<template>
  <div ref="turning_right"></div>
</template>

<script>
import TurningRight from "!!raw-loader!../../assets/ricoh_assets/icons/BTN005n_turning_right.svg" // loads SVG Icons as String via Webpack.
export default {
    mounted() {
        let shadowRoot = this.$refs.turning_right.attachShadow({mode: 'open'})
        shadowRoot.innerHTML = TurningRight

        const style = document.createElement('style')
        style.innerHTML = `svg { width: 100%; height: 100%; }`

        const clonedStyle = style.cloneNode(true)
        shadowRoot.appendChild(clonedStyle)
    }
}
</script>

<style>
</style>