<template>
    <div ref="battery_100"></div>
</template>

<script>
import Battery100 from "!!raw-loader!../../assets/ricoh_assets/icons/ICN001-03_battery_100.svg" // loads SVG Icons as String via Webpack.
export default {
    mounted() {
        let shadowRoot = this.$refs.battery_100.attachShadow({mode: 'open'})
        shadowRoot.innerHTML = Battery100

        const style = document.createElement('style')
        style.innerHTML = `svg { width: 100%; height: 100%; }`

        const clonedStyle = style.cloneNode(true)
        shadowRoot.appendChild(clonedStyle)
    }
}
</script>

<style>
</style>