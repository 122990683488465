<template>
  <v-dialog persistent max-width="600px" :retain-focus="false" v-model="c_recoverableErrorDialogVisible">
      <template v-slot:activator="{c_recoverableErrorDialogVisible}"></template> <!-- eslint-disable-line -->
      <v-card class="dialog">
        <v-toolbar flat dark class="dialog__title" color="#FF0000" height="71">
            <v-icon large class="mr-2">$vuetify.icons.infoWarning</v-icon>
            エラー
        </v-toolbar>
        <v-card-text class="dialog__message mt-10 ">
            以下のエラーが発生しました。エラーを解除してください。
            <v-list class="overflow-y-auto" :height=150>
                <template v-for="(item, index) in recoverableErrors">
                    <v-list-item :key="index" class="mt-n6">
                        <v-card-text :class="[(item.hw_id === 'crawler_io_control' && item.code === parseInt('0x8004')) ? 'dialog__errorlist_emergencyStop' : 'dialog__errorlist']">
                            {{ convertErrorTemplate(item) }}
                            <v-divider :key="index"></v-divider>
                        </v-card-text>
                    </v-list-item>
                </template>
            </v-list>
        </v-card-text>

        <v-card-actions>
            <v-layout justify-end class="mb-5">
                <v-btn rounded dark class="dialog__button mx-3" color="#0099FF" @click="resetError">エラー解除</v-btn>
            </v-layout>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
import { convert } from '@ricoh-srg-system/error-stringizer'
export default {
    props: {
        recoverableErrorDialogVisible: { type: Boolean },
        isEmergencyStopPushed: { type: Boolean },
        recoverableErrors: { type: Array },
        liftStop: { type: Boolean },
    },
    data() {
        return {
        }
    },
    computed: {
        c_recoverableErrorDialogVisible: {
            get() {
                return this.recoverableErrorDialogVisible
            }
        },
    },
    methods: {
        resetError() {
            this.$emit('resetError')
        },
        convertErrorTemplate(item) {
            return convert(JSON.stringify(item), window.navigator.language)
        }
    }
}
</script>

<style scoped>
.dialog {
    max-width: 600px;
    min-height: 216px;
}
.dialog__button {
  min-width: 202px !important;
  height: 54px !important;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 21px;
  opacity: 1;
  text-align: left;
  font: normal normal bold 24px/32px Segoe UI;
  letter-spacing: 0px;
}
.dialog__message {
  text-align: left;
  font: normal normal normal 24px/32px Segoe UI;
  letter-spacing: 0px;
  color: #000000 !important;
  opacity: 1;
}
.dialog__errorlist {
  font: normal normal normal 16px/24px Segoe UI;
  letter-spacing: 0px;
  color: #000000 !important;
  opacity: 1;
}
.dialog__errorlist_emergencyStop {
  font: normal normal normal 16px/24px Segoe UI;
  letter-spacing: 0px;
  color: #FF0000 !important;
  opacity: 1;
}
.dialog__title {
  text-align: left;
  font: normal normal bold 24px/32px Segoe UI;
  letter-spacing: 0px;
  opacity: 1;
}
</style>