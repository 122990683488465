<template>
    <div>
        <v-layout v-show="!isInMiniMap">
            <v-icon large class="mx-1" v-show="batteryLevel>=81 && batteryLevel<=100">$vuetify.icons.battery100</v-icon>
            <v-icon large class="mx-1" v-show="batteryLevel>=61 && batteryLevel<=80">$vuetify.icons.battery80</v-icon>
            <v-icon large class="mx-1" v-show="batteryLevel>=41 && batteryLevel<=60">$vuetify.icons.battery60</v-icon>
            <v-icon large class="mx-1" v-show="batteryLevel>=21 && batteryLevel<=40">$vuetify.icons.battery40</v-icon>
            <v-icon large class="mx-1" v-show="((batteryLevel==0 || batteryLevel>0)&& batteryLevel<=20)">$vuetify.icons.battery20</v-icon>
            <v-icon large class="mx-1" v-show="batteryLevel==null">$vuetify.icons.batteryUnknown</v-icon>
            {{c_batteryLevel}}
        </v-layout>

         <v-layout v-show="isInMiniMap">
            <v-icon v-show="!isMagnifiedMap && (batteryLevel>=81 && batteryLevel<=100)">$vuetify.icons.battery100</v-icon>
            <v-icon v-show="!isMagnifiedMap && (batteryLevel>=61 && batteryLevel<=80)">$vuetify.icons.battery80</v-icon>
            <v-icon v-show="!isMagnifiedMap && (batteryLevel>=41 && batteryLevel<=60)">$vuetify.icons.battery60</v-icon>
            <v-icon v-show="!isMagnifiedMap && (batteryLevel>=21 && batteryLevel<=40)">$vuetify.icons.battery40</v-icon>
            <v-icon v-show="!isMagnifiedMap && ((batteryLevel==0 || batteryLevel>0) && batteryLevel<=20)">$vuetify.icons.battery20</v-icon>
            <v-icon v-show="!isMagnifiedMap && batteryLevel==null">$vuetify.icons.batteryUnknown</v-icon>
            
            <v-icon large v-show="isMagnifiedMap && (batteryLevel>=81 && batteryLevel<=100)">$vuetify.icons.battery100</v-icon>
            <v-icon large v-show="isMagnifiedMap && (batteryLevel>=61 && batteryLevel<=80)">$vuetify.icons.battery80</v-icon>
            <v-icon large v-show="isMagnifiedMap && (batteryLevel>=41 && batteryLevel<=60)">$vuetify.icons.battery60</v-icon>
            <v-icon large v-show="isMagnifiedMap && (batteryLevel>=21 && batteryLevel<=40)">$vuetify.icons.battery40</v-icon>
            <v-icon large v-show="isMagnifiedMap && ((batteryLevel==0 || batteryLevel>0) && batteryLevel<=20)">$vuetify.icons.battery20</v-icon>
            <v-icon large v-show="isMagnifiedMap && batteryLevel==null">$vuetify.icons.batteryUnknown</v-icon>
        </v-layout>
    </div>
</template>

<script>
export default {
    props: {
        batteryLevel: { type: Number },
        isMagnifiedMap: { type: Boolean, default:true },
        isInMiniMap: { type: Boolean, default:false }
    },
    computed: {
        c_batteryLevel: {
            get() {
                if (this.batteryLevel != null) {
                    return `${this.batteryLevel}%`
                }
                else {
                    return this.batteryLevel
                }
            }
        }
    }
}
</script>

<style scoped>
</style>