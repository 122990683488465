<template>
<div id="camera-and-ctrl">
  <crawler-controller-template ref="crawler_ctrl" class="crawler-controller-template" v-show="cameraMode==='maincam'" 
    :drivingMode="drivingMode" :cameraMode="cameraMode"
    @addMaincam="addMaincam($event)"
    @destroyMaincam="destroyMaincam"
    @resizeMaincam="resizeMaincam"
    @displayBackNotification="displayBackNotification($event)"
    @registerCurrentLoc="registerCurrentLoc"
    @registerSound="registerCheckPoint($event)"
    @pauseResponse="pauseResponse($event)"
    @resumeResponse="resumeResponse($event)"
    @resetErrorResponse="resetErrorResponse($event)"
    @switchCtrlModeResponse="switchCtrlModeResponse($event)">
  </crawler-controller-template>
  
  <ptz-camera-controller-template ref="ptz_ctrl" class="ptz-camera-controller-template" v-show="cameraMode==='ptz'" 
    :drivingMode="drivingMode" :cameraMode="cameraMode"
    @registerVisual="registerCheckPoint($event)">
  </ptz-camera-controller-template>

  <thermal-camera-controller-template ref="thermal_ctrl" class="thermal-camera-controller-template" v-show="cameraMode==='thermal'" 
    :drivingMode="drivingMode" :cameraMode="cameraMode"
    @registerThermal="registerCheckPoint($event)"
    @switchCtrlModeResponse="switchCtrlModeResponse($event)">
  </thermal-camera-controller-template>

  <sound-controller-template ref="sound_ctrl" class="sound-controller-template" v-show="cameraMode==='sound'"
    :cameraMode="cameraMode"
    @registerSound="registerCheckPoint($event)"
    @switchCtrlModeResponse="switchCtrlModeResponse($event)">
  </sound-controller-template>
</div>
</template>

<script>
import CrawlerControllerTemplate from '../templates/CrawlerControllerTemplate.vue'
import PtzCameraControllerTemplate from '../templates/PtzCameraControllerTemplate.vue'
import ThermalCameraControllerTemplate from '../templates/ThermalCameraControllerTemplate.vue'
import SoundControllerTemplate from '../templates/SoundControllerTemplate.vue'
import { LiveStreaming } from '../../class/LiveStreaming'

export default {
  props: {
    drivingMode: String,
    cameraMode: String
  },
  components: { 
    CrawlerControllerTemplate, 
    PtzCameraControllerTemplate,
    ThermalCameraControllerTemplate,
    SoundControllerTemplate
  },
  watch: {
    cameraMode(mode) {
      this.cameraMode = mode
    }
  },
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
    initialize() {
      this.liveStreaming = new LiveStreaming(this.$store.state.selectedCrawler)
      this.$refs.crawler_ctrl.setLiveStreamingEvent(this.liveStreaming)
      this.$refs.thermal_ctrl.setLiveStreamingEvent(this.liveStreaming)
      this.$refs.ptz_ctrl.setLiveStreamingEvent(this.liveStreaming)
    },
    addMaincam(event) {
      this.$refs.sound_ctrl.addMaincam(event.connectionId)
    },
    destroyMaincam() {
      this.$refs.sound_ctrl.destroyMaincam()
    },
    resizeMaincam() {
      this.$refs.sound_ctrl.resizeMaincam()
    },
    registerCurrentLoc() {
      this.$emit('registerCurrentLoc')
    },
    registerCheckPoint(event) {
      this.$emit('registerCheckPoint', { connectionId: event.connectionId , resetZoom: event.resetZoom != null ? event.resetZoom : null })
    },
    disableCrawlerCtrl() {
      this.$refs.crawler_ctrl.disableCrawlerCtrl()
      this.$refs.thermal_ctrl.disableCrawlerCtrl()
      this.$refs.sound_ctrl.disableCrawlerCtrl()
    },
    enableCrawlerCtrl(cameraMode) {
      this.disableCrawlerCtrl()
      switch(cameraMode) {
        case 'maincam':
          this.$refs.crawler_ctrl.enableCrawlerCtrl()
          break
        case 'thermal':
          this.$refs.thermal_ctrl.enableCrawlerCtrl()
          break
        case 'sound':
          this.$refs.sound_ctrl.enableCrawlerCtrl()
          break
        default:
          break
      }
    },
    displayBackNotification(event) {
      this.$emit('displayBackNotification', event)
    },
    pause(from) {
      this.$refs.crawler_ctrl.pause(from)
    },
    pauseResponse(event) {
      this.$emit('pauseResponse', event)
    },
    resume(mode, from) {
      this.$refs.crawler_ctrl.resume(mode, from)
    },
    resumeResponse(event) {
      this.$emit('resumeResponse', event)
    },
    resetError() {
      this.$refs.crawler_ctrl.resetError()
    },
    resetErrorResponse(event) {
      this.$emit('resetErrorResponse', event)
    },
    reconnect() {
      this.$refs.crawler_ctrl.reconnect()
      this.$refs.ptz_ctrl.reconnect()
      this.liveStreaming.reconnect()
    },
    setRobotParameter(linearVelocity, slewingSpeed) {
      if (this.$refs.crawler_ctrl != null) {
        this.$refs.crawler_ctrl.setRobotParameter(linearVelocity, slewingSpeed)
      }
      else if (this.$refs.thermal_ctrl != null) {
        this.$refs.thermal_ctrl.setRobotParameter(linearVelocity, slewingSpeed)
      }
      else if (this.$refs.sound_ctrl != null) {
        this.$refs.sound_ctrl.setRobotParameter(linearVelocity, slewingSpeed)
      }
      else {
        // nothing
      }
    },
    sendGetCtrlCmd() {
      if (this.$refs.crawler_ctrl != null) {
        this.$refs.crawler_ctrl.sendGetCtrlCmd()
      }
      else if (this.$refs.thermal_ctrl != null) {
        this.$refs.thermal_ctrl.sendGetCtrlCmd()
      }
      else if (this.$refs.sound_ctrl != null) {
        this.$refs.sound_ctrl.sendGetCtrlCmd()
      }
      else {
        // nothing
      }
    },
    switchCtrlModeResponse(event) {
      this.$emit('switchCtrlModeResponse', event)
    },
    finalize() {
      this.liveStreaming.finalize()
    }
  }

}
</script>

<style scoped>
.crawler-controller-template {
  position: relative;
}
.ptz-camera-controller-template{
  position: relative;
}
.sound-controller-template{
  position: relative;
}
.thermal-camera-controller-template {
  position: relative;
}
</style>