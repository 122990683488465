import LSReadyState from './LSReadyState'
import MqttCommand from '../util/MqttCommand'

let self = null
let LSContext = function(sendTopic, randomStr) {
    this.state = LSReadyState
    this.sendTopic = sendTopic
    this.randomStr = randomStr
    self = this
}

LSContext.prototype = {
    constructor: LSContext,
    setReply: function(topic, reply) {  // eslint-disable-line
        self.state.doReply(self, reply)
    },
    changeState: function(state) {
        self.state = state
        self.state.doAction(self, self.randomStr)
    },
    publish: function(type, name, payload) {
        MqttCommand.publish(self.sendTopic, type, name, payload)
    }
}

export default { LSContext }