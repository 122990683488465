<template>
    <v-form ref="form" v-model="valid" lazy-validation>
        <v-container fluid>
            <v-row class="input__field__frame">
                <v-row style="padding: 0.5em;">
                    <v-card-text class="dialog__title mt-2 ml-2">ランプ／スイッチ（目視）</v-card-text>
                    <v-checkbox class="dialog__item ml-2" v-model="isSave" label="点検する" dense />
                    <v-card-text class="dialog__item ml-2">閾値１</v-card-text>
                    <v-responsive max-width="200">
                        <v-text-field outlined class="ml-4 right__alignment" :rules="[rules.number, rules.range]" v-model="threshold[0]" :disabled="!isSave" dense />
                    </v-responsive>
                    <v-card-text class="dialog__item ml-2">閾値２</v-card-text>
                    <v-responsive max-width="200">
                        <v-text-field outlined class="ml-4 right__alignment" :rules="[rules.number, rules.range]" v-model="threshold[1]" :disabled="!isSave" dense />
                    </v-responsive>
                    <v-card-text class="dialog__item ml-2">備考</v-card-text>
                    <v-text-field outlined class="ml-4 mr-4" v-model="remarks" :disabled="!isSave" dense />
                </v-row>
                <v-row style="padding: 0.5em;">
                    <v-layout justify-end class="buttons">
                        <v-btn rounded dark class="dialog__button" color="#707070" @click="cancel">キャンセル</v-btn>
                        <v-btn rounded :dark="valid" :disabled="!valid" class="dialog__button ml-2 mr-2 mb-2" color="#0099ff" @click="set">設定</v-btn>
                    </v-layout>
                </v-row>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
export default {
    props: {
        params: { type: Object },
    },
    data() {
        return {
            valid: true,
            isSave: false,
            threshold: [ null, null ],
            remarks: '',
            rules: {
                /*
                required: value => !!value || '必須入力です。',
                */
                number: value => {
                    let number = Number(value)
                    if (!Number.isNaN(number)) {
                        return true
                    }
                    else {
                        return '半角数字で入力してください。'
                    }
                },
                range: value => {
                    // required無しの場合、未入力であれば範囲チェックをスキップ
                    if (value === null || value === '') {
                        return true
                    }
                    return true
                    /* TODO: 上下限値が決まり次第
                    let number = Number(value)
                    if (Math.sign(value) >= 0 && number >= 1 && number <= 100 && Number.isInteger(number)) {
                        return true
                    }
                    else {
                        return '1～100の整数値を入力してください。'
                    }
                    */
                },
            },
        }
    },
    created() {
        this.isSave = this.params.isSave
        this.threshold = this.params.threshold
        this.remarks = this.params.remarks
    },
    mounted() {
        this.$refs.form.validate()
    },
    methods: {
        /**
         * 初期処理
         */
        initialize: async function() {
            this.valid = true
            this.isSave = true
            this.threshold = [ null, null ]
            this.remarks = ''
            this.$refs.form.validate()
        },
        /**
         * 能動的にバリデーション実行
         */
        activeValidate: function() {
            let result = this.rules.number(this.threshold[0]) === true ? true : false
            result = result && this.rules.range(this.threshold[0]) === true ? true : false
            result = result && this.rules.number(this.threshold[1]) === true ? true : false
            result = result && this.rules.range(this.threshold[1]) === true ? true : false
            return result
        },
        set: function() {
            this.$emit('update', {
                // v-formのv-model「valid」に結果（true/false）が反映される前にイベント発火となるので能動的に実行
                isValid: !this.isSave ? true : this.activeValidate(),
                isSave: this.isSave,
                threshold: [
                    (this.threshold[0] === null || this.threshold[0] === '') ? null : Number(this.threshold[0]),
                    (this.threshold[1] === null || this.threshold[1] === '') ? null : Number(this.threshold[1]),
                ],
                remarks: this.remarks,
            })
        },
        cancel: function() {
            this.$emit('cancel', {})
        },
    }
}
</script>

<style scoped>
.dialog__title {
    width: 100%;
    text-align: left;
    font: normal normal bold 20px/28px Segoe UI;
    letter-spacing: 0px;
    color: #000000 !important;
    opacity: 1;
    padding: 1px !important;
}
.dialog__item {
    width: 60%;
    text-align: left;
    font: normal normal bold 16px/24px Segoe UI;
    letter-spacing: 0px;
    color: #000000 !important;
    opacity: 1;
    padding: 1px !important;
}
.dialog__button {
    min-width: 120px !important;
    height: 40px !important;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 26px;
    opacity: 1;
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
}
.right__alignment::v-deep input {
    text-align: right !important;
}
.input__field__frame {
    padding: 0.2em 0.0em 0.2em 0.5em;
    border-radius: 10px;
    border: solid 1px;
    border-color: #4a4b4c;
}
</style>