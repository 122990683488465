<template>
    <v-form ref="form" v-model="valid" lazy-validation>
        <v-container fluid>
            <v-row style="padding: 0.0em 0.5em 0.0em 0.5em;">
                <v-layout>
                    <v-card-text class="dialog__item" style="width: 50% !important;">閾値1</v-card-text>
                    <v-text-field outlined :rules="[rules.number, rules.range]" v-model="threshold[0]" v-on:input="onInput" class="right__alignment" dense />
                </v-layout>
                <v-layout style="width: 100% !important;">
                    <v-card-text class="dialog__item" style="width: 50% !important;">閾値2</v-card-text>
                    <v-text-field outlined :rules="[rules.number, rules.range]" v-model="threshold[1]" v-on:input="onInput" class="right__alignment" dense />
                </v-layout>
                <v-layout>
                    <v-card-text class="dialog__item" style="width: 50% !important;">表示最低温度</v-card-text>
                    <v-card-text class="dialog__item" style="width: 50% !important;">表示最高温度</v-card-text>
                </v-layout>
                <v-layout>
                    <v-responsive max-width="226">
                        <v-text-field outlined class="ml-4 right__alignment" :rules="[rules.number, rules.rangeTemperature, rules.irregularTemperatureFrom]" v-model="temperature[0]" v-on:input="onInput" suffix="℃" dense />
                    </v-responsive>
                    <v-icon class="mb-8">mdi-minus</v-icon>
                    <v-responsive max-width="226">
                        <v-text-field outlined class="right__alignment" :rules="[rules.number, rules.rangeTemperature, rules.irregularTemperatureTo]" v-model="temperature[1]" v-on:input="onInput" suffix="℃" dense />
                    </v-responsive>
                </v-layout>
                <v-card-text class="dialog__item">備考</v-card-text>
                <v-text-field outlined class="ml-4" v-model="remarks" v-on:input="onInput" dense />
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
export default {
    watch: {
        temperature: {
            handler: function() {
                if (((this.temperature[0] === null || this.temperature[0] === '') && (this.temperature[1] === null || this.temperature[1] === '')) || 
                    ((this.temperature[0] !== null && this.temperature[0] !== '') && (this.temperature[1] !== null && this.temperature[1] !== ''))) {
                    let result = this.rules.number(this.temperature[0]) === true ? true : false
                    result = result && this.rules.rangeTemperature(this.temperature[0]) === true ? true : false
                    result = result && this.rules.number(this.temperature[1]) === true ? true : false
                    result = result && this.rules.rangeTemperature(this.temperature[1]) === true ? true : false
                    if (result) {
                        this.$refs.form.resetValidation()
                        return
                    }
                }
                this.$refs.form.validate()
            },
            deep: true,
        }
    },
    data() {
        return {
            valid: true,
            threshold: [ null, null ],
            temperature: [ 0, 70 ],
            remarks: '',
            rules: {
                number: value => {
                    let number = Number(value)
                    if (!Number.isNaN(number)) {
                        return true
                    }
                    else {
                        return '半角数字で入力してください。'
                    }
                },
                range: value => {
                    // required無しの場合、未入力であれば範囲チェックをスキップ
                    if (value === null || value === '') {
                        return true
                    }
                    return true
                    /* TODO: 上下限値が決まり次第
                    let number = Number(value)
                    if (Math.sign(value) >= 0 && number >= 1 && number <= 100 && Number.isInteger(number)) {
                        return true
                    }
                    else {
                        return '1～100の整数値を入力してください。'
                    }
                    */
                },
                rangeTemperature: value => {
                    // required無しの場合、未入力であれば範囲チェックをスキップ
                    if (value === null || value === '') {
                        return true
                    }
                    return true
                    /* TODO: 上下限値が決まり次第
                    let number = Number(value)
                    if (Math.sign(value) >= 0 && number >= 1 && number <= 100 && Number.isInteger(number)) {
                        return true
                    }
                    else {
                        return '1～100の整数値を入力してください。'
                    }
                    */
                },
                irregularTemperatureFrom: value => {
                    if (value === null || value === '') {
                        if (this.temperature[1] !== null && this.temperature[1] !== '') {
                            return '「表示最低温度、表示最高温度の双方を入力」又は「双方を未入力」としてください。'
                        }
                    }
                    return true
                },
                irregularTemperatureTo: value => {
                    if (value === null || value === '') {
                        if (this.temperature[0] !== null && this.temperature[0] !== '') {
                            return '「表示最低温度、表示最高温度の双方を入力」又は「双方を未入力」としてください。'
                        }
                    }
                    return true
                },
            },
        }
    },
    created() {
        // 初期表示からそのままの入力値の場合、当該コンポーネントの値が上位コンポーネントに通知されないので
        this.onInput()
    },
    mounted() {
        this.$refs.form.validate()
    },
    methods: {
        /**
         * 初期処理
         */
        initialize: async function() {
            this.valid = true
            this.threshold = [ null, null ]
            this.temperature = [ 0, 70 ],
            this.remarks = ''
            this.$refs.form.validate()
        },
        /**
         * ユーザー操作により値が変更
         * @param {Object} e  イベント
         */
        onInput: function(e) {
            this.$emit('update', {
                isValid: this.activeValidate(),
                threshold: [
                    (this.threshold[0] === null || this.threshold[0] === '') ? null : Number(this.threshold[0]),
                    (this.threshold[1] === null || this.threshold[1] === '') ? null : Number(this.threshold[1]),
                ],
                temperature: [
                    (this.temperature[0] === null || this.temperature[0] === '') ? null : Number(this.temperature[0]),
                    (this.temperature[1] === null || this.temperature[1] === '') ? null : Number(this.temperature[1]),
                ],
                remarks: this.remarks,
            })
        },
        /**
         * 能動的にバリデーション実行
         */
        activeValidate: function() {
            let result = this.rules.number(this.threshold[0]) === true ? true : false
            result = result && this.rules.range(this.threshold[0]) === true ? true : false
            result = result && this.rules.number(this.threshold[1]) === true ? true : false
            result = result && this.rules.range(this.threshold[1]) === true ? true : false
            result = result && this.rules.number(this.temperature[0]) === true ? true : false
            result = result && this.rules.rangeTemperature(this.temperature[0]) === true ? true : false
            result = result && this.rules.number(this.temperature[1]) === true ? true : false
            result = result && this.rules.rangeTemperature(this.temperature[1]) === true ? true : false
            result = result && this.rules.irregularTemperatureFrom(this.temperature[0]) === true ? true : false
            result = result && this.rules.irregularTemperatureTo(this.temperature[1]) === true ? true : false
            return result
        },
    }
}
</script>

<style scoped>
.dialog__item {
    width: 60%;
    text-align: left;
    font: normal normal bold 16px/24px Segoe UI;
    letter-spacing: 0px;
    color: #000000 !important;
    opacity: 1;
    padding: 1px !important;
}
.right__alignment::v-deep input {
    text-align: right !important;
}
</style>