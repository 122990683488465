<template>
    <v-form ref="form" v-model="valid" lazy-validation>
        <v-container fluid>
            <v-row style="padding: 0.0em 0.5em 0.0em 0.5em;">
                <v-layout>
                    <v-card-text class="dialog__item" style="width: 50% !important;">録音時間</v-card-text>
                    <v-text-field outlined :rules="[rules.required, rules.second]" v-model="recordingTime" v-on:input="onInput" suffix="秒" class="right__alignment" dense />
                </v-layout>
                <v-layout>
                    <v-card-text class="dialog__item" style="width: 50% !important;">マイクゲイン</v-card-text>
                    <v-text-field outlined :rules="[rules.required, rules.percent]" v-model="gain" v-on:input="onInput" suffix="%" class="right__alignment" dense />
                </v-layout>
                <v-layout>
                    <v-card-text class="dialog__item" style="width: 50% !important;">閾値1</v-card-text>
                    <v-text-field outlined :rules="[rules.number, rules.range]" v-model="threshold[0]" v-on:input="onInput" class="right__alignment" dense />
                </v-layout>
                <v-layout>
                    <v-card-text class="dialog__item" style="width: 50% !important;">閾値2</v-card-text>
                    <v-text-field outlined :rules="[rules.number, rules.range]" v-model="threshold[1]" v-on:input="onInput" class="right__alignment" dense />
                </v-layout>
                <v-card-text class="dialog__item">備考</v-card-text>
                <v-text-field outlined class="ml-4" v-model="remarks" v-on:input="onInput" dense />
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
export default {
    data() {
        return {
            valid: true,
            recordingTime: 1,
            gain: 100,
            threshold: [ null, null ],
            remarks: '',
            rules: {
                required: value => {
                    if (!!value) return true
                    else {
                        if (value === 0) return true
                        return '必須入力です。'
                    }
                },
                second: value => {
                    let number = Number(value)
                    if (Math.sign(value) >= 0 && number >= 1 &&  Number.isInteger(number)) {
                        return true
                    }
                    else {
                        return '1以上の整数値を入力してください。'
                    }
                },
                percent: value => {
                    let number = Number(value)
                    if (Math.sign(value) >= 0 && number >= 1 && number <= 100 && Number.isInteger(number)) {
                        return true
                    }
                    else {
                        return '1～100の整数値を入力してください。'
                    }
                },
                number: value => {
                    let number = Number(value)
                    if (!Number.isNaN(number)) {
                        return true
                    }
                    else {
                        return '半角数字で入力してください。'
                    }
                },
                range: value => {
                    // required無しの場合、未入力であれば範囲チェックをスキップ
                    if (value === null || value === '') {
                        return true
                    }
                    return true
                    /* TODO: 上下限値が決まり次第
                    let number = Number(value)
                    if (Math.sign(value) >= 0 && number >= 1 && number <= 100 && Number.isInteger(number)) {
                        return true
                    }
                    else {
                        return '1～100の整数値を入力してください。'
                    }
                    */
                },
            },
        }
    },
    created() {
        // 初期表示からそのままの入力値の場合、当該コンポーネントの値が上位コンポーネントに通知されないので
        this.onInput()
    },
    mounted() {
        this.$refs.form.validate()
    },
    methods: {
        /**
         * 初期処理
         */
        initialize: async function() {
            this.valid = true
            this.recordingTime = 1
            this.gain = 100
            this.threshold = [ null, null ]
            this.remarks = ''
            this.$refs.form.validate()
        },
        /**
         * ユーザー操作により値が変更
         * @param {Object} e  イベント
         */
        onInput: function(e) {
            this.$emit('update', {
                // v-formのv-model「valid」に結果（true/false）が反映される前にイベント発火となるので能動的に実行
                isValid: this.activeValidate(),
                second: this.recordingTime === null ? null : Number(this.recordingTime),
                gain: this.gain === null ? null : Number(this.gain),
                threshold: [
                    (this.threshold[0] === null || this.threshold[0] === '') ? null : Number(this.threshold[0]),
                    (this.threshold[1] === null || this.threshold[1] === '') ? null : Number(this.threshold[1]),
                ],
                remarks: this.remarks,
            })
        },
        /**
         * 能動的にバリデーション実行
         */
        activeValidate: function() {
            let result = this.rules.required(this.recordingTime) === true ? true : false
            result = result && this.rules.second(this.recordingTime) === true ? true : false
            result = result && this.rules.required(this.gain) === true ? true : false
            result = result && this.rules.percent(this.gain) === true ? true : false
            result = result && this.rules.number(this.threshold[0]) === true ? true : false
            result = result && this.rules.range(this.threshold[0]) === true ? true : false
            result = result && this.rules.number(this.threshold[1]) === true ? true : false
            result = result && this.rules.range(this.threshold[1]) === true ? true : false
            return result
        },
    }
}
</script>

<style scoped>
.dialog__item {
    width: 60%;
    text-align: left;
    font: normal normal bold 16px/24px Segoe UI;
    letter-spacing: 0px;
    color: #000000 !important;
    opacity: 1;
    padding: 1px !important;
}
.right__alignment::v-deep input {
    text-align: right !important;
}
</style>