<template>
  <v-menu dark v-model="isShowMenu" :close-on-content-click=false :close-on-click=false>
    <template v-slot:activator="{ on, attrs }">
      <v-btn dark class="menu-button" color="#009999" v-bind="attrs" v-on="on" 
        :disabled="operatingMode==='check.plant'&&mode==='autonomous'"><v-icon>$vuetify.icons.menu</v-icon></v-btn>
  </template>
  <v-card class="menu-card">
    <v-card-subtitle class="menu-card-text--bold">速度</v-card-subtitle>
    <v-card-subtitle class="menu-card-text">直進速度</v-card-subtitle>
    <v-slider color="#009999" track-color="grey lighten-2" thumb-color="#009999" prepend-icon="mdi-minus" append-icon="mdi-plus" :max="2" :tick-labels="speedLabels" step="1" tick-size="8" v-model="linearVelocity"></v-slider>
    <v-card-subtitle class="menu-card-text">旋回速度</v-card-subtitle>
    <v-slider color="#009999" track-color="grey lighten-2" thumb-color="#009999" prepend-icon="mdi-minus" append-icon="mdi-plus" :max="2" :tick-labels="speedLabels" step="1" tick-size="8" v-model="slewingSpeed"></v-slider>
    <v-card-subtitle class="menu-card-text--bold pb-2">モード</v-card-subtitle>
    <div style="text-align:center;">
    <v-btn  outlined class="menu-card-mode-button mx-5 mb-3"  :color="isSelectedDockChargingStationMode ? selectedColor : unSelectedColor" @click="isSelectedDockChargingStationMode=!isSelectedDockChargingStationMode; isSelectedUndockChargingStationMode=false">入庫モード</v-btn>
    <v-btn  outlined class="menu-card-mode-button mx-5 mb-3"  :color="isSelectedUndockChargingStationMode ? selectedColor : unSelectedColor" @click="isSelectedDockChargingStationMode=false; isSelectedUndockChargingStationMode=!isSelectedUndockChargingStationMode">出庫モード</v-btn>
  </div>
    <div class="menu-card-button--layout">
      <v-btn rounded class="menu-card-button my-5" color="#009999" @click="setRobotParameter">OK</v-btn>
    </div>
  </v-card>
</v-menu>
</template>

<script>
export default {
  props: {
    drivingMode: String,
  },
  data() {
    return {
      operatingMode: this.$store.state.selectedMode,
      isShowMenu: false,
      speedLabels: [ '遅い', '普通', '速い'],
      linearVelocity: 1,
      w_linearVelocity: 0.4,
      slewingSpeed: 1,
      w_slewingSpeed: 0.4,
      mode: this.drivingMode,
      isSelectedDockChargingStationMode: false,
      isSelectedUndockChargingStationMode: false,
      selectedColor: "#009999",
      unSelectedColor: "#a9a9a9",
    }
  },
  watch: {
    linearVelocity: {
      handler:function() {
        switch (this.speedLabels[this.linearVelocity]) {
          case '遅い':
            this.w_linearVelocity = 0.2
            break;
          case '普通':
            this.w_linearVelocity = 0.4
            break;
          case '速い':
            this.w_linearVelocity = 0.8
            break;
        }
      }
    },
    slewingSpeed: {
      handler:function() {
        switch (this.speedLabels[this.slewingSpeed]) {
          case '遅い':
            this.w_slewingSpeed = 0.2
            break;
          case '普通':
            this.w_slewingSpeed = 0.4
            break;
          case '速い':
            this.w_slewingSpeed = 0.8
            break;
        }
      }
    },
    drivingMode: {
      handler: function(mode) {
        this.mode = mode;
      }
    },
  },
  methods: {
    setRobotParameter() {
      let autoChargingMode = null
      if (this.isSelectedDockChargingStationMode) {
        autoChargingMode = "docking"
      } else if (this.isSelectedUndockChargingStationMode) {
        autoChargingMode = "undocking"
      }
      this.$emit('setRobotParameter', { linearVelocity: this.w_linearVelocity, slewingSpeed: this.w_slewingSpeed, autoChargingMode: autoChargingMode })
      this.isShowMenu = false
    }
  }
}
</script>

<style scoped>
.menu-button {
  height: 64px !important;
  width: 73px;
}
.menu-card {
    width: 348px;
}
.menu-card-mode-button {
  width: 110px;
    height: 45px !important;
    opacity: 1;
    font: normal normal bold 16px Segoe UI;
    letter-spacing: 0px;
    border-width: 2px;
}
.menu-card-button {
    width: 110px;
    height: 45px !important;
    background: #009999 0% 0% no-repeat padding-box;
    opacity: 1;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
}
.menu-card-button--layout {
    text-align:center;
}
.menu-card-text {
    text-align: left;
    font: normal normal normal 24px/32px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
.menu-card-text--bold {
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
</style>