<template>
    <div class="input__field__frame">
        <v-form ref="form" v-model="valid" lazy-validation>
            <v-container fluid>
                <v-row>
                    <v-row style="padding: 0.5em;">
                        <v-card-text class="dialog__title mt-2 ml-2">メーター</v-card-text>
                        <v-checkbox class="dialog__item ml-2" v-model="isSave" label="点検する" dense />
                        <v-card-text class="dialog__item ml-2">メーター種類</v-card-text>
                        <v-layout>
                            <v-responsive max-width="220">
                                <v-select
                                    v-model="meterType"
                                    :items="meterTypes"
                                    dense
                                    outlined
                                    placeholder="メーター種類"
                                    class="ml-4"
                                    item-value="key"
                                    item-text="value"
                                    :disabled="!isSave" 
                                    @change="changeMeterType"
                                    return-object />
                            </v-responsive>
                        </v-layout>

                        <div v-if="meterType.key=='round'" class="input__field__frame_ex">
                            <div class="contents">
                                <v-card-text class="dialog__item">正常範囲</v-card-text>
                                <v-layout>
                                    <v-responsive max-width="200">
                                        <v-text-field outlined class="ml-4 right__alignment" :rules="[rules.number]" v-model="lower" :disabled="!isSave" dense />
                                    </v-responsive>
                                    <v-icon class="mb-8">mdi-minus</v-icon>
                                    <v-responsive max-width="200">
                                        <v-text-field outlined class="right__alignment" :rules="[rules.number]" v-model="upper" :disabled="!isSave" dense />
                                    </v-responsive>
                                </v-layout>
                                <v-layout>
                                    <v-card-text class="dialog__item">メーターの最低値</v-card-text>
                                    <v-card-text class="dialog__item">メーターの最高値</v-card-text>
                                </v-layout>
                                <v-layout>
                                    <v-responsive max-width="200">
                                        <v-text-field outlined class="ml-4 right__alignment" :rules="[rules.number, rules.rangeMeter]" v-model="meterMin" :disabled="!isSave" dense />
                                    </v-responsive>
                                    <v-icon class="mb-8">mdi-minus</v-icon>
                                    <v-responsive max-width="200">
                                        <v-text-field outlined class="right__alignment" :rules="[rules.number, rules.rangeMeter]" v-model="meterMax" :disabled="!isSave" dense />
                                    </v-responsive>
                                </v-layout>
                                <v-layout>
                                    <v-card-text class="dialog__item">最低値のメーター角度</v-card-text>
                                    <v-card-text class="dialog__item">最高値のメーター角度</v-card-text>
                                </v-layout>
                                <v-layout>
                                    <v-responsive max-width="200">
                                        <v-text-field outlined class="ml-4 right__alignment" :rules="[rules.number, rules.rangeAngle]" v-model="angleMin" :disabled="!isSave" dense />
                                    </v-responsive>
                                    <v-icon class="mb-8">mdi-minus</v-icon>
                                    <v-responsive max-width="200">
                                        <v-text-field outlined class="right__alignment" :rules="[rules.number, rules.rangeAngle]" v-model="angleMax" :disabled="!isSave" dense />
                                    </v-responsive>
                                </v-layout>
                                <v-card-text class="dialog__item ml-2">備考</v-card-text>
                                <v-text-field outlined class="ml-4 mr-2" v-model="remarks" :disabled="!isSave" dense />
                            </div>
                        </div>
                        <div v-else-if="meterType.key=='rectangle'" class="input__field__frame__blank">
                            <!-- 2023.05.31時点では未対応 -->
                        </div>
                        <div v-else-if="meterType.key=='sevenSeg'" class="input__field__frame__blank">
                            <!-- 2023.05.31時点では未対応 -->
                        </div>
                        <div v-else-if="meterType.key=='counter'" class="input__field__frame__blank">
                            <!-- 2023.05.31時点では未対応 -->
                        </div>
                        <div v-else-if="meterType.key=='rod'" class="input__field__frame__blank">
                            <!-- 2023.05.31時点では未対応 -->
                        </div>
                        <div v-else class="input__field__frame__blank">
                        </div>
                    </v-row>
                    <v-row style="padding: 0.5em;">
                        <v-layout justify-end>
                            <v-btn rounded dark class="dialog__button" color="#707070" @click="cancel">キャンセル</v-btn>
                            <v-btn rounded :dark="!isDisabled" :disabled="isDisabled" class="dialog__button ml-2 mr-2" color="#0099ff" @click="set">設定</v-btn>
                        </v-layout>
                    </v-row>
                </v-row>
            </v-container>
        </v-form>
    </div>
</template>

<script>
export default {
    props: {
        params: { type: Object },
    },
    computed: {
        isDisabled: {
            get() {
                return !(this.valid && this.meterType.key === 'round')
            }
        },
    },
    data() {
        return {
            valid: true,
            isSave: false,
            lower: null,
            upper: null,
            meterMin: null, // メーターの最低値
            meterMax: null, // メーターの最高値
            angleMin: null, // 最低値のメーター角度
            angleMax: null, // 最低値のメーター角度
            meterType: { key: '', value: '' },  // メーターの種類
            meterTypes: [
                { key: 'round', value: '丸型' },
                { key: 'rectangle', value: '矩形' },
                { key: 'sevenSeg', value: '7セグ' },
                { key: 'counter', value: 'カウンタ' },
                { key: 'rod', value: '棒型' },
            ],
            remarks: '',
            rules: {
                /*
                required: value => !!value || '必須入力です。',
                */
                number: value => {
                    let number = Number(value)
                    if (!Number.isNaN(number)) {
                        return true
                    }
                    else {
                        return '半角数字で入力してください。'
                    }
                },
                rangeMeter: value => {
                    // required無しの場合、未入力であれば範囲チェックをスキップ
                    if (value === null || value === '') {
                        return true
                    }
                    return true
                    /* TODO: 上下限値が決まり次第
                    let number = Number(value)
                    if (Math.sign(value) >= 0 && number >= 1 && number <= 100 && Number.isInteger(number)) {
                        return true
                    }
                    else {
                        return '1～100の整数値を入力してください。'
                    }
                    */
                },
                rangeAngle: value => {
                    // required無しの場合、未入力であれば範囲チェックをスキップ
                    if (value === null || value === '') {
                        return true
                    }
                    let number = Number(value)
                    if (Math.sign(value) >= 0 && number >= 0 && number <= 360 && Number.isInteger(number)) {
                        return true
                    }
                    else {
                        return '0～360の整数値を入力してください。'
                    }
                },
            },
        }
    },
    created() {
        this.isSave = this.params.isSave
        this.lower = this.params.lower
        this.upper = this.params.upper
        this.meterMin = this.params.meterMin
        this.meterMax = this.params.meterMax
        this.angleMin = this.params.angleMin
        this.angleMax = this.params.angleMax
        this.meterType.key = this.params.meterType
        this.remarks = this.params.remarks
    },
    mounted() {
        this.$refs.form.validate()
    },
    methods: {
        /**
         * 初期処理
         */
        initialize: async function() {
            this.valid = true
            this.isSave = true
            this.lower = null
            this.upper = null
            this.meterMin = null
            this.meterMax = null
            this.angleMin = null
            this.angleMax = null
            this.meterType = ''
            this.remarks = ''
            this.$refs.form.validate()
        },
        /**
         * メーター種類選択
         * @param {Object} meter  選択したオブジェクト
         */
        changeMeterType: function(meter) {
            if (meter.key !== 'round') {
                alert('丸型以外は未対応の為、選択できません。')
                this.meterType = { key: '', value: '' }
            }
        },
        /**
         * 能動的にバリデーション実行
         */
        activeValidate: function() {
            let result = this.rules.number(this.lower) === true ? true : false
            result = result && this.rules.number(this.upper) === true ? true : false
            result = result && this.rules.number(this.meterMin) === true ? true : false
            result = result && this.rules.rangeMeter(this.meterMin) === true ? true : false
            result = result && this.rules.number(this.meterMax) === true ? true : false
            result = result && this.rules.rangeMeter(this.meterMax) === true ? true : false
            result = result && this.rules.number(this.angleMin) === true ? true : false
            result = result && this.rules.rangeAngle(this.angleMin) === true ? true : false
            result = result && this.rules.number(this.angleMax) === true ? true : false
            result = result && this.rules.rangeAngle(this.angleMax) === true ? true : false
            return result
        },
        set: function() {
            this.$emit('update', {
                // v-formのv-model「valid」に結果（true/false）が反映される前にイベント発火となるので能動的に実行
                isValid: !this.isSave ? true : this.activeValidate(),
                isSave: this.isSave,
                lower: this.lower === null ? null : Number(this.lower),
                upper: this.upper === null ? null : Number(this.upper),
                meterMin: this.meterMin === null ? null : Number(this.meterMin),
                meterMax: this.meterMax === null ? null : Number(this.meterMax),
                angleMin: this.angleMin === null ? null : Number(this.angleMin),
                angleMax: this.angleMax === null ? null : Number(this.angleMax),
                meterType: this.meterType.key,
                remarks: this.remarks,
            })
        },
        cancel: function() {
            this.$emit('cancel', {})
        },
    }
}
</script>

<style scoped>
.dialog__title {
    width: 100%;
    text-align: left;
    font: normal normal bold 20px/28px Segoe UI;
    letter-spacing: 0px;
    color: #000000 !important;
    opacity: 1;
    padding: 1px !important;
}
.dialog__item {
    width: 60%;
    text-align: left;
    font: normal normal bold 16px/24px Segoe UI;
    letter-spacing: 0px;
    color: #000000 !important;
    opacity: 1;
    padding: 1px !important;
}
.dialog__button {
    min-width: 120px !important;
    height: 40px !important;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 26px;
    opacity: 1;
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
}
.right__alignment::v-deep input {
    text-align: right !important;
}
.input__field__frame {
    width: 490px;
    height: 550px;
    padding: 0.2em 0.0em 0.2em 0.5em;
    border-radius: 10px;
    border: solid 1px;
    border-color: #4a4b4c;
}
.input__field__frame_ex {
    width: 464px;
    height: 284px;
    padding: 0.2em 0.0em 0.2em 0.5em;
    margin-left: 0.5em !important;
    border-radius: 10px;
    overflow: hidden;
    border: solid 1px;
    border-color: #4a4b4c;
}
.input__field__frame__blank {
    width: 464px;
    height: 284px;
}
.contents {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
}
</style>