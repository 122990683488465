// Copyright(c) 2024 RICOH Company, Ltd. All rights reserved.

import NormalState from './NormalState'
import BackReadyState from './BackReadyState'

class NormalReadyState {
    constructor() {}

    doInput(moveContext, input){
        if (input !== 'down') {
            moveContext.changeState(NormalState, input)
        }
        else {
            moveContext.changeState(BackReadyState)
        }
    }

    doAction(moveContext, input) {
        if (input === undefined) {
            moveContext.notify('normalReady', null)
        }
    }
}

const normalReadyState = new NormalReadyState()
Object.freeze(normalReadyState)
export default normalReadyState