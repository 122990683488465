import MqttCommand from '../util/MqttCommand'
export class PtzController {
  sendTopic = null

  constructor(serialNo) {
    this.sendTopic = `${sessionStorage.getItem('userPoolId')}/${serialNo}/usr_program/ptz-camera-control`
  }

  focusFar() {
    let payload = {
      'far.near': 1
    }
    this.sendCameraControlCommand('camera.change.focus', payload)
  }

  focusNear() {
    let payload = {
      'far.near': -1
    }
    this.sendCameraControlCommand('camera.change.focus', payload)
  }

  focusAuto() {
    let payload = {
      'far.near': 0
    }
    this.sendCameraControlCommand('camera.change.focus', payload)
  }

  zoomIn() {
    this.camStart(function() {
      let payload = {
          x: 0, 
          y: 0,
          zoom: 0.75
      }
      this.sendCameraControlCommand('camera.move', payload)
    }.bind(this))
  }

  zoomInDrastically() {
    this.camStart(function() {
      let payload = {
          direction: 1
      }
      this.sendCameraControlCommand('camera.change.zoom', payload)
    }.bind(this))
  }

  zoomOut() {
    this.camStart(function() {
        let payload = {
            x: 0, 
            y: 0,
            zoom: -0.75
        }
        this.sendCameraControlCommand('camera.move', payload)
    }.bind(this))
  }

  zoomOutDrastically() {
    this.camStart(function() {
      let payload = {
          direction: -1
      }
      this.sendCameraControlCommand('camera.change.zoom', payload)
    }.bind(this))
  }

  zoomStop() {
    this.camStop(function() {
      let payload = {
          pantilt: false,
          zoom: true
      }
      this.sendCameraControlCommand('camera.stop', payload)
    }.bind(this))
  }

  moveLeft() {
    let payload = {
      "pan.deg": -90
    }
    this.sendCameraControlCommand('camera.move.absolute', payload)
  }

  moveRight() {
    let payload = {
      "pan.deg": 90
    }
    this.sendCameraControlCommand('camera.move.absolute', payload)
  }

  moveDown() {
    this.camStart(function() {
      let payload = {
          x: 0, 
          y: -0.75,
          zoom: 0
      }
      this.sendCameraControlCommand('camera.move', payload)
    }.bind(this))
  }

  moveUp() {
    this.camStart(function() {
      let payload = {
          x: 0, 
          y: 0.75,
          zoom: 0
      }
      this.sendCameraControlCommand('camera.move', payload)
    }.bind(this))
  }

  stop() {
    this.camStop(function() {
      let payload = {
          pantilt: true,
          zoom: false
      }
      this.sendCameraControlCommand('camera.stop', payload)
    }.bind(this))
  }

  camStart(startFunc) {
    if (this.camTimerId) {
      return;
    }
    startFunc();
    this.camTimerId = setInterval(startFunc, 750);
  }

  camStop(stopFunc) {
    if (this.camTimerId) {
      clearInterval(this.camTimerId);
      this.camTimerId = 0;
      stopFunc();
    }
  }

  returnHomePos() {
    let payload = {}
    this.sendCameraControlCommand('camera.home', payload)
  }

  moveBackPos() {
    let payload = {
      "pan.deg": 179.4,
      "tilt.deg": -7.4,
    }
    this.sendCameraControlCommand('camera.move.absolute', payload)
  }

  resetZoom() {
    let payload = {}
    console.log(this)
    this.sendCameraControlCommand('camera.reset.zoom', payload)
  }

  ptzClickAndCentering(event, ptzConnectionId) {
    // クリックされた位置を取得して、コマンドを送信する
    let $clickedVideo = document.getElementById(ptzConnectionId)
    let headerHeight = document.getElementsByTagName('header')[0].clientHeight
    let payload = {
        rx: (event.pageX - $clickedVideo.offsetLeft) / $clickedVideo.clientWidth,
        ry: (event.pageY - headerHeight) / $clickedVideo.clientHeight
    }
    this.sendCameraControlCommand('camera.center', payload)
  }

  ptzTouchAndCentering(event, ptzConnectionId) {
      // タッチされた位置を取得して、コマンドを送信する
      let touchObject = event.changedTouches[0]
      let $touchedVideo = document.getElementById(ptzConnectionId)
      let headerHeight = document.getElementsByTagName('header')[0].clientHeight
      let payload = {
          rx: (touchObject.pageX - $touchedVideo.offsetLeft) / $touchedVideo.clientWidth,
          ry: (touchObject.pageY - headerHeight) / $touchedVideo.clientHeight
      }
      this.sendCameraControlCommand('camera.center', payload)
  }

  centeringZoomWithRect(left, top, width, height, ptzConnectionId, callback) {
    let $ptzVideo = document.getElementById(ptzConnectionId)

    let payload = {
        left: left / $ptzVideo.clientWidth,
        top: top / $ptzVideo.clientHeight,
        width: width / $ptzVideo.clientWidth,
        height: height / $ptzVideo.clientHeight,
    }
    this.sendCameraControlCommand('camera.change.reticle.center.zoom', payload)
    callback()
  }

  sendCameraControlCommand(name, payload) {
    MqttCommand.publish(this.sendTopic, 'cmd.noreply', name, payload)
  }
}