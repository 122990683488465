import NormalReadyState from './NormalReadyState'

class BackState {
    constructor() {}

    doInput(moveContext, input) {
        if (input !== 'down') {
            moveContext.changeState(NormalReadyState)
        }
        else {
            this.doAction(moveContext, input)
        }
    }

    doAction(moveContext, input) {
        moveContext.notify('back', input)
    }
}

const backState = new BackState()
Object.freeze(backState)
export default backState