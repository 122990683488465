<template>
  <v-card dark id="map-area" class="map-area--normal">
    <div class="map-component">
      <route-map ref="route_map" v-if="operatingMode==='create.route'"
        :initCenter="[35.29708092453554, 139.57834696746266]"  
        :initZoom=18
        :enableExpandShrinkCtrl=true
        :enableContextMenu=true
        :enableMultiSelect=false
        :centerRobot=true
        @ricoh-srg-event-course-map-way-point-selected="onPointSelected" 
        @ricoh-srg-event-course-map-way-point-unselected="onPointUnselected"
        @ricoh-srg-event-course-map-expand-shrink-map="onExpandShrink"
        @ricoh-srg-event-course-map-remove-waypoints="removeWayPoint"
      />
      <route-view ref="route_view" v-if="operatingMode==='check.plant'"
        :initCenter="[35.29708092453554, 139.57834696746266]"
        :initZoom=18
        :enableContextMenu=false
        :enableMultiSelect=false
        :showRobot=true
        :centerRobot=true
        :enableExpandShrinkCtrl=true
        :enableWaypointRemove=false
        :enableWaypointSelect=false
        @ricoh-srg-event-course-map-expand-shrink-map="onExpandShrink"
      ></route-view>
    </div>
    <v-container fluid class="map__foot">
      <v-row dense no-gutters>
        <v-col cols="10">
          crawler名：{{crawlerName}}
        </v-col>
        <v-col cols="2">
          <v-layout justify-end>
            <signal-icon :is-magnified-map="isMagnifiedMap" :signal-condition="signalCondition"></signal-icon>
            <battery-icon class="ml-2" :is-magnified-map="isMagnifiedMap" :is-in-mini-map=true :battery-level="batteryLevel"></battery-icon>
          </v-layout>
        </v-col>
      </v-row>
      <v-row dense no-gutters>
        <v-col cols="4">
          <p class="map__foot--status-overflow">{{status}}</p>
        </v-col>
        <v-col id="gps-precision" cols="7" class="gps_unsupported">
          GPS精度：{{gpsPrecisionString!=null?gpsPrecisionString:'状態不明'}}
        </v-col>
        <v-col cols="1">
          <v-layout justify-end>
            <v-btn dark rounded small color="#009999" @click="reconnect">再配信</v-btn>
          </v-layout>
        </v-col>
      </v-row>
      <v-row dense no-gutters>
        <v-col cols="12">
          <v-layout>
            <p class="map__foot--status-overflow">
              操作権限：
              {{ controller === null ? '不明' : (controller === 'null' ? '' : controller) }}
            </p>
            <v-btn dark rounded small :disabled="(operatingMode==='check.plant'&&mode==='autonomous') || d_controller==='aws.iot'" color="#FF0000" @click="sendGetCtrlCmd">操作権取得</v-btn>
            <v-btn dark rounded small v-if="showDisableWarningButton"
              :color="showWarning ? `#009999` : `#FF0000`"
              @click="toggleShowWarning(!showWarning)"
            >
              {{ showWarning ? '警告ON' : '警告OFF' }}
            </v-btn>
          </v-layout>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import Routes from '../../class/Routes'
import RouteMap from '@ricoh-srg-system/routemap-component'
import RouteView from '@ricoh-srg-system/routeview-component'
import SignalIcon from '../SignalIcon.vue'
import BatteryIcon from '../BatteryIcon.vue'
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    RouteMap,
    RouteView,
    SignalIcon,
    BatteryIcon
  },
  props: {
    displayStatus: { type: String },
    precision: {type: String},
    controller: {type: String},
    drivingMode: {type: String}
  },
  computed: {
  },
  watch: {
    displayStatus: {
      handler: function(status) {
        this.status = status;
      }
    },
    precision: function(){
      this.updateGpsPrecisionString(this.precision)
    },
    drivingMode (mode) {
      this.mode = mode
    },
    controller(w_controller) {
      this.d_controller = w_controller
    }
  },
  created() {
  },
  mounted() {
    // this.initMap()
  },
  data() {
    return {
      showDisableWarningButton: process.env.RC_VUE_APP_DISABLE_WARNING_CARD_TOGGLE === 'true',
      operatingMode: this.$store.state.selectedMode,
      mode: this.drivingMode,
      d_controller: this.controller,
      crawlerName: this.$store.state.selectedCrawler,
      status: this.displayStatus,
      gpsPrecision: this.precision,
      gpsPrecisionString: "状態不明",
      signalCondition: null,
      batteryLevel: null,
      isMagnifiedMap: false,
    }
  },
  methods: {
    ...mapActions(['toggleShowWarning']),
    reconnect() {
      this.$emit('reconnect')
    },
    async initMap() {
      if (this.operatingMode === 'create.route') {
        this.robotLoc = this.$refs.route_map.robotState.clone();
        this.waypointID = 0n;
        const prevPt = this.prevWaypointPt ?? this.$refs.route_map.map.latLngToLayerPoint(this.robotLoc);
        const currPt = this.$refs.route_map.map.latLngToLayerPoint(this.robotLoc);

        this.$refs.route_map.updateRobotLocation(this.robotLoc.lat, this.robotLoc.lng, this.robotLoc.azimuthDeg);

        this.prevWaypointPt = this.$refs.route_map.map.latLngToLayerPoint(this.robotLoc);
        this.$refs.route_map.setRobotState("remotectrl");
      }
      else if (this.operatingMode === 'check.plant') {
        let route = await Routes.getRoute(this.$store.state.selectedRoute)
        let routeGpxList = []
        if (route.combinedRoutes == null) {
            routeGpxList.push(await Routes.getRouteGpx(this.$store.state.selectedRoute))
        }
        else {
            for (let routeId of route.combinedRoutes) {
                routeGpxList.push(await Routes.getRouteGpx(routeId))
            }
        }
        this.$refs.route_view.setWaypointsFromGpx(routeGpxList)
        this.$refs.route_view.setRobotState("autonomous");
      }
    },
    updateRobotInfo(robot) {
      this.crawlerName = robot.robotStatus.robotName != null ? robot.robotStatus.robotName : robot.robotStatus.serialNo
      this.signalCondition = robot.robotStatus.signalCondition*100
      this.batteryLevel = robot.robotStatus.batteryLevel_pct

      let status = null
      if (robot.robotStatus.status === 'running') {
        if (robot.robotStatus.application.drivingMode === 'remote.ctrl') {
          status = 'remotectrl'
        }
        else if (robot.robotStatus.application.drivingMode === 'autonomous') {
          status = 'autonomous'
        }
        else {
          status = 'emergency'
        }
      }
      else { // 障害物回避が入ったらavoidanceを追加する
        status = 'emergency'
      }

      if (this.operatingMode === 'create.route') {
        this.robotLoc.lat = robot.robotStatus.location['lat.deg'];
        this.robotLoc.lng = robot.robotStatus.location['lon.deg'];
        this.robotLoc.azimuth = robot.robotStatus.location['azimuth.deg']
        this.$refs.route_map.updateRobotLocation(this.robotLoc.lat, this.robotLoc.lng, this.robotLoc.azimuth);

        this.$refs.route_map.setRobotState(status);
      }
      else if (this.operatingMode === 'check.plant') {
        this.$refs.route_view.drawRobotRoute(robot.robotStatus.location['lat.deg'], robot.robotStatus.location['lon.deg'], 0, 1, false, robot.robotStatus.location['azimuth.deg']);

        this.$refs.route_view.setRobotState(status);
      }
    },
    onPointSelected() {
      console.log(`Point(ID:${arg.id}) selected.`);
    },
    onPointUnselected() {
      console.log(`Point IDs:${arg} unselected.`);
    },
    onExpandShrink(arg) {
      let mapDisplayArea = document.getElementById('map-area')
      if (arg.state === 'expand') {
          this.isMagnifiedMap = true
          mapDisplayArea.className = ''
          mapDisplayArea.className = 'map-area--expand v-card v-sheet theme--dark'
      }
      else if (arg.state === 'shrink'){
          this.isMagnifiedMap = false
          mapDisplayArea.className = ''
          mapDisplayArea.className = 'map-area--normal v-card v-sheet theme--dark'
      }
      else {
          // nothing
      }
      if (this.$store.state.selectedMode === 'create.route') {
        this.$refs.route_map.redraw()
      }
      else if (this.$store.state.selectedMode === 'check.plant') {
        this.$refs.route_view.redraw()
        
      }
    },
    /**
     * gps精度に変更があれば表示とcssを変える
     */
    updateGpsPrecisionString(latestGpsPrecision) {
      if (latestGpsPrecision == null) {
        if (this.gpsPrecisionString !== '状態不明') {
            this.gpsPrecision = latestGpsPrecision
            let gpsPrecisionElement = document.getElementById("gps-precision");
            gpsPrecisionElement.classList.remove(...gpsPrecisionElement.classList);
            this.gpsPrecisionString = '状態不明'
            gpsPrecisionElement.classList.add("gps_unsupported")
            gpsPrecisionElement.classList.add("col")
            gpsPrecisionElement.classList.add("col-7")
        }
      } else if (latestGpsPrecision != this.gpsPrecision){
        this.gpsPrecision = latestGpsPrecision;
        let gpsPrecisionElement = document.getElementById("gps-precision");
        gpsPrecisionElement.classList.remove(...gpsPrecisionElement.classList);
        switch (this.gpsPrecision) {
          case 'no gps':
            this.gpsPrecisionString = '解析不可'
            gpsPrecisionElement.classList.add("gps_no-gps")
            break
          case 'gps only':
            this.gpsPrecisionString = '低'
            gpsPrecisionElement.classList.add("gps_gps-only")
            break
          case 'float':
          case 'sensor dist. error':
            this.gpsPrecisionString = '中'
            gpsPrecisionElement.classList.add("gps_float")
            break
          case 'fixed':
            this.gpsPrecisionString = '高'
            gpsPrecisionElement.classList.add("gps_fixed")
            break
          default:
            this.gpsPrecisionString = '状態不明'
            gpsPrecisionElement.classList.add("gps_unsupported")
            break
        }
        gpsPrecisionElement.classList.add("col")
        gpsPrecisionElement.classList.add("col-7")
      }
    },
    removeWayPoint() {
      // 未実装
    },
    addWayPoint(latDeg, lonDeg, elevM, wayPointId, wait) {
      this.$refs.route_map.addWayPoint(latDeg, lonDeg, elevM, wayPointId, wait)
      this.$refs.route_map.redraw()
    },
    sendGetCtrlCmd() {
      this.$emit('sendGetCtrlCmd')
    }
  },
  computed: {
    ...mapGetters(['showWarning']),
  }
}
</script>

<style scoped>
.map-area--normal {
    position: absolute;
    top: 10%;
    margin: 12px;
    z-index: 100000;
    height: 350px;
    width: 319px;
    font-size: 16px;
}
.map-area--expand {
    position: absolute;
    top: 10%;
    margin: 12px;
    z-index: 100000;
    height: 600px;
    width: 1220px;
    font-size: 25px;
}
.map-area--under {
    position: absolute;
    top: 55%;
    margin: 20px;
    height: 250px;
    width: 280px;
    text-align: center;
    border: 8px solid red;
}
.map-area--under__display-rect-button {
    position: absolute;
    top: 52%;
    margin-left: 72px;
    width: 175px;
    height: 64px !important;
    padding: 0 16px;
    background: #009999 0% 0% no-repeat padding-box;
    opacity: 1;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
}
.map-area--under__rect-ctrl-button {
    width: 175px;
    height: 64px !important;
    padding: 0 16px;
    background: #009999 0% 0% no-repeat padding-box;
    opacity: 1;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
}
.map-component {
    width: 100%;
    height: 73%;
}
.map__foot {
    position: absolute;
    bottom: 0;
}
.map__foot--status-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 0 !important;
    font-size: 90%;
}
.gps_no-gps {
    animation: flash 1s linear infinite;
    color: #C0C0C0;
    font-size: 90%;
    margin-bottom: 0 !important;
}
.gps_gps-only {
    animation: flash 1s linear infinite;
    color: #FF0000;
    font-size: 90%;
    margin-bottom: 0 !important;
}
.gps_float {
    color: #F9A825;
    font-size: 90%;
    margin-bottom: 0 !important;
}
.gps_fixed {
    color: #009999;
    font-size: 90%;
    margin-bottom: 0 !important;
}
.gps_unsupported {
    color: #FFFFFF;
    font-size: 90%;
    margin-bottom: 0 !important;
}
.map__foot--warning-switch .v-input--switch .v-input__control .v-input__slot .v-switch__thumb {
  width: 10px;
  height: 10px;
}
@keyframes flash {
    0%,
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
    35% {
      opacity: 1;
    }
    65% {
      opacity: 1;
    }
}
</style>