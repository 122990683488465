<template>
  <v-app-bar dark color="#4A4B4C" max-height="64px">
    <v-toolbar-title class="normal-font">RICOH Crawler</v-toolbar-title>
    <v-spacer></v-spacer>
    <div class="user-name">
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn text v-show="isLoggedIn()" v-on="on" style="font-size:24px;">
            <v-icon large class="mr-1">$vuetify.icons.user</v-icon>
            {{userName}}
          </v-btn>
        </template>
        <logged-in-user-card @logout="logout"></logged-in-user-card>
      </v-menu>
    </div>
    <info-dialog class="front" :infoVisible="isNotifyForcedLogout" :infoType="'normal'" :infoMessage="notifyForcedLogoutMsg" @closeDialog="forcedLogout"></info-dialog>
  </v-app-bar>
</template>

<script>
import LoggedInUserCard from '../components/LoggedInUserCard.vue'
import AWSUtil from '../class/AWSUtil'
import InfoDialog from '../components/InfoDialog.vue'

export default {
  components: { LoggedInUserCard, InfoDialog },
  
  data() {
    return {
      userName: '',
      isNotifyForcedLogout: false,
      notifyForcedLogoutMsg: ''
      
    }
  },
  created() {
    this.setNotifyTokenExpirationHandler()
    // window.addEventListener("beforeunload", this.terminate)
  },
  destroyed() {
    // window.removeEventListener("beforeunload", this.terminate)
  },
  methods: {
    isLoggedIn() {
      if (this.$store.state.loggedinUser !== '') {
        this.userName = this.$store.state.loggedinUser
        return true
      }
      else {
        return false
      }
    },
    logout(isLoggedOut) {
      if (isLoggedOut) {
        this.$store.dispatch('destroyAction')
        this.$router.push('/')
        window.location.reload()
      } 
      else {
        alert('ログアウトに失敗しました。再試行またはウィンドウを直接閉じて下さい。')
      }
    },
    setNotifyTokenExpirationHandler() {
      AWSUtil.on('notifyTokenExpiration', function() {
        // 「トークンが切れました再度ログインが必要です。」というメッセージを出し、ＯＫボタンでログアウト処理。
        this.notifyForcedLogoutMsg = '認証期限が切れました。\n再度ログインしてください。'
        this.isNotifyForcedLogout = true
      }.bind(this))
    },
    forcedLogout() {
      this.isNotifyForcedLogout = false
      AWSUtil.disableAuthentication()
      .then(()=> {
        this.logout(true)
      })
    }
    // terminate() {
    //   AWSUtil.clearAWSCache()
    // }
  }
}
</script>

<style scoped>
.user-name {
  right: 0px;
}
.front {
  z-index: 2000000;
}
</style>