<template>
  <v-dialog persistent max-width="630px" v-model="c_warningBeforeStartDialogVisible">
      <template v-slot:activator="{c_warningBeforeStartDialogVisible}"></template> <!-- eslint-disable-line -->
      <v-card class="dialog">
        <v-toolbar flat dark class="dialog__title" color="#009999" height="40">
            <v-icon large class="mr-2">$vuetify.icons.info</v-icon>確認
        </v-toolbar>
        <v-card-text class="dialog__message mt-10">背面カバーが開いています。<br>背面カバーを閉めてから開始してください。
        </v-card-text>
        <v-card-actions>
            <v-layout justify-end>
                <v-btn rounded dark class="dialog__button mx-3 mb-3" color="#707070" @click="cancel">キャンセル</v-btn>
                <v-btn rounded :dark="!isBackCoverOpened" class="dialog__button mx-3 mb-3" color="#0099FF" :disabled="isBackCoverOpened" @click="confirm">OK</v-btn>
            </v-layout>
        </v-card-actions>
      </v-card>
  </v-dialog>

</template>

<script>
export default {
    props: {
        warningBeforeStartDialogVisible: { type: Boolean },
        selectedCrawler: { type: Object },
        from: { type: String },
        isBackCoverOpened: { type: Boolean },
    },
    data() {
        return {
           w_isBackCoverOpened: false
        }
    },
    computed: {
        c_warningBeforeStartDialogVisible: {
            get() {
                return this.warningBeforeStartDialogVisible
            }
        }
    },
    watch: {
        isBackCoverOpened (p_isBackCoverOpened) {
            this.w_isBackCoverOpened = p_isBackCoverOpened
        },
    },
    methods: {
        confirm() {
            this.$emit('confirm', {crawler: this.selectedCrawler, from: this.from})
        },
        cancel() {
            this.$emit('cancel')
        }
    }
}
</script>

<style scoped>
.dialog {
    max-width: 630px;
    min-height: 236px;
}
.dialog__button {
    min-width: 164px !important;
    height: 54px !important;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 26px;
    opacity: 1;
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI !important;
    letter-spacing: 0px;
}
.dialog__message {
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
    color: #000000 !important;
    opacity: 1;
    white-space: pre-wrap;
}
::v-deep .dialog_checkbox__message .v-label{
    font: normal normal bold 24px/32px Segoe UI;
    color: #000000 !important;
}
.dialog__title {
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
    opacity: 1;
}
</style>