<template>
    <div id="com-disruption-card">
        <v-card dark class="card">
            <v-layout>
                <v-icon large class="ml-5 mt-3">$vuetify.icons.info</v-icon>
                <v-card-subtitle class="card__message">ロボットとの通信が途絶しました。</v-card-subtitle>
            </v-layout>
        </v-card>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>

<style scoped>
.card {
    background: #FF0000 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    opacity: 1;
}
.card__message {
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
    color: #FFFFFF !important;
    opacity: 1;
    white-space: pre-wrap;
}
</style>