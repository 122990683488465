<template>
  <div id='login'>
    <div class="login-form" @keyup.enter.exact="keyUpEnter">
      <p>ログイン情報を入力してください</p>
      
      <div class="error-message-area" v-show="isError">{{errorMessage}}</div>
      
      <p>テナントID</p>
      <v-text-field dense outlined label="Tenant ID" placeholder="Tenant ID" v-model="tenantId" :disabled="inProgressLogin" aria-autocomplete="off"></v-text-field>
      
      <p>ユーザー名</p>
      <v-text-field dense outlined label="User name" placeholder="User name" v-model="userName" :disabled="inProgressLogin" aria-autocomplete="off"></v-text-field>
      
      <p>パスワード</p>
      <v-text-field dense outlined label="Password" placeholder="Password" v-model="password" :disabled="inProgressLogin" aria-autocomplete="off" 
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassword = !showPassword" :type="showPassword ? 'text':'password'">
      </v-text-field>
      
      <div class='login-button'>
        <v-btn rounded dark @click="login">
          <div v-show="!inProgressLogin">ログイン</div>
          <v-progress-circular indeterminate color="#FFFFFF" v-show="inProgressLogin"></v-progress-circular>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import AWSUtil from '../class/AWSUtil'
export default {
  name: 'Login',
  data() {
    return {
      showPassword: false,
      inProgressLogin: false,
      errorMessage: 'ログインに失敗しました。\nログイン情報を再度確認して下さい。',
      tenantId: '',
      userName: '',
      password: '',
      isError: false
    }
  },
  created() {
    localStorage.clear()
  },
  destroyed() {
    this.inProgressLogin = false
  },
  methods: {
    keyUpEnter(e) {
      e.preventDefault()
      this.login()
    },
    setLoggedinUserName(loggedinUserName) {
      this.$store.dispatch('setLoggedinUserAction', {
        name : loggedinUserName,
      })
    },
    login() {
      this.inProgressLogin = true
      
      AWSUtil.authenticate(this.tenantId, this.userName, this.password, this.setLoggedinUserName)
      .then(() => {
          this.isError = false
          this.$router.push('/Menu')
      })
      .catch(() => {
            this.isError = true
            this.inProgressLogin = false
      })
    }
  }
}
</script>

<style scoped>
.error-message-area {
  position: relative;
  margin: 10px;
  color: #FF0000;
  white-space:pre-wrap;
}
.login-button {
  margin-top: 50px;
  text-align: center;
}
.v-btn {
  width: 240px;
  height: 54px !important;
  background: #0099FF 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029;
  font: normal normal bold 24px/32px Segoe UI !important;
  letter-spacing: 0px;
}
.login-form {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 400px;
  height: 500px;
}
.login-form > p {
  font: normal normal bold 24px/32px Segoe UI;
  letter-spacing: 0px;
}
</style>