import { render, staticRenderFns } from "./CrawlerSpeedController.vue?vue&type=template&id=0bc710c2&scoped=true&"
import script from "./CrawlerSpeedController.vue?vue&type=script&lang=js&"
export * from "./CrawlerSpeedController.vue?vue&type=script&lang=js&"
import style0 from "./CrawlerSpeedController.vue?vue&type=style&index=0&id=0bc710c2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bc710c2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSlider } from 'vuetify/lib/components/VSlider';
installComponents(component, {VBtn,VCard,VCardSubtitle,VIcon,VMenu,VSlider})
