<template>
  <v-dialog persistent max-width="550px" :retain-focus="false" v-model="c_infoVisible">
    <template v-slot:activator="{c_infoVisible}"></template> <!-- eslint-disable-line -->
    <v-card class="dialog">
      <v-toolbar flat dark class="dialog__title" color="#009999" height="40" v-show="c_infoType==='normal'">
        <v-icon large class="mr-2">$vuetify.icons.info</v-icon>確認
      </v-toolbar>
      <v-toolbar flat dark class="dialog__title" color="#FF0000" height="40" v-show="c_infoType==='error'">
        <v-icon large class="mr-2">$vuetify.icons.info</v-icon>エラー
      </v-toolbar>
      <v-card-text class="dialog__message mt-10">
        {{c_infoMessage}}
      </v-card-text>
      <v-card-actions>
        <v-layout justify-end>
          <v-btn rounded dark class="dialog__button mx-3 mb-3" color="#0099FF" @click="closeDialog">OK</v-btn>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    infoVisible: { type: Boolean, default: false },
    infoType: { type: String, default: 'normal' },
    infoMessage: { type: String, default: '' },
  },
  computed: {
    c_infoVisible: {
      get() {
        return this.infoVisible
      }
    },
    c_infoMessage: {
      get() {
        return this.infoMessage
      }
    },
    c_infoType: {
      get() {
        return this.infoType
      }
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    }
  }
}
</script>

<style scoped>
.dialog {
  max-width: 550px;
  min-height: 220px;
}
.dialog__button {
    min-width: 164px !important;
    height: 54px !important;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 26px;
    opacity: 1;
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI !important;
    letter-spacing: 0px;
}
.dialog__message {
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
    color: #000000 !important;
    opacity: 1;
    white-space: pre-wrap;
}
.dialog__title {
  text-align: left;
  font: normal normal bold 24px/32px Segoe UI;
  letter-spacing: 0px;
  opacity: 1;
}
</style>