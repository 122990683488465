// LiveStreamingCommand.js

'use strict'

import LSContext from './LSContext'
import MqttCommand from '../util/MqttCommand'

let lsContext = null
let recvTopic = ''
let topicRegExp = /ap-northeast-1_([0-9a-zA-Z]){9}\/([0-9a-z]){12,}\/usr_program\/rsi-livestreaming\/reply/

export default class LiveStreamingCommand {
    static startLiveStreaming(serialNo) {
        return new Promise((resolve, reject) => {
            let userPoolId = sessionStorage.getItem('userPoolId')
            recvTopic = `${userPoolId}/${serialNo}/usr_program/rsi-livestreaming/reply`
            let sendTopic = `${userPoolId}/${serialNo}/usr_program/rsi-livestreaming`
            // let randomStr = createLStokenRandomStr();
            // let roomId = `${serialNo}-${randomStr}`
            let roomId = `${serialNo}-0000` // 社内デモ用暫定対応。上記コメントアウトに元に戻す。
            MqttCommand.subscribe(recvTopic)
            .then(()=>{
                // lsContext = new LSContext.LSContext(sendTopic, randomStr)
                lsContext = new LSContext.LSContext(sendTopic, '0000') // 社内デモ用暫定対応。上記コメントアウトに元に戻す。

                let type = 'cmd'
                let name = 'rsi.livestreaming.stop'
                let payload = {}
                MqttCommand.createPacket(type, name, payload)
                .then((packet) => {
                    MqttCommand.setSubscribeTopicHandler(topicRegExp, null, lsContext.setReply)
                    .then(()=>{
                        MqttCommand.sendPacket(sendTopic, packet)
                        resolve(roomId)
                    }) 
                })
            }).catch((err)=>{
                reject(err)
            })
        })
    }
    static stopLiveStreaming() {
        return new Promise((resolve) => {
            MqttCommand.unsubscribe(recvTopic)
            MqttCommand.removeSubscribeTopicHandler(topicRegExp, null)
            resolve()
        })
    }
    static reconnectLiveStreaming(serialNo) {
        let userPoolId = sessionStorage.getItem('userPoolId')
        let sendTopic = `${userPoolId}/${serialNo}/usr_program/rsi-livestreaming`

        let type = 'cmd.noreply'
        let name = 'rsi.livestreaming.reconnect'
        let payload = {}
        MqttCommand.createPacket(type, name, payload)
        .then((packet) => {
            MqttCommand.sendPacket(sendTopic, packet)
        })
    }
}
function createLStokenRandomStr() {
    let length = 4
    let str = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890.%+^_"`{|}~<>\\-'
    let strLength = str.length
    let result = ''

    for (let i = 0; i < length; i++) {
        result += str[Math.floor(Math.random() * strLength)]
    }

    return result 
}