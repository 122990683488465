<template>
    <div id="notification-card">
        <v-card dark class="card--info" v-show="iconType==='normal'||iconType==='location'">
            <v-layout>
                <v-icon large class="ml-5 mt-3" v-show="iconType==='location'">$vuetify.icons.infoPoint</v-icon>
                <v-icon large class="ml-5 mt-3" v-show="iconType==='normal'">$vuetify.icons.info</v-icon>
                <v-card-subtitle class="card__message">{{dialogMessage}}</v-card-subtitle>
            </v-layout>
        </v-card>

        <v-card dark class="card--error" v-show="iconType==='error'">
            <v-layout>
                <v-icon large class="ml-5 mt-3">$vuetify.icons.info</v-icon>
                <v-card-subtitle class="card__message">{{dialogMessage}}</v-card-subtitle>
            </v-layout>
        </v-card>
    </div>
</template>

<script>
export default {
    props: {
        iconType: { type: String },
        dialogMessage: { type: String }
    },
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>

<style scoped>
.card--error {
    background: #FF0000 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    opacity: 1;
}
.card--info{
    background: #009999 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    opacity: 1;
}
.card__message {
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
    color: #FFFFFF !important;
    opacity: 1;
    white-space: pre-wrap;
}
</style>