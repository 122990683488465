<template>
    <v-container>
        <v-expansion-panels>
            <v-expansion-panel>
                <v-expansion-panel-header>音声設定</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row>
                        <v-switch
                            :input-value="playWarningSound"
                            :label="`警告時に音を鳴らす: ${playWarningSound ? 'オン' : 'オフ'}`"
                            @change="togglePlayWarningSound(!playWarningSound)"
                        ></v-switch>
                    </v-row>
                    <v-row>
                        <v-switch
                            :input-value="playErrorSound"
                            :label="`エラー時に音を鳴らす: ${playErrorSound ? 'オン' : 'オフ'}`"
                            @change="togglePlayErrorSound(!playErrorSound)"
                        ></v-switch>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>   
            <v-expansion-panel>
                <v-expansion-panel-header>表示設定</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row>
                        <v-switch
                            :disabled="!showWanringSwitch"
                            :input-value="showWarning"
                            :label="`警告表示: ${showWarning ? 'オン' : 'オフ'}`"
                            @change="toggleShowWarning(!showWarning)"
                        ></v-switch>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>   
        </v-expansion-panels>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    data() {
        return {
            showWanringSwitch: process.env.VUE_APP_DISABLE_WARNING_CARD_TOGGLE === 'true',
        };
    },
    methods: {
        ...mapActions(['togglePlayWarningSound']),
        ...mapActions(['togglePlayErrorSound']),
        ...mapActions(['toggleShowWarning']),
    },
    computed: {
        ...mapGetters(['playWarningSound']),
        ...mapGetters(['playErrorSound']),
        ...mapGetters(['showWarning']),
    }
}
</script>

<style scoped>

.setting__header {
    position: absolute;
    width: 160px; 
    height: 54px;
    text-align: center;
    font: normal normal bold 24px/32px Segoe UI !important;
    letter-spacing: 0px;
    opacity: 1;
}


.setting__item {
    position: absolute;
    width: 160px; 
    height: 54px;
    text-align: center;
    font: normal normal bold 24px/32px Segoe UI !important;
    letter-spacing: 0px;
    opacity: 1;
}


</style>