<template>
  <v-dialog persistent max-width="600px" :retain-focus="false" v-model="c_recoverableErrorDialogVisible">
      <template v-slot:activator="{c_recoverableErrorDialogVisible}"></template> <!-- eslint-disable-line -->
      <v-toolbar v-if="titleType == 'fatal'" flat dark class="dialog__title" color="#FF0000" height="71">
        <v-icon large class="mr-2">$vuetify.icons.infoWarning</v-icon>
        致命的なエラー
        <v-spacer></v-spacer>
        <v-btn v-if="closeButtonVisible" dark class="dialog__closebutton" color="#707070" @click="close">
            <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-toolbar v-if="titleType == 'error'" flat dark class="dialog__title" color="#FF0000" height="71">
        <v-icon large class="mr-2">$vuetify.icons.infoWarning</v-icon>
        エラー
        <v-spacer></v-spacer>
        <v-btn v-if="closeButtonVisible" dark class="dialog__closebutton" color="#707070" @click="close">
            <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-toolbar v-if="titleType == 'warning'" flat dark class="dialog__title" color="#F9A825" height="71">
        <v-icon large class="mr-2">$vuetify.icons.infoWarning</v-icon>
        警告
        <v-spacer></v-spacer>
        <v-btn v-if="closeButtonVisible" dark class="dialog__closebutton" color="#707070" @click="close">
            <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-toolbar v-if="titleType == 'none'" flat dark class="dialog__title" color="#707070" height="71">
        <v-icon large class="mr-2">$vuetify.icons.infoWarning</v-icon>
        <v-spacer></v-spacer>
        <v-btn v-if="closeButtonVisible" dark class="dialog__closebutton" color="#707070" @click="close">
            <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card class="dialog">
        <v-tabs v-model="activeTab">
            <v-tab class="dialog__redtab" v-if="fatalErrors.length > 0">致命的なエラー</v-tab>
            <v-tab class="dialog__redtab" v-if="recoverableErrors.length > 0">エラー</v-tab>
            <v-tab class="dialog__yellowtab" v-if="warnings.length > 0">警告</v-tab>
        </v-tabs>
        <v-tabs-items v-model="activeTab">
            <v-tab-item v-if="fatalErrors.length > 0">
                <v-card class="dialog">
                    <v-card-text class="dialog__message mt-10 ">
                        以下の致命的なエラーが発生しました。
                        <v-list class="overflow-y-auto" :height=150>
                            <template v-for="(item, index) in fatalErrors">
                                <v-list-item :key="index" class="mt-n6">
                                    <v-card-text :class="'dialog__errorlist'">
                                        {{ convertErrorTemplate(item) }}
                                        <v-divider :key="index"></v-divider>
                                    </v-card-text>
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item v-if="recoverableErrors.length > 0">
                <v-card class="dialog">
                    <v-card-text class="dialog__message mt-10 ">
                        以下のエラーが発生しました。エラーを解除してください。
                        <v-list class="overflow-y-auto" :height=150>
                            <template v-for="(item, index) in recoverableErrors">
                                <v-list-item :key="index" class="mt-n6">
                                    <v-card-text :class="[(item.hw_id === 'crawler_io_control' && item.code === parseInt('0x8004')) ? 'dialog__errorlist_emergencyStop' : 'dialog__errorlist']">
                                        {{ convertErrorTemplate(item) }}
                                        <v-divider :key="index"></v-divider>
                                    </v-card-text>
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-card-text>
                    <!-- <v-card-actions>
                        <v-layout justify-end class="mb-5">
                            <v-btn rounded dark class="dialog__button mx-3" color="#0099FF" @click="resetError">エラー解除</v-btn>
                        </v-layout>
                    </v-card-actions> -->
                </v-card>
            </v-tab-item>
            <v-tab-item v-if="warnings.length > 0">
                <v-card class="dialog">
                    <v-card-text class="dialog__message mt-10 ">
                        以下の警告が発生しました。
                        <v-list class="overflow-y-auto" :height=150>
                            <template v-for="(item, index) in warnings">
                                <v-list-item :key="index" class="mt-n6">
                                    <v-card-text :class="'dialog__errorlist'">
                                        {{ convertErrorTemplate(item) }}
                                        <v-divider :key="index"></v-divider>
                                    </v-card-text>
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
        <v-card-actions v-if="recoverableErrors.length > 0">
            <v-layout justify-end class="mb-5">
                <v-btn rounded dark class="dialog__button mx-3" color="#0099FF" @click="resetError">エラー解除</v-btn>
            </v-layout>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
import { convert } from '@ricoh-srg-system/error-stringizer'
export default {
    props: {
        recoverableErrorDialogVisible: { type: Boolean },
        isEmergencyStopPushed: { type: Boolean },
        fatalErrors: { type: Array },
        recoverableErrors: { type: Array },
        warnings: {type: Array},
        liftStop: { type: Boolean },
        closeButtonVisible: { type: Boolean },
    },
    data() {
        return {
            activeTab: 0,
            localRecoverableErrorDialogVisible: this.recoverableErrorDialogVisible
        }
    },
    created() {
    },
    computed: {
        c_recoverableErrorDialogVisible: {
            get() {
                return this.localRecoverableErrorDialogVisible
            }
        },
        titleType() {
            if (this.fatalErrors.length > 0) {
                return 'fatal'
            }
            else if (this.fatalErrors.length == 0 && this.recoverableErrors.length > 0) {
                return 'error'
            }
            else if (this.warnings.length > 0 ) {
                return 'warning'
            }
            else {
                return 'none'
            }
        },
    },
    methods: {
        resetError() {
            this.$emit('resetError')
        },
        close() {
            this.$emit('close')
        },
        convertErrorTemplate(item) {
            return convert(JSON.stringify(item), window.navigator.language)
        },
        initDiag() {
            if (this.closeButtonVisible) {
                if (this.fatalErrors.length == 0 && this.recoverableErrors.length == 0 && this.warnings.length == 0) {
                    this.localRecoverableErrorDialogVisible = false
                }
            }
            else {
                if (this.recoverableErrors.length > 0 ) {
                    this.localRecoverableErrorDialogVisible = true
                }
                else {
                    this.localRecoverableErrorDialogVisible = false
                }
            }
        }
    },
    watch: {
        recoverableErrorDialogVisible : {
            handler(newVal) {
                this.localRecoverableErrorDialogVisible = newVal
                this.activeTab = 0
            }
        },
        fatalErrors : {
            handler() {
                this.initDiag()
            }
        },
        recoverableErrors : {
            handler() {
                this.initDiag()
            }
        },
        warnings : {
            handler() {
                this.initDiag()
            }
        },
    }
}
</script>

<style scoped>
.dialog {
    max-width: 600px;
    min-height: 216px;
}
.dialog__button {
  min-width: 202px !important;
  height: 54px !important;
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid;
  border-radius: 26px;
  opacity: 1;
  text-align: left;
  font: normal normal bold 24px/32px Segoe UI !important;
  letter-spacing: 0px;
}
.dialog__closebutton {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 26px;
  border: 2px solid;
  font: normal normal bold 24px/32px Segoe UI !important;
  opacity: 1;
  letter-spacing: 0px;
}

.dialog__message {
  text-align: left;
  font: normal normal normal 24px/32px Segoe UI;
  letter-spacing: 0px;
  color: #000000 !important;
  opacity: 1;
}
.dialog__errorlist {
  font: normal normal normal 16px/24px Segoe UI;
  letter-spacing: 0px;
  color: #000000 !important;
  opacity: 1;
}
.dialog__errorlist_emergencyStop {
  font: normal normal normal 16px/24px Segoe UI;
  letter-spacing: 0px;
  color: #FF0000 !important;
  opacity: 1;
}
.dialog__title {
  text-align: left;
  font: normal normal bold 24px/32px Segoe UI;
  letter-spacing: 0px;
  opacity: 1;
}
.dialog__redtab {
  color: #FF0000;
}
.dialog__yellowtab {
  color: #F9A825
}
</style>