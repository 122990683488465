<template>
  <div>
    <v-dialog persistent max-width="621px" :retain-focus="false" v-model="c_confirmVisible">
      <template v-slot:activator="{c_confirmVisible}"></template> <!-- eslint-disable-line -->
      <v-card class="dialog">
        <v-toolbar flat dark class="dialog__title" color="#009999" height="40">
          <v-icon large class="mr-2">$vuetify.icons.info</v-icon>確認
        </v-toolbar>
        <v-card-text class="dialog__message mt-10">
          {{confirmMessage}}
        </v-card-text>
        <v-card-actions>
          <v-layout justify-end class="mb-3">
            <v-btn rounded dark class="dialog__button" color="#707070" @click="cancel">キャンセル</v-btn>
            <v-btn rounded dark class="dialog__button mx-3" color="#0099FF" @click="confirm">OK</v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <progress-dialog :progress-visible="c_progressVisible" :progress-message="c_progressMessage" :info-visible="c_infoVisible" :info-type="c_infoType" :info-message="c_infoMessage" @closeDialog="closeDialog"></progress-dialog>
  </div>
</template>

<script>
import ProgressDialog from './ProgressDialog.vue'
export default {
  components: {
    ProgressDialog
  },
  props: {
    confirmVisible: { type: Boolean },
    confirmMessage: { type: String },
    progressVisible: { type: Boolean, default: false },
    progressMessage: { type: String, default: '' },
    infoVisible: { type: Boolean, default: false },
    infoType: { type: String, default: 'normal' },
    infoMessage: { type: String, default: '' },
  },
  computed: {
    c_confirmVisible: {
      get() {
        return this.confirmVisible
      },
      set(val) {
        this.$emit('confirm-visible', val)
      }
    },
    c_progressVisible: {
      get() {
        return this.progressVisible
      },
    },
    c_progressMessage: { 
      get() {
        return this.progressMessage
      }, 
    },
    c_infoVisible: { 
      get() {
        return this.infoVisible
      },
    },
    c_infoType: { 
      get() {
        return this.infoType
      },  
    },
    c_infoMessage: { 
      get() {
        return this.infoMessage
      },
    },
  },
  methods: {
    confirm() {
      this.$emit('confirm')
    },
    cancel() {
      this.c_confirmVisible = false
    },
    closeDialog() {
      this.$emit('closeDialog')
    }
  }
}
</script>

<style scoped>
.dialog {
  max-width: 621px;
  min-height: 236px;
}
.dialog__button {
    min-width: 164px !important;
    height: 54px !important;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 26px;
    opacity: 1;
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI !important;
    letter-spacing: 0px;
}
.dialog__message {
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
    color: #000000 !important;
    opacity: 1;
    white-space: pre-wrap;
}
.dialog__title {
  text-align: left;
  font: normal normal bold 24px/32px Segoe UI;
  letter-spacing: 0px;
  opacity: 1;
}
</style>