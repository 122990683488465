import {EventEmitter} from 'events'
import LiveStreamingCommand from '../class/LiveStreamingCommand'
import { Credentials } from "../assets/credential"
import AWSUtil from '../class/AWSUtil'
import * as LSSDK from "../util/ricoh-ls-sdk"

export class LiveStreaming extends EventEmitter {
    serialNo = null
    roomId = null
    client = null
    maincamConnectionId = null
    ptzConnectionId = null
    thermalConnectionId = null
    broadcastingDevice = null
    callbackList = []

    constructor(serialNo) {
        super()
        this.serialNo = serialNo
        // const randomStr = this.createLStokenRandomStr()
        // this.roomId = `${serialNo}-${randomStr}`
        this.roomId = `${serialNo}-0000`
        LiveStreamingCommand.startLiveStreaming(serialNo, this.roomId)
        this.createAndJoin()
    }

    createLStokenRandomStr() {
        let length = 4
        let str = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890.%+^_"`{|}~<>\\-'
        let strLength = str.length
        let result = ''
    
        for (let i = 0; i < length; i++) {
            result += str[Math.floor(Math.random() * strLength)]
        }
        return result 
    }

    setEventSubscriber(object, type, callback) {
        let callbackSet = {
            object: object,
            type: type,
            callback: callback
        }
        this.callbackList.push(callbackSet)
    }
  
    async createAndJoin() {
        const stream = await this.getDummyMedia()
        if (stream != null) {
            const localLSTracks = stream.getTracks().map((mediaStreamTrack) => {
                return new LSSDK.LSTrack(mediaStreamTrack, stream, { mute: "softmute" })
            });

            const access_token = await this.getLsAccessToken(this.roomId)

            try {
                this.client = this.createClient()

                const connectOption = { localLSTracks };
                if (Credentials.SIGNALING_URL) {
                    connectOption.signalingURL = Credentials.SIGNALING_URL;
                }

                this.client.connect(Credentials.CLIENT_ID, access_token, connectOption)
            } 
            catch (e) {
                console.log(e);
            }
        }
    }
  
  async getDummyMedia() {
      return new Promise((resolve) => {
          if ("function" !== typeof HTMLCanvasElement.prototype.captureStream) {
              resolve(null);
          }
          const canvas = document.createElement("canvas");
          canvas.getContext("2d");
          resolve(canvas.captureStream())
      })
  }

  async getLsAccessToken(roomId) {
      const axios = require('axios')
      const url = 'https://api.dev-crawlerrobo.trial.ricoh/getlivestreamingaccesstoken'

      // ヘッダーの署名を取得する
      let signedHeaderInfo = await AWSUtil.createSigV4(url, 'get')

      // 認可情報と署名したヘッダーを利用してAPIをコールする
      let self = this
      return axios.get(url, {
          headers: {
              'Authorization': signedHeaderInfo.Authorization,
              'X-Host-Override': signedHeaderInfo.Host,
              'X-Amz-Date': signedHeaderInfo['X-Amz-Date'],
              'X-Amz-Security-Token': signedHeaderInfo['x-amz-security-token'],
              'Room-ID': roomId
          },
      })
      .then((res) => res.data)
  }

  createClient() {
    // clientの初期化
    const client = new LSSDK.Client();

    // イベントの待ち受けを行う分イベントの登録
    client.addEventListener("connecting", (e) => {
        console.log(`[${e.type}]`, client.getState());
    });
    client.addEventListener("open", (e) => {
        console.log(`[${e.type}]`, client.getState());
    })
    client.addEventListener("closing", (e) => {
        console.log(`[${e.type}]`, client.getState());
    })
    client.addEventListener("close", (e) => {
        console.log(`[${e.type}]`, client.getState());
    })
    client.addEventListener("error", (e) => {
        console.table(e.detail);
        this.createAndJoin()
    })

    client.on("addremoteconnection", ({ connection_id, meta }) => {
        if (meta['stream.id'] != null) {
            console.log(meta['stream.id'])
            if(meta['stream.id'].indexOf('built-in maincam: area') !== -1) {
                this.maincamConnectionId = connection_id
            }
            else if (meta['stream.id'].indexOf('ptz') !== -1) {
                this.ptzConnectionId = connection_id
            }
            else if(meta['stream.id'].indexOf('thermal') !== -1) {
                this.thermalConnectionId = connection_id
            }
            else {
                // nothing
            }
        }
        console.log('add: ', connection_id)
    });
    client.on("addremotetrack", async ({ connection_id, mediaStreamTrack, stream, meta, mute }) => {
        // metaに入っている情報でmaincam PTZ, サーモコンポーネントへ振り分けしてイベントを発火して画面に描画処理する
        try {
            let $video = document.getElementById(`${connection_id}`);
            if ($video === null) {
                $video = document.createElement("video");
                $video.id = connection_id;
                $video.muted = "muted"

                switch (connection_id) {
                    case this.maincamConnectionId:
                        // maincamイベントを発火する
                        this.emit('maincam_start', $video, this.maincamConnectionId, mediaStreamTrack, stream)
                        break
                    case this.ptzConnectionId:
                        // ptzイベントを発火する
                        this.emit('ptz_start', $video, this.ptzConnectionId, mediaStreamTrack, stream)
                        break
                    case this.thermalConnectionId:
                        // thermalイベントを発火する
                        this.emit('thermal_start', $video, this.thermalConnectionId, mediaStreamTrack, stream)
                        break
                    default:
                        // nothing
                        break
                }
            }
        }
        catch(e) {
            console.log(e)
        }
    });
    client.on("removeremoteconnection", ({ connection_id, meta, mediaStreamTrack }) => {
        console.log('remove: ', connection_id)
        switch (connection_id) {
            case this.maincamConnectionId:
                // maincamイベントを発火する
                this.emit('maincam_stop')
                break
            case this.ptzConnectionId:
                // ptzイベントを発火する
                this.emit('ptz_stop')
                break
            case this.thermalConnectionId:
                // thermalイベントを発火する
                this.emit('thermal_stop')
                break
            default:
                // nothing
                break
        }
    });
    client.on("updateremoteconnection", ({ connection_id, meta }) => {
        console.log('update: ', connection_id);
    });
    client.on("updatemute", ({ connection_id, mediaStreamTrack, stream, mute }) => {
        console.log('updatemute: ', connection_id, mediaStreamTrack.kind);
    });
    client.on("changestability", ({ connection_id, stability }) => {
        console.log('changestability: ', connection_id, stability);
    });
    client.on("updateremotetrack", ({ connection_id, mediaStreamTrack, stream, meta }) => {
        console.log(connection_id, mediaStreamTrack, stream, meta);
        // connectionの内容(カメラ)が変わった時にここに来る。
        // これまで繋いでいたオブジェクトを削除する。broadCastingDeviceから判断する
        // ここからvideoを送り込む先を変える
    });

      return client;
  }
  reconnect() {
      this.client.disconnect()
      this.client = null
      let self = this
      setTimeout(() => {
          self.createAndJoin()
      }, 3000)
      LiveStreamingCommand.reconnectLiveStreaming(this.serialNo)
  }
  finalize() {
    // LiveStreamingの配信継続コマンドトピックサブスクライブを解除し、接続を切断
    LiveStreamingCommand.stopLiveStreaming()
    //LiveStreamingのClient削除
    if (this.client != null) {
    this.client.disconnect();
    }
  }
}