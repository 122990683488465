<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
    <g id="BTN016p_right.svg" @mousedown="displayWhiteTriangle" @mouseup="displayTransparentTriangle" @mouseleave="displayTransparentTriangle" @touchstart="displayWhiteTriangle" @touchend="displayTransparentTriangle">
      <rect id="長方形_11" data-name="長方形 11" class="cls-1" width="30" height="30"/>
      <path v-show="!isPushed" id="三角形_1" data-name="三角形 1" class="cls-2" d="M447,127l16,15-16,15V127Zm2,5v20l11-10Z" transform="translate(-440 -127)"/>
      <path v-show="isPushed" id="三角形_1" data-name="三角形 1" class="cls-2" d="M510,142l-16,15V127Z" transform="translate(-487 -127)"/>
    </g>
</svg>
</template>

<script>
export default {
  data() {
    return {
        isPushed: false
    }
  },
  methods: {
    displayWhiteTriangle() {
        this.isPushed = true
    },
    displayTransparentTriangle() {
        this.isPushed = false
    }
  }
}
</script>

<style scoped>
  .cls-1, .cls-2 {
    fill: #fff;
  }
  .cls-1 {
    opacity: 0;
  }
  .cls-2 {
    fill-rule: evenodd;
  }
  </style>