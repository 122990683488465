<template>
  <div class="outer">
    <thermal-camera ref="thermal_camera" class="thermal-camera-view"
      @addThermal="addThermal($event)">
    </thermal-camera>
    <crawler-controller ref="robot_ctrl" class="controller-area" v-show="isAbleToCrawlerCtrl"
      :cameraMode="cameraMode"
      @registerThermal="registerThermal"
      @switchCtrlModeResponse="switchCtrlModeResponse($event)">
    </crawler-controller>
  </div>
</template>

<script>
import ThermalCamera from "../parts/ThermalCamera.vue"
import CrawlerController from '../parts/CrawlerController.vue'

export default {
  components: {
    ThermalCamera,
    CrawlerController
  },
  props: {
    cameraMode: { type: String }
  },
  data() {
    return {
      operatingMode: this.$store.state.selectedMode,
      thermalConnectionId: null,
      isAbleToCrawlerCtrl: false
    }
  },
  watch: {
  },
  computed: {
  },
  beforeDestroy() {
  },
  created() {
  },
  mounted() {
  },
  methods: {
    setLiveStreamingEvent(liveStreaming) {
      this.$refs.thermal_camera.setLiveStreamEventCallback(liveStreaming) 
    },
    reconnect() {
      this.$refs.thermal_camera.reconnect()
    },
    addThermal(event) {
      this.thermalConnectionId = event.connectionId
    },
    registerThermal() {
      this.$emit('registerThermal', { connectionId: this.thermalConnectionId , resetZoom: null })
    },
    setRobotParameter(linearVelocity, slewingSpeed) {
      this.$refs.robot_ctrl.setDutyScale(linearVelocity)
      this.$refs.robot_ctrl.setDutyScaleTurn(slewingSpeed)
    },
    sendGetCtrlCmd() {
      this.$refs.robot_ctrl.getRobotCtrl()
    },
    switchCtrlModeResponse(event) {
      this.$emit('switchCtrlModeResponse', event)
    },
    disableCrawlerCtrl() {
      this.isAbleToCrawlerCtrl = false
      this.$refs.robot_ctrl.destroy()
    },
    enableCrawlerCtrl() {
      this.$refs.robot_ctrl.destroy()
      this.isAbleToCrawlerCtrl = true
      this.$refs.robot_ctrl.initialize()
    },
  }
}
</script>

<style scoped>
.controller-area {
  position: absolute;
  bottom: 9%;
  right: 5px;
}
.outer {
  width: inherit;
  height: inherit;
}
.thermal-camera-view {
  width: 100%;
  height: 100%;
  background-color: #000000;
}
</style>