<template>
    <div>
      <v-icon v-show="!isMagnifiedMap && (signalCondition>=67 && signalCondition<=100)">$vuetify.icons.threeBar</v-icon>
      <v-icon v-show="!isMagnifiedMap && (signalCondition>=34 && signalCondition<=66)">$vuetify.icons.twoBar</v-icon>
      <v-icon v-show="!isMagnifiedMap && (signalCondition>=1 && signalCondition<=33)">$vuetify.icons.oneBar</v-icon>
      <v-icon v-show="!isMagnifiedMap && (signalCondition===0 || signalCondition==null)">$vuetify.icons.noBar</v-icon>

      <v-icon large v-show="isMagnifiedMap && (signalCondition>=67 && signalCondition<=100)">$vuetify.icons.threeBar</v-icon>
      <v-icon large v-show="isMagnifiedMap && (signalCondition>=34 && signalCondition<=66)">$vuetify.icons.twoBar</v-icon>
      <v-icon large v-show="isMagnifiedMap && (signalCondition>=1 && signalCondition<=33)">$vuetify.icons.oneBar</v-icon>
      <v-icon large v-show="isMagnifiedMap && (signalCondition===0 || signalCondition==null)">$vuetify.icons.noBar</v-icon>
    </div>
</template>

<script>
export default {
    props: {
        signalCondition: { type: Number },
        isMagnifiedMap: { type: Boolean, default:false },
    }
}
</script>

<style>

</style>