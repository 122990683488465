<template>
    <div>
        <v-dialog persistent max-width="1170px" v-model="c_dialogVisible">
            <template v-slot:activator="{c_dialogVisible}"></template> <!-- eslint-disable-line -->
            <v-card class="dialog">
                <v-toolbar flat dark class="dialog__title" color="#4A4B4C" height="40">ルート登録</v-toolbar>
                <v-container fluid>
                    <v-row no-gutters class="dialog__item-container">
                        <v-col cols="7">
                            <route-view ref="registered_route_view"
                                :initCenter="[35.29708092453554, 139.57834696746266]"
                                :initZoom="18"
                                :enableContextMenu="false"
                                :enableMultiSelect="false"
                                :centerRobot="false"
                                :enableExpandShrinkCtrl="false"
                            ></route-view>
                            <p v-html="leafletLicense" class="dialog__map-license"></p>
                        </v-col>
                        <v-col cols="5">
                            <v-card-text class="dialog__item">ルート名</v-card-text>
                            <v-text-field outlined class="ml-4" :rules="[rules.required]" v-model="routeName"></v-text-field>
                            <v-card-actions class="mt-16">
                                <v-layout justify-end>
                                    <v-btn rounded dark class="dialog__button" color="#707070" @click="cancelRegisterRouteInformation">キャンセル</v-btn>
                                    <v-btn rounded :dark="routeName!==''" :disabled="routeName===''" class="dialog__button ml-5 mb-5" color="#0099FF" @click="registerRouteInformation">登録</v-btn>
                                </v-layout>
                            </v-card-actions>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
        <progress-dialog :progress-visible="isShowProgressDialog" :progress-message="progressMessage" :info-visible="isShowInfoDialog" :info-type="infoType" :info-message="infoMessage" @closeDialog="endRouteRegistration"></progress-dialog>
    </div>
</template>

<script>
import MqttCommand from '../util/MqttCommand'
import RouteView from '@ricoh-srg-system/routeview-component'
import ProgressDialog from './ProgressDialog.vue'
export default {
    components: {
        RouteView,
        ProgressDialog
    },
    props: {
        dialogVisible: { type: Boolean },
    },
    data() {
        return {
            leafletLicense:'&copy<a href="http://osm.org/copyright">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
            isShowProgressDialog: false,
            progressMessage: '',
            isShowInfoDialog: false,
            infoType: 'normal',
            infoMessage: '',
            routeName: '',
            topicRegExp: /ap-northeast-1_([0-9a-zA-Z]){9}\/([0-9a-z]){12,}\/usr_program\/route-check-subject-registration\/reply/,
            sendTopic: `${sessionStorage.getItem('userPoolId')}/${this.$store.state.selectedCrawler}/usr_program/route-check-subject-registration`,
            recvTopic: `${sessionStorage.getItem('userPoolId')}/${this.$store.state.selectedCrawler}/usr_program/route-check-subject-registration/reply`,
            allRegisteredWayPointsList: [],
            timeoutId: '',
            rules: {
                required: value => !!value || '必須入力です。'
            }
        }
    },
    computed: {
        c_dialogVisible: {
            get() {
                return this.dialogVisible
            },
            set(val) {
                this.$emit('dialog-visible', val)
            }
        }
    },
    methods: {
        async initialize() {
            // MQTTコマンドで登録した現在地情報を全て取得する
            MqttCommand.subscribe(this.recvTopic)

            let type = 'cmd'
            let name = 'get.route'
            let payload = {}
            let packet = await MqttCommand.createPacket(type, name, payload)
            this.routeGetPacketId = packet['packet.id']
            let self = this
            MqttCommand.setSubscribeTopicHandler(this.topicRegExp, this.routeGetPacketId, function(topic, data) {
                if (data['replied.to'] === this) {
                    // 受け取ったdataのwaypointを地図にプロットして表示する
                    MqttCommand.removeSubscribeTopicHandler(self.topicRegExp, self.routeGetPacketId)
                    self.allRegisteredWayPointsList = data.payload['way.points']
                    self.$refs.registered_route_view.setWaypoints(JSON.stringify(self.allRegisteredWayPointsList))
                }
            }.bind(this.routeGetPacketId))
            .then(()=>{
                MqttCommand.sendPacket(this.sendTopic, packet)
                this.$refs.registered_route_view.redraw()
            })
        },
        async registerRouteInformation() {
            this.progressMessage = `ルート名：${this.routeName}を登録中です。`
            this.isShowProgressDialog = true
            let type = 'cmd'
            let name = 'register.route'
            let payload = {
                'route.name': this.routeName
            }
            let packet = await MqttCommand.createPacket(type, name, payload)
            this.routeRegisterPacketId = packet['packet.id']
            let self = this
            MqttCommand.setSubscribeTopicHandler(this.topicRegExp, this.routeRegisterPacketId, function(topic, data) { // eslint-disable-line
                if (data['replied.to'] === this) {
                    if (data.payload['has.succeeded']) {
                        self.infoType = 'normal'
                        self.infoMessage = `ルート名：${self.routeName}を登録しました。`
                        self.isSuccessRegistration = true
                        self.isShowInfoDialog = true
                    }
                    else {
                        self.infoType = 'error'
                        self.infoMessage =`ルート名：${self.routeName}の登録が失敗しました。`
                        self.isSuccessRegistration = false
                        self.isShowInfoDialog = true
                    }
                    clearTimeout(self.timeoutId)
                }
            }.bind(this.routeRegisterPacketId))
            .then(() => {
                MqttCommand.sendPacket(this.sendTopic, packet)
                this.timeoutId = setTimeout(function() {
                    self.infoType = 'error'
                    self.infoMessage = `ルート名：${self.routeName}の登録に失敗した可能性があります。\n時間をおいてルート一覧画面で\n登録状況をチェックしてください。`
                    self.isSuccessRegistration = false
                    self.isShowInfoDialog = true
                    clearTimeout(this.timeoutId)
                }.bind(this), 60000)
            })
        },
        async endRouteRegistration() {
            MqttCommand.removeSubscribeTopicHandler(this.topicRegExp, this.routeGetPacketId)
            MqttCommand.removeSubscribeTopicHandler(this.topicRegExp, this.routeRegisterPacketId)

            this.isShowProgressDialog = false
            this.progressMessage = ''
            this.isShowInfoDialog = false
            this.infoType = 'normal'
            this.infoMessage = ''
            if (this.isSuccessRegistration) {
                MqttCommand.unsubscribe(this.recvTopic)
                this.c_dialogVisible = false
                this.routeName = ''
                this.routeGetPacketId = undefined
                this.routeRegisterPacketId = undefined
            }
            this.$emit('finish-route-registration', { result: this.isSuccessRegistration })
        },
        async cancelRegisterRouteInformation() {
            MqttCommand.removeSubscribeTopicHandler(this.topicRegExp, this.routeGetPacketId)
            MqttCommand.removeSubscribeTopicHandler(this.topicRegExp, this.routeRegisterPacketId)
            MqttCommand.unsubscribe(this.recvTopic)
            clearTimeout(this.timeoutId)
            this.routeName = ''
            this.c_dialogVisible = false
            this.routeGetPacketId = undefined
            this.routeRegisterPacketId = undefined

            this.$emit('finish-route-registration', { result: null })
        }
    }
}
</script>

<style scoped>
.dialog {
    width: 1170px;
    height: 521px;
}
.dialog__button {
    min-width: 164px !important;
    height: 54px !important;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 26px;
    opacity: 1;
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
}
.dialog__item {
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
    color: #000000 !important;
    opacity: 1;
}
.dialog__item-container {
    max-width: 100%;
    height: 423px;
}
.dialog__map-license {
    position: absolute;
    font-size: 10px;
    bottom: 0;
}
.dialog__title {
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
    opacity: 1;
}
</style>