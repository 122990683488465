<template>
  <v-dialog persistent max-width="621px" v-model="c_checkStartConfirmDialogVisible">
    <template v-slot:activator="{c_checkStartConfirmDialogVisible}"></template> <!-- eslint-disable-line -->
    <v-card class="dialog">
      <v-toolbar flat dark class="dialog__title" color="#4A4B4C" height="40">点検の確認</v-toolbar>
      <v-container fluid>
        <v-card-text class="dialog__item">ルート名</v-card-text>
        <v-card-text class="dialog__item ml-6 mt-n3" v-show="isFromRouteList">{{routeName}}</v-card-text>
        <v-row v-show="isFromHome">
          <v-col cols="7">
            <v-select outlined dense class="dialog__item-selector ml-9" :items="routeList" item-text="routeName" item-value="routeId" v-model="selectedRoute"></v-select>
          </v-col>
          <v-col cols="5">
            <v-btn class="ml-5" color="#0099FF" dark @click="pushReloadRouteList"><v-icon>mdi-autorenew</v-icon></v-btn>
          </v-col>
        </v-row>
        <v-card-text class="dialog__item">使用するcrawler</v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-select outlined dense class="dialog__item-selector ml-9" v-show="isFromRouteList" :items="availableCrawlerList" :item-text="item => item.robotStatus.robotName != null ? item.robotStatus.robotName : item.robotStatus.serialNo" item-value="robotStatus.serialNo" v-model="selectedCrawlerSerialNo"></v-select>
              <v-card-text class="dialog__item ml-6 mt-n3" v-show="isFromHome">{{crawlerName != null ? crawlerName : crawlerId}}</v-card-text>
              <p v-show="(selectedCrawler != null ? selectedCrawler.robotStatus.isBackCoverOpened : false)" class="dialog__item-warning ml-10 mt-n5">
                背面カバーが開いています。背面カバーを閉めてから開始してください。
              </p>
            </v-col>
          </v-row>
        </v-container>
        <v-container pb-0>
          <v-row>
            <v-col>
             <v-checkbox class="dialog__item mt-0" v-model="isExecuteContinuousCheck" :label="'連続実行する'"></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
        <v-container pb-0>
          <v-row>
            <v-col cols="6">
             <v-checkbox class="dialog__item mt-0" v-model="isStartingFromChargingStation" :label="'充電ステーションから発進する'"></v-checkbox>
            </v-col>
            <v-col cols="6">
             <v-checkbox class="dialog__item mt-0" v-model="isReturningToChargingStation" :label="'充電ステーションに帰還する'"></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
        <v-container>
          <v-row>
            <v-col>
             <v-checkbox class="dialog__item mt-0" v-model="isExecuteResetPtzOriginPosition" :label="'PTZカメラ原点リセットを実行'"></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-layout justify-end>
            <v-btn rounded dark class="dialog__button" color="#707070" @click="cancel">キャンセル</v-btn>
            <v-btn rounded :dark="!(selectedCrawler != null ? selectedCrawler.robotStatus.isBackCoverOpened : false)" class="dialog__button mx-3" color="#0099FF" :disabled="(selectedCrawler != null ? selectedCrawler.robotStatus.isBackCoverOpened : false)" @click="startCheck">点検開始</v-btn>
          </v-layout>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    checkStartConfirmDialogVisible: { type: Boolean },
    routeId: { type: String },
    routeName: { type: String },
    crawlerId: { type: String },
    crawlerName: { type: String },
    availableCrawlerList: { type: Array },
    routeList: { type: Array },
    isFromRouteList: { type: Boolean },
    isFromHome: { type: Boolean }
  },
  data() {
    return {
      selectedCrawlerSerialNo: null,
      selectedCrawler: null,
      selectedRoute: null,
      isExecuteContinuousCheck: false,
      isStartingFromChargingStation: false,
      isReturningToChargingStation: false,
      isExecuteResetPtzOriginPosition: true
    }
  },
  beforeDestroy() {
    this.finalize()
  },
  computed: {
    c_checkStartConfirmDialogVisible: {
      get() {
          return this.checkStartConfirmDialogVisible
      },
    }
  },
  watch: {
    selectedCrawlerSerialNo(val) {
      this.selectedCrawler = this.availableCrawlerList.find((item) => item.robotStatus.serialNo === val)
    },
    crawlerId(val) {
      this.selectedCrawler = this.availableCrawlerList.find((item) => item.robotStatus.serialNo === val)
    }
  },
  methods: {
    startCheck() {
      if (this.isFromRouteList) {
        this.$emit('startCheckExecution', { 'serialNo': this.selectedCrawlerSerialNo, 'routeId': null, 'isExecuteContinuousCheck': this.isExecuteContinuousCheck, 'isStartingFromChargingStation': this.isStartingFromChargingStation, 'isReturningToChargingStation': this.isReturningToChargingStation, 'isExecuteResetPtzOriginPosition': this.isExecuteResetPtzOriginPosition })
      }
      else if(this.isFromHome) {
        this.$emit('startCheckExecution', { 'serialNo': null, 'routeId': this.selectedRoute, 'isExecuteContinuousCheck': this.isExecuteContinuousCheck, 'isStartingFromChargingStation': this.isStartingFromChargingStation, 'isReturningToChargingStation': this.isReturningToChargingStation, 'isExecuteResetPtzOriginPosition': this.isExecuteResetPtzOriginPosition })
      }
    },
    cancel() {
      this.finalize()
      this.$emit('startCheckExecution', { 'serialNo': null, 'routeId': null })
    },
    finalize() {
      this.selectedCrawler = null
      this.selectedRoute = null
      this.isExecuteContinuousCheck = false
      this.isStartingFromChargingStation = false
      this.isReturningToChargingStation = false
      this.isExecuteResetPtzOriginPosition = true
    },
    pushReloadRouteList() {
       this.$emit("reloadRouteList");
    }
  }
}
</script>

<style scoped>
.dialog {
  max-width: 621px;
  min-height: 340px;
}
.dialog__button {
  width: 164px !important;
  height: 54px !important;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 26px;
  opacity: 1;
  text-align: left;
  font: normal normal bold 24px/32px Segoe UI !important;
  letter-spacing: 0px;
}
.dialog__title {
  text-align: left;
  font: normal normal bold 24px/32px Segoe UI;
  letter-spacing: 0px;
  opacity: 1;
}
.dialog__item {
  max-width: 580px;
  text-align: left;
  font: normal normal bold 24px/32px Segoe UI !important;
  letter-spacing: 0px;
  color: #000000 !important;
  opacity: 1;
}
.dialog__item-selector {
  width: 341px !important;
}
.dialog__item-warning {
  font: normal normal bold 14px Segoe UI !important;
  color: #FF0000 !important;
}
</style>