<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
        <g id="diagonal__right.svg" data-name="diagonal_ right.svg" @mousedown="displayWhiteTriangle" @mouseup="displayTransparentTriangle" @mouseleave="displayTransparentTriangle" @touchstart="displayWhiteTriangle" @touchend="displayTransparentTriangle">
            <rect id="長方形_11" data-name="長方形 11" class="cls-1" width="30" height="30"/>
            <path v-show="!isPushed" id="三角形_1" data-name="三角形 1" class="cls-2" d="M214,153V131H192Zm-2-5-15-15h15v15Z" transform="translate(-188 -127)"/>
            <path v-show="isPushed" id="シェイプ_1" data-name="シェイプ 1" class="cls-2" d="M261,131v22l-22-22h22Z" transform="translate(-235 -127)"/>
        </g>
    </svg>
</template>

<script>
export default {
    data() {
        return {
            isPushed: false
        }
    },
    methods: {
        displayWhiteTriangle() {
            this.isPushed = true
        },
        displayTransparentTriangle() {
            this.isPushed = false
        }
    }
}
</script>

<style scoped>
.cls-1, .cls-2 {
    fill: #fff;
}
.cls-1 {
    opacity: 0;
}
.cls-2 {
    fill-rule: evenodd;
}
</style>