<template>
  <div>
    <div class="frame" v-show="hasRect">
      <v-btn rounded dark class="rect-ctrl-button mt-14" color="#009999" @click="centeringZoomWithRect">合わせる</v-btn>
      <v-btn rounded dark class="rect-ctrl-button mt-8" color="#009999" @click="cancelRect">やめる</v-btn>
    </div>
    <v-btn rounded dark class="display-rect-button mt-5" color="#009999" :disabled="hasRect" @click="drawRect">矩形選択</v-btn>
  </div>
</template>

<script>
import { fabric } from 'fabric'
export default {
  props: {
  },
  data() {
    return {
      ptzConnectionId: null,
      hasRect: false
    }
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    addPtzCanvas(connectionId) {
      this.ptzConnectionId = connectionId
      const $baseCanvas = document.createElement('canvas')
      $baseCanvas.id = 'drawing-rect-touch-canvas'
      $baseCanvas.style.position = 'absolute'

      const $parent = document.getElementById('ptz')
      $parent.appendChild($baseCanvas)

      this.canvas = new fabric.Canvas('drawing-rect-touch-canvas')
      this.canvas.on('object:modified', this.getRectProperty)

      const $upperCanvas = document.getElementsByClassName('upper-canvas')[0]
      $upperCanvas.addEventListener('touchend', this.onTouchEnd)
      $upperCanvas.addEventListener('mousedown', this.onMousedown)
      document.addEventListener('contextmenu', this.preventDefaultContextMenu)

      this.adjustCanvasSize()
      
      this.drawReticle()
      this.drawPtzCaption()
    },
    resizePtzCanvas() {
      this.adjustSizeAndPosition()
    },
    destroyPtzCanvas() {
      const $upperCanvas = document.getElementsByClassName('upper-canvas')[0]
      if ($upperCanvas != null) {
        $upperCanvas.removeEventListener('touchend', this.onTouchEnd)
        $upperCanvas.removeEventListener('mousedown', this.onMousedown)
        document.removeEventListener('contextmenu', this.preventDefaultContextMenu)
        this.clearAllCanvas()
      }
      const $baseCanvas = document.getElementById('drawing-rect-touch-canvas')
      if ($baseCanvas != null && $baseCanvas.parentElement != null && $baseCanvas.parentElement.childElementCount > 0) {
          $baseCanvas.parentElement.removeChild($baseCanvas)
      }
    },
    drawReticle() {
      if (this.ptzConnectionId != null) {
        const $ptzCameraImage = document.getElementById(this.ptzConnectionId)
        if ($ptzCameraImage != null) {
          let scale = window.devicePixelRatio
          this.verticalLine = new fabric.Line(
            [$ptzCameraImage.clientWidth / 2 / scale, (($ptzCameraImage.clientHeight / 2) - 50) / scale , $ptzCameraImage.clientWidth / 2 / scale, (($ptzCameraImage.clientHeight / 2) + 50) / scale], {
            stroke: 'black',
            selectable: false,
            hasControls: false,
            evented: false
          })
          this.horizontalLine = new fabric.Line(
            [(($ptzCameraImage.clientWidth / 2) - 50) / scale, $ptzCameraImage.clientHeight / 2 / scale, (($ptzCameraImage.clientWidth / 2) + 50) / scale, $ptzCameraImage.clientHeight / 2 / scale], {
            stroke: 'black',
            angle: 0,
            selectable: false,
            hasControls: false,
            evented: false
            })
          this.canvas.add(this.verticalLine)
          this.canvas.add(this.horizontalLine)
        }
      }
    },
    drawPtzCaption() {
      const $ptzCameraImage = document.getElementById(this.ptzConnectionId)
      if ($ptzCameraImage != null) {
        let scale = window.devicePixelRatio
        this.ptzCaption = new fabric.IText("PTZ", {
          left: 0,
          top: ($ptzCameraImage.clientHeight - 80) / scale,
          fill: '#76FF03',
          fontFamily: "Gothic",
          selectable: false,
          hasControls: false,
          evented: false
        })
        this.canvas.add(this.ptzCaption)
      }
    },
    adjustCanvasSize() {
      const $ptzCameraImage = document.getElementById(this.ptzConnectionId)

      if ($ptzCameraImage != null) {
        if (this.canvas != null) {
          this.canvas.setWidth(parseInt($ptzCameraImage.style.width))
          this.canvas.setHeight(parseInt($ptzCameraImage.style.height))
          this.canvas.renderAll()
        }

        let scale = window.devicePixelRatio
        const $baseCanvas = document.getElementById('drawing-rect-touch-canvas')
        if ($baseCanvas != null) {
          $baseCanvas.width = parseInt($ptzCameraImage.style.width)
          $baseCanvas.height = parseInt($ptzCameraImage.style.height)
          $baseCanvas.style.marginLeft = $ptzCameraImage.style.marginLeft
          $baseCanvas.style.marginTop = $ptzCameraImage.style.marginTop
          let $baseCanvasContext = $baseCanvas.getContext('2d')
          $baseCanvasContext.scale(scale, scale)
        }

        const $upperCanvas = document.getElementsByClassName('upper-canvas')[0]
        if ($upperCanvas != null) {
          $upperCanvas.width = parseInt($ptzCameraImage.style.width)
          $upperCanvas.height = parseInt($ptzCameraImage.style.height)
          $upperCanvas.style.marginLeft = $ptzCameraImage.style.marginLeft
          $upperCanvas.style.marginTop = $ptzCameraImage.style.marginTop
          let $upperCanvasContext = $upperCanvas.getContext('2d')
          $upperCanvasContext.scale(scale, scale)
        }
      }
    },
    adjustReticlePosition() {
      if (this.ptzConnectionId != null) {
        const $ptzCameraImage = document.getElementById(this.ptzConnectionId)
        if ($ptzCameraImage != null) {  
          let scale = window.devicePixelRatio
          if (this.verticalLine != null && this.horizontalLine != null) {
            this.verticalLine.set({'x1': $ptzCameraImage.clientWidth / 2 / scale, 'y1': (($ptzCameraImage.clientHeight / 2) - 50) / scale, 'x2': $ptzCameraImage.clientWidth / 2 / scale, 'y2': (($ptzCameraImage.clientHeight / 2) + 50) / scale})
            this.horizontalLine.set({'x1': (($ptzCameraImage.clientWidth / 2) - 50) / scale, 'y1': $ptzCameraImage.clientHeight / 2 / scale, 'x2': (($ptzCameraImage.clientWidth / 2) + 50) / scale, 'y2': $ptzCameraImage.clientHeight / 2 / scale})
            if (this.canvas.contextContainer != null) {
              this.canvas.renderAll();
            }
          }
        }
      }
    },
    adjustPtzCaptionPosition() {
      if (this.ptzConnectionId != null) {
        const $ptzCameraImage = document.getElementById(this.ptzConnectionId)
        let scale = window.devicePixelRatio
        if (this.ptzCaption != null && $ptzCameraImage != null) {
          this.ptzCaption.set({'top': ($ptzCameraImage.clientHeight - 80) / scale, 'left': 0, })
          if (this.canvas.contextContainer != null) {
            this.canvas.renderAll();
          }
        }
      }
    },
    adjustRectPosition() {
      if (this.ptzConnectionId != null) {
        const $ptzCameraImage = document.getElementById(this.ptzConnectionId)
        if ($ptzCameraImage != null) {
          let scale = window.devicePixelRatio
          if (this.rect != null) {
            this.rect.set({'top': (($ptzCameraImage.clientHeight / 2) - 96) / scale , 'left': (($ptzCameraImage.clientWidth / 2) - 128) / scale})
              if (this.canvas.contextContainer != null) {
                this.canvas.renderAll();
              }
          }
        }
      }
    },
    adjustSizeAndPosition() {
      this.adjustCanvasSize()
      this.adjustReticlePosition()
      this.adjustPtzCaptionPosition()
      this.adjustRectPosition()
    },
    drawRect() {
      if (!this.hasRect) {  // fabric.jsで矩形を描画する
        if (this.ptzConnectionId != null) {
          let $ptzCameraImage = document.getElementById(this.ptzConnectionId)
          if ($ptzCameraImage != null) {
            let scale = window.devicePixelRatio
            this.left = ($ptzCameraImage.clientWidth - 256) / 2 / scale,
            this.top = ($ptzCameraImage.clientHeight - 192) / 2 / scale,
            this.width = 256 / scale
            this.height = 192 / scale

            this.rect = new fabric.Rect({
              left: this.left,
              top: this.top,
              width: this.width,
              height: this.height,
              stroke: 'blue',
              strokeWidth: 4,
              angle: 0,
              fill: 'transparent',
              lockRotation: true
            })
            this.canvas.add(this.rect)
            this.hasRect = true
            this.$emit('display-rect', this.hasRect)
            this.getRectProperty()
          }
        }   
      }
    },
    drawProgressCaption() {
      if (this.ptzConnectionId != null) {
        let $ptzCameraImage = document.getElementById(this.ptzConnectionId)
        if ($ptzCameraImage != null) {
          let scale = window.devicePixelRatio
          this.progressCaption = new fabric.IText("調整中", {
            left: ($ptzCameraImage.clientWidth - 240 * scale) / 2 / scale,
            top: ($ptzCameraImage.clientHeight - 90 * scale) / 2 / scale,
            fill: '#FFFFFF',
            fontFamily: "Gothic",
            fontSize: 80,
            selectable: false,
            hasControls: false,
            evented: false
          })
          this.canvas.add(this.progressCaption)

          setTimeout(function() {
              this.canvas.remove(this.progressCaption)
          }.bind(this), 3000)
        }
      }
    },
    getRectProperty() {
      let scale = window.devicePixelRatio
      this.left = this.rect.left * scale
      this.top = this.rect.top * scale
      this.width = this.rect.width * scale * this.rect.scaleX
      this.height = this.rect.height * scale * this.rect.scaleY

      if (this.ptzConnectionId != null) {
        const $ptzCameraImage = document.getElementById(this.ptzConnectionId)
        if ($ptzCameraImage != null) {
          if (this.left < 0 || this.left  > $ptzCameraImage.clientWidth 
            || this.top < 0 || this.top > $ptzCameraImage.clientHeight) {
            this.cancelRect()
          }
        }
      }
    },
    clearAllCanvas() {
      if (this.canvas != null && this.canvas.contextContainer != null) {
        this.canvas.clear()
        this.canvas.dispose()
      }
      this.hasRect = false
      this.$emit('display-rect', this.hasRect)
    },
    onMousedown(event) {
      if (event.button === 0)  { // 左クリック
        if (!this.hasRect) {
          this.$emit('clickAndCentering', { clickEvent: event, connectionId: this.ptzConnectionId })
        }
      }
      event.preventDefault()
    },
    onTouchEnd(event) {
      if (!this.hasRect) {
        this.$emit('touchAndCentering', { touchEvent: event, connectionId: this.ptzConnectionId })
      }
      event.preventDefault()
    },
    centeringZoomWithRect() {
      this.$emit('centeringZoomWithRect', {left: this.left, top: this.top, width: this.width, height: this.height, connectionId: this.ptzConnectionId, callback: this.drawProgressCaption})
      this.cancelRect()
    },
    cancelRect() {
      this.canvas.remove(this.rect)
      this.hasRect = false
      this.$emit('display-rect', this.hasRect)
    },
    preventDefaultContextMenu(e) {
      e.preventDefault()
    },
    renderReticleAndCaption() {
      this.drawReticle()
      this.drawPtzCaption()
    },
    destroyReticleAndCaption() {
      if (this.canvas != null) {
        this.canvas.remove(this.verticalLine)
        this.canvas.remove(this.horizontalLine)
        this.canvas.remove(this.ptzCaption)
      }
    }
  }
}
</script>

<style scoped>
.frame {
  height: 250px;
  width: 280px;
  text-align: center;
  border: 8px solid red;
}
.display-rect-button {
    position: absolute;
    top: -20%;
    margin-left: 50px;
    width: 175px;
    height: 64px !important;
    padding: 0 16px;
    background: #009999 0% 0% no-repeat padding-box;
    opacity: 1;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
}
.rect-ctrl-button {
    width: 175px;
    height: 64px !important;
    padding: 0 16px;
    background: #009999 0% 0% no-repeat padding-box;
    opacity: 1;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
}
</style>