/**
 * @file convertion table for vue-i18n.
 * @example
 * // English
 * en :{
 *   // 全コンポーネントに共通のメッセージ
 *   message: {
 *     CommonMsg01: "common message 01",
 *   },
 *   // コンポーネントAでのみ使用するメッセージ
 *   ComponentA: {
 *      UniqueMsg01: "unique message to Component A"
 *   },
 * // Japanese
 * ja :{
 *   message: {
 *     CommonMsg01: "共通メッセージ01",
 *   },
 *   ComponentA: {
 *      UniqueMsg01: "コンポーネントAのみで使用するメッセージ"
 *   }
 * }
 * 
 * @todo this file should be made from the CSV file like below.
 * @example
 * # supported locales. 
 * "locales", "en", "ja", "fr"
 * # "message"(common) or component name, message ID(IDString), the corresponding strings in English, Japanese and French.  
 * "message", "common_msg01", "common message 01", "共通メッセージ01", "message commun 01",
 * "CompnentA", "unique_msg01", "unique message to Component A", "コンポーネントAのみで使用するメッセージ", "message unique au composant A",
 */

const TranslationData = 
{
    en: {
        message: {
            ricoh_co_ltd: "Ricoh Company, Ltd.",
            "Theta camera is not connected.": "Theta camera is not connected."
        },
        RouteMap: {
            ctxmenu_reset_view: "Reset view",
            ctxmenu_clear_route: "Clear all",
            ctxmenu_remove_waypoint: "Remove",
        },
        ErrorWarnings: {
            " Localization warning": "Localization warning",
            " GPS no fix timeout warning": "GPS no fix timeout warning",
            " Goal timeout warning": "Goal timeout warning",
            " Wheel slip warning": "Wheel slip warning",
            " Obstacle stop timeout warning": "Obstacle stop timeout warning",
            " LiDAR data: missing": "LiDAR data: missing"
        }
    },
    ja: {
        message: {
            ricoh_co_ltd: "株式会社リコー",
            "Theta camera is not connected.": "Thetaが繋がっていません。"
        },
        RouteMap: {
            ctxmenu_reset_view: "デフォルト表示に戻す",
            ctxmenu_clear_route: "全ポイントを削除する",
            ctxmenu_remove_waypoint: "削除",
        },
        ErrorWarnings: {
            " Localization warning": "GPSが取得できません。\n GPSが取得できるところまで移動して、再開してください",
            " GPS no fix timeout warning": "GPSが取得できません。\n GPSが取得できるところまで移動して、再開してください",
            " Goal timeout warning": "走行路もしくは走行体に異常状態が続いています。\n 安全に走行できることを確認し、再開してください",
            " Wheel slip warning": "車輪がスリップして、走行できません。\n 安全に走行できる場所に移動し、再開してください",
            " Obstacle stop timeout warning": "障害物があり、走行できません。\n 障害物を取り除き、再開してください",
            " LiDAR data: missing": "障害物検知センサが不調のため一時停止"
        }
    }
};

export {TranslationData};