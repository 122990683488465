<template>
    <div id="home">
        <v-container fluid class="home">
            <v-row>
                <v-col cols="5" sm="5" md="6" lg="7" xl="8">
                    <home-map ref="home_map" :initCenter="center"></home-map>
                </v-col>

                <v-col cols="7" sm="7" md="6" lg="5" xl="4">
                    <v-card elevation="0">
                        <v-toolbar flat class="home__category">稼働中</v-toolbar>
                        <v-list class="overflow-y-auto" :height="activeRobotTab">
                            <template v-for="(item, index) in activeRobots">
                                <v-list-item :key="item.robotStatus.serialNo">
                                    <v-card-text class="home__item">
                                        <v-layout>
                                            <filled-number-icon class="mr-5" :no="item.no" :status="item.robotStatus.status"></filled-number-icon>
                                            <v-layout>
                                                <p>{{item.robotStatus.robotName!=null ? item.robotStatus.robotName : item.robotStatus.serialNo}}</p>
                                                <v-btn class="home__button ml-3" color="#FFFFFF" :disabled="!item.isShutdownEnabled" @click="shutdownConfirmDialog(item)">
                                                  <v-icon>mdi-power</v-icon>
                                                </v-btn>
                                                <battery-icon class="home__item--right" :battery-level="item.robotStatus.batteryLevel_pct"></battery-icon>
                                            </v-layout>
                                        </v-layout>
                                        <v-layout>
                                            <v-btn class="home__button mt-3" color="#FFFFFF" v-if="item.hasWarning || item.hasError" @click="showRecoverableErrorDialog(item)">
                                                <v-icon>mdi-alert</v-icon>
                                            </v-btn>
                                            <v-card-text :class="[
                                                item.hasError ? 'home__item--thick-text-red' :
                                                item.hasWarning ? 'home__item--thick-text-yellow' :
                                                'home__item--thick-text-black'
                                                ]">
                                                {{item.displayStatus}}
                                            </v-card-text>
                                            <v-icon v-if="item.robotStatus.isDockedToStation">mdi-home</v-icon>
                                        </v-layout>
                                        <v-layout justify-end class="mb-3">
                                            <v-btn rounded dark class="home__button--filled" color="#0099FF" v-show="item.robotStatus.status!=='error'&&item.robotStatus.application.appName==='check.plant'" @click="checkRunningCrawler(item)">確認</v-btn>
                                            <v-btn rounded dark class="home__button--filled" color="#FF0000" v-show="item.robotStatus.status==='error'&&item.robotStatus.application.appName==='check.plant'" @click="checkRunningCrawler(item)">確認</v-btn>
                                            <v-btn rounded dark class="home__button--filled" color="#0099FF" v-show="item.robotStatus.status!=='error'&&item.robotStatus.application.appName==='create.route'" @click="checkCreateRouteCrawler(item)">ルート設定</v-btn>
                                        </v-layout>
                                        <v-divider :key="index"></v-divider>
                                    </v-card-text>
                                </v-list-item>
                            </template>
                        </v-list>

                        <v-toolbar flat class="home__category">ステーション</v-toolbar>
                        <v-list class="overflow-y-auto" :height="chargingRobotTab">
                            <template v-for="(item, index) in chargingRobots">
                                <v-list-item :key="item.robotStatus.serialNo">
                                    <v-card-text class="home__item">
                                        <v-layout>
                                            <outlined-number-icon class="mr-5" :no="item.no" :status="item.robotStatus.status"></outlined-number-icon>
                                            <v-layout>
                                                <p>{{item.robotStatus.robotName != undefined ? item.robotStatus.robotName : item.robotStatus.serialNo}}</p>
                                                <v-btn class="home__button ml-3" color="#FFFFFF" :disabled="!item.isShutdownEnabled" @click="shutdownConfirmDialog(item)">
                                                  <v-icon>mdi-power</v-icon>
                                                </v-btn>
                                                <battery-icon class="home__item--right" :battery-level="item.robotStatus.batteryLevel_pct"></battery-icon>
                                            </v-layout>
                                        </v-layout>
                                        <v-layout>
                                            <v-btn class="home__button mt-3" color="#FFFFFF" v-if="item.hasWarning || item.hasError" @click="showRecoverableErrorDialog(item)">
                                                <v-icon>mdi-alert</v-icon>
                                            </v-btn>
                                            <v-card-text :class="[
                                                item.hasError ? 'home__item--thick-text-red' :
                                                item.hasWarning ? 'home__item--thick-text-yellow' :
                                                'home__item--thick-text-black'
                                                ]">
                                                {{item.displayStatus}}
                                            </v-card-text>
                                            <v-icon v-if="item.robotStatus.isDockedToStation">mdi-home</v-icon>
                                        </v-layout>
                                        <v-layout justify-end class="mb-3">
                                            <v-btn rounded outlined :dark="item.isRunApplicationEnabled" class="home__button--outlined mr-5" color="#0099FF" :disabled="!item.isRunApplicationEnabled" @click="confirmNeedToWarnBeforeRouteCreate(item)">ルート設定</v-btn>
                                            <v-btn rounded :dark="item.isRunApplicationEnabled" class="home__button--filled" color="#0099FF" :disabled="!item.isRunApplicationEnabled" @click="confirmNeedToWarnBeforeCheckStart(item)">点検開始</v-btn>
                                        </v-layout>
                                        <v-divider :key="index"></v-divider>
                                    </v-card-text>
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <progress-dialog class="home__dialog--front" :progress-visible="isShowProgressDialog" :progress-message="progressMessage" :info-visible="isShowInfoDialog" :info-type="infoType" :info-message="infoMessage" @closeDialog="closeProgressDialog"></progress-dialog>
        <warning-before-start-dialog class="home__dialog--front" :warning-before-start-dialog-visible="isNeedToWarnBeforeStart" :selected-crawler="crawlerToUse" :from="from" :is-back-cover-opened="crawlerToUse != null && crawlerToUse.robotStatus != null ? crawlerToUse.robotStatus.isBackCoverOpened : false" @confirm="readyToStartCrawler" @cancel="closeWarningDialog" ></warning-before-start-dialog>
        <check-start-confirm-dialog class="home__dialog--front" :check-start-confirm-dialog-visible="isShowDialog" :route-id="''" :route-name="''" :crawler-id="selectedCrawlerSerialNo" :crawler-name="selectedCrawlerName" :available-crawler-list="managedRobotList" :route-list="routeList" :isFromRouteList=false :isFromHome=true @startCheckExecution="executeCheck" @reloadRouteList="reloadRouteList"></check-start-confirm-dialog>
        <shutdown-confirm-dialog class="home__dialog--front" :shutdown-confirm-dialog-visible="isShowShutdownDialog" :crawler-id="selectedCrawlerSerialNo" :crawler-name="selectedCrawlerName" @confirmShutdownCheckFinish="shutdownCrawler" @cancelCheckFinish="cancelShutdownCrawler"></shutdown-confirm-dialog>

        <recoverable-error-dialog class="home__dialog--front" 
            :recoverable-error-dialog-visible="isShowRecoverableErrorDialog" 
            :fatal-errors="selectedCrawlerFatalErrors"
            :recoverable-errors="selectedCrawlerErrors"
            :warnings="selectedCrawlerWarnings"
            :close-button-visible="true"
            @resetError="resetError"
            @close="closeRecoverableErrorDialog">
        </recoverable-error-dialog>

    </div>
</template>

<script>

import { mapGetters } from 'vuex';
import BatteryIcon from './BatteryIcon.vue'
import FilledNumberIcon from './FilledNumberIcon.vue'
import OutlinedNumberIcon from './OutlinedNumberIcon.vue'
import Robots from '../class/Robots'
import RobotAliveMonitoringCommand from '../util/RobotAliveMonitoringCommand'
import Routes from '../class/Routes'
import HomeMap from '@ricoh-srg-system/homemap-component'
import CheckStartConfirmDialog from './CheckStartConfirmDialog.vue'
import ShutdownConfirmDialog from './ShutdownConfirmDialog.vue'
import MqttCommand from '../util/MqttCommand'
import WarningBeforeStartDialog from './WarningBeforeStartDialog.vue'
import ProgressDialog from '../components/ProgressDialog.vue'
import RecoverableErrorDialog from '../components/RecoverableErrorDialog.vue'
import { BeepSound } from '../class/BeepSound';
import { Robot } from '../class/Robot'

export default {
    components: { 
        FilledNumberIcon, 
        BatteryIcon, 
        OutlinedNumberIcon,
        HomeMap,
        CheckStartConfirmDialog,
        ShutdownConfirmDialog,
        WarningBeforeStartDialog,
        ProgressDialog,
        RecoverableErrorDialog,
    },
    data() {
        return {
            managedRobotList: [],
            isShowDialog: false,
            isShowShutdownDialog: false,
            isShowRecoverableErrorDialog: false,
            crawlerToUse: null,
            from: '',
            isNeedToWarnBeforeStart: false,
            isBackCoverOpened: false,
            routeList: [],
            homeContainer: '450px',
            activeRobotTab: '400px',
            chargingRobotTab: '400px',
            center: [35.457976, 139.388327],
            editedIndex: -1,
            robotObjList: null,
            isShowProgressDialog: false,
            progressMessage: '',
            isShowInfoDialog: false,
            infoType: 'normal',
            infoMessage: '',
        }
    },
    computed: {
        ...mapGetters(['playWarningSound']),
        ...mapGetters(['playErrorSound']),
        activeRobots() {
            return this.managedRobotList.filter(item => item.robotStatus.application.appName != null && (item.robotStatus.application.appName === 'create.route' || item.robotStatus.application.appName === 'check.plant'))
        },
        chargingRobots() {
            return this.managedRobotList.filter(item => item.robotStatus.application.appName == null || !(item.robotStatus.application.appName === 'create.route' || item.robotStatus.application.appName === 'check.plant'))
        },
        selectedCrawlerStatus() {
            if (this.crawlerToUse != null) {
                return this.crawlerToUse.robotStatus
            }
            else {
                return null
            }
        },
        selectedCrawlerSerialNo() {
            if (this.selectedCrawlerStatus != null) {
                return this.selectedCrawlerStatus.serialNo
            }           
            else {
                return ''
            }
        },
        selectedCrawlerName() {
            if (this.selectedCrawlerStatus != null) {
                return this.selectedCrawlerStatus.robotName
            }           
            else {
                return ''
            }
        },
        selectedCrawlerFatalErrors() {
            if (this.selectedCrawlerStatus != null) {
                return this.selectedCrawlerStatus.fatalErrors
            }
            else {
                return []
            }
        },
        selectedCrawlerErrors() {
            if (this.selectedCrawlerStatus != null) {
                return this.selectedCrawlerStatus.errors
            }
            else {
                return []
            }
        },
        selectedCrawlerWarnings() {
            if (this.selectedCrawlerStatus != null) {
                return this.selectedCrawlerStatus.warnings
            }
            else {
                return []
            }
        },
        hasErrorOrWarnings() {
            if (this.selectedCrawlerFatalErrors.length == 0 && this.selectedCrawlerErrors.length == 0 && this.selectedCrawlerWarnings.length == 0) {
                return false
            }
            else {
                return true
            }
        }
    },
    beforeDestroy() {
        RobotAliveMonitoringCommand.deleteRobotMonitoringCallback(this.updateRobot)
        window.removeEventListener('resize', this.fitHomeWindowSize, false)
    },
    mounted() {
        window.addEventListener('resize', this.fitHomeWindowSize, false)
        this.fitHomeWindowSize()
    },
    methods: {
        fitHomeWindowSize() {
            this.homeContainer = document.getElementById('tabs')
            this.homeContainer.style.height = `${window.innerHeight - document.getElementsByTagName('header')[0].clientHeight}px`
            
            this.activeRobotTab = this.homeContainer.clientHeight * 0.39
            this.chargingRobotTab = this.homeContainer.clientHeight * 0.39
        },
        async initialize() {
            this.managedRobotList = await Robots.getManagedRobotList()
            this.initMap()
            RobotAliveMonitoringCommand.setRobotMonitoringCallback(this.updateRobot)
        },
        async initMap() {
            // 地図の中心を決定する(Webアプリを起動しているPCの位置とする)
            //this.center = await this.$refs.home_map.getClientLocation();

            // 充電ステーションの位置を設定する
            // 現時点はダミーだが後々充電ステーションの座標位置を取得して表示するように変更する
            this.$refs.home_map.addChargingStation(
                "chargingStation1",
                this.center[0],
                this.center[1]
            )

            // 地図に表示するロボットを追加し、ロボット情報を更新する際にアクセスするロボットオブジェクトのリストを作成する
            this.robotObjList = new Map()
            for (let robot of this.managedRobotList) {
                let robotObj = await this.$refs.home_map.addNewRobot(
                    robot.robotStatus.serialNo,
                    robot.robotStatus.location['lat.deg'],
                    robot.robotStatus.location['lon.deg'],
                    robot.robotStatus.location['azimuth.deg'],
                    this.$refs.home_map.StateCode.Inactive
                )
                this.robotObjList.set(robot.robotStatus.serialNo, robotObj)
                console.log(`new robot as ${robotObj.getIconText()}.`)
            }

            // 地図のサイズと場所を全ロボットが表示されるように調整する
            this.$refs.home_map.fitMap()
        },
        updateRobot(robot) {
            let robotObj = this.robotObjList.get(robot.robotStatus.serialNo)
            this.$refs.home_map.moveRobotTo(robotObj, robot.robotStatus.location['lat.deg'], robot.robotStatus.location['lon.deg'], robot.robotStatus.location['azimuth.deg'])

            let hasWarnings = false
            for (let warning of robot.robotStatus.warnings) {
                hasWarnings = true
            }

            let newState = 0
            switch (robot.robotStatus.status) {
                case 'waiting':
                case 'disabled':
                    newState = 0
                    break
                case 'running':
                    newState = 1
                    if (hasWarnings && this.playWarningSound) {
                        let beep = new BeepSound('square', 800, 0.3, 0.5);
                        beep.play();
                    }
                    break
                case 'stop':
                    newState = -1
                    if (
                        robot.robotStatus.application.appName != null && 
                        robot.robotStatus.application.drivingMode != null && 
                        this.playErrorSound
                    ) {
                        let beep = new BeepSound('square', 800, 0.3, 0.5);
                        beep.play();
                    }
                    break;
                case 'error':
                    newState = -1
                    break
                case 'shutdown':
                    newState = -1
                    break
                case 'reboot':
                    newState = -1
                    break
                case 'warning':
                case 'unknown':
                    if (robot.previousStatus === 'running') {
                        newState = 1
                    }
                    else {
                        newState = 0
                    }
            }
            this.$refs.home_map.setRobotState(robotObj, newState)

        },
        confirmNeedToWarnBeforeRouteCreate(item) {
            this.crawlerToUse = item
            this.from = 'create.route'
            if (this.crawlerToUse.robotStatus.isBackCoverOpened) {
                this.isBackCoverOpened = this.crawlerToUse.robotStatus.isBackCoverOpened
                this.isNeedToWarnBeforeStart = true
            }
            else {
                this.setAutonomousDrivingRoute(this.crawlerToUse)
            }
        },
        confirmNeedToWarnBeforeCheckStart(item) {
            this.crawlerToUse = item
            this.from = 'check.start'
            if (this.crawlerToUse.robotStatus.isBackCoverOpened) {
                this.isBackCoverOpened = this.crawlerToUse.robotStatus.isBackCoverOpened
                this.isNeedToWarnBeforeStart = true
            }
            else {
                this.setCheckRoute(this.crawlerToUse)        
            }
        },
        shutdownConfirmDialog(item) {
            this.crawlerToUse = item
            this.isShowShutdownDialog = true
        },
        async shutdownCrawler(obj) {
            this.isShowShutdownDialog = false
            let recvTopic = `${sessionStorage.getItem('userPoolId')}/${this.selectedCrawlerSerialNo}/robot_system/shutdown/reply`
            MqttCommand.subscribe(recvTopic)
            let topicRegExp = /ap-northeast-1_([0-9a-zA-Z]){9}\/([0-9a-z]){12,}\/robot_system\/shutdown\/reply/
            let type = 'cmd'
            let name = 'reboot'
            let payload = {
                "reboot": obj.reboot,
                "delay.time": obj.delayTime
            }
            let packet = await MqttCommand.createPacket(type, name, payload)
            let packetId = packet['packet.id']
            let prefixMessage = ""
            if (obj.reboot) {
                prefixMessage = "再起動"
            }
            else {
                prefixMessage = "シャットダウン"
            }
            let timeoutId = ''
            let self = this            
            MqttCommand.setSubscribeTopicHandler(topicRegExp, packetId, function(topic, data) {
                if (data['replied.to'] === this) {
                    if (data.payload['has.succeeded']) {
                        self.isShowDialog = false
                        self.isShowProgressDialog = false
                        self.progressMessage = ''
                        self.isShowInfoDialog = false
                    }
                    else {
                        self.infoType = "error"
                        self.infoMessage = prefixMessage + "に失敗しました。"
                        self.isShowInfoDialog = true
                    }
                    MqttCommand.removeSubscribeTopicHandler(topicRegExp, packetId)
                    MqttCommand.unsubscribe(recvTopic)
                    clearTimeout(timeoutId)
                }
            }.bind(packetId))
            .then(()=>{
                let sendTopic = `${sessionStorage.getItem('userPoolId')}/${this.selectedCrawlerSerialNo}/robot_system/shutdown`
                MqttCommand.sendPacket(sendTopic, packet)
                this.progressMessage = prefixMessage + "を開始しています。"
                this.isShowProgressDialog = true

                timeoutId = setTimeout(function() {
                    MqttCommand.removeSubscribeTopicHandler(topicRegExp, name)
                    MqttCommand.unsubscribe(recvTopic)
                    this.infoType = "error"
                    this.infoMessage = prefixMessage + "開始に失敗しました。\nネットワークを確認してください。"
                    this.isShowInfoDialog = true
                    clearTimeout(timeoutId)
                }.bind(this), 60000)  // 60(s)
            })
        },
        cancelShutdownCrawler() {
            this.isShowShutdownDialog = false
            this.crawlerToUse = null
        },
        showRecoverableErrorDialog(item) {
            this.crawlerToUse = item
            this.isShowRecoverableErrorDialog = true
        },
        async resetError() {
            let recvTopic = `${sessionStorage.getItem('userPoolId')}/${this.selectedCrawlerSerialNo}/robot_system/crawler-error-reset/reply`
            MqttCommand.subscribe(recvTopic)
            let topicRegExp = /ap-northeast-1_([0-9a-zA-Z]){9}\/([0-9a-z]){12,}\/robot_system\/crawler-error-reset\/reply/

            let type = 'cmd'
            let name = 'error.reset'
            let payload = {}

            let packet = await MqttCommand.createPacket(type, name, payload)
            let packetId = packet['packet.id']
            let timeoutId = ''
            let self = this            
            MqttCommand.setSubscribeTopicHandler(topicRegExp, packetId, function(topic, data) {
                if (data['replied.to'] === this) {
                    if (data.payload['has.succeeded']) {
                        self.isShowDialog = false
                        self.isShowProgressDialog = false
                        self.progressMessage = ''
                        self.isShowInfoDialog = false
                        self.crawlerToUse = null
                        self.isShowRecoverableErrorDialog = false
                    }
                    else {
                        self.infoType = "error"
                        self.infoMessage = "エラー解除に失敗しました。"
                        self.isShowInfoDialog = true
                    }
                    MqttCommand.removeSubscribeTopicHandler(topicRegExp, packetId)
                    MqttCommand.unsubscribe(recvTopic)
                    clearTimeout(timeoutId)
                }
            }.bind(packetId))
            .then(()=>{
                let sendTopic = `${sessionStorage.getItem('userPoolId')}/${this.selectedCrawlerSerialNo}/robot_system/crawler-error-reset`
                MqttCommand.sendPacket(sendTopic, packet)
                this.progressMessage = "エラー解除中です。"
                this.isShowProgressDialog = true

                timeoutId = setTimeout(function() {
                    MqttCommand.removeSubscribeTopicHandler(topicRegExp, name)
                    MqttCommand.unsubscribe(recvTopic)
                    this.infoType = "error"
                    this.infoMessage = "エラー解除に失敗しました。"
                    this.isShowInfoDialog = true
                    clearTimeout(timeoutId)
                }.bind(this), 10000)  // 10(s)
            })
        },
        closeRecoverableErrorDialog() {
            this.isShowRecoverableErrorDialog = false
            this.crawlerToUse = null
        },
        readyToStartCrawler(obj) {
            if (obj.from === 'create.route') {
                this.setAutonomousDrivingRoute(obj.crawler)
            }
            else if (obj.from === 'check.start') {
                this.setCheckRoute(obj.crawler)            
            }
            else {
                // nothing
            }
            this.isNeedToWarnBeforeStart = false
        },
        closeWarningDialog() {
            this.crawlerToUse = null
            this.isBackCoverOpened = false
            this.isNeedToWarnBeforeStart = false
        },
        async executeCheck(obj) {
            if(obj.serialNo === null && obj.routeId !== null) {
                let recvTopic = `${sessionStorage.getItem('userPoolId')}/${this.selectedCrawlerSerialNo}/usr_program/exec-ctrl/reply`
                MqttCommand.subscribe(recvTopic)
                let topicRegExp = /ap-northeast-1_([0-9a-zA-Z]){9}\/([0-9a-z]){12,}\/usr_program\/exec-ctrl\/reply/
                let type = 'cmd'
                let name = 'run'
                let payload = {
                    param: {
                        "route": `plant_check/routes/${obj.routeId}`,
                        "repeat.count": obj.isExecuteContinuousCheck ? -1 : null
                    },
                    program: 'check.plant',
                    experimental: {
                        'does.start.from.charging.station': obj.isStartingFromChargingStation,
                        'does.return.to.charging.station': obj.isReturningToChargingStation,
                        "does.reset.ptz.origin.position": obj.isExecuteResetPtzOriginPosition
                    }
                }
                let packet = await MqttCommand.createPacket(type, name, payload)
                let packetId = packet['packet.id']
                // let timeoutId = ''
                let self = this
                MqttCommand.setSubscribeTopicHandler(topicRegExp, packetId, function(topic, data) {
                    if (data['replied.to'] === this) {
                        if (data.payload['has.succeeded']) {
                            self.isShowDialog = false
                            self.isShowProgressDialog = false
                            self.progressMessage = ''
                            self.isShowInfoDialog = false
                            self.infoType = 'error'
                            self.infoMessage = ''
                            self.$router.push({name: 'CrawlerDriving',  params: { 'serial_no':  self.selectedCrawlerSerialNo, 'route_id': obj.routeId, 'operating_mode': 'check.plant' }})
                        }
                        else {
                            self.infoType = "error"
                            self.infoMessage = "点検開始に失敗しました。"
                            self.isShowInfoDialog = true
                        }
                        MqttCommand.removeSubscribeTopicHandler(topicRegExp, packetId)
                        MqttCommand.unsubscribe(recvTopic)
                        // clearTimeout(timeoutId)
                    }
                }.bind(packetId))
                .then(()=>{
                    let sendTopic = `${sessionStorage.getItem('userPoolId')}/${this.selectedCrawlerSerialNo}/usr_program/exec-ctrl`
                    MqttCommand.sendPacket(sendTopic, packet)
                    this.progressMessage = '点検を開始しています。'
                    this.isShowProgressDialog = true

                    // timeoutId = setTimeout(function() {
                        // MqttCommand.removeSubscribeTopicHandler(topicRegExp, name)
                        // MqttCommand.unsubscribe(recvTopic)
                        // this.infoType = "error"
                        // this.infoMessage = "点検開始に失敗しました。\nネットワークを確認してください。"
                        // this.isShowInfoDialog = true
                        // clearTimeout(timeoutId)
                    // }.bind(this), 10000)
                })
            }
            else if (obj.serialNo === null && obj.routeId === null) {
                this.crawlerToUse = null
                this.isShowDialog = false
            }
            else {
                // nothing
            }
        },
        checkRunningCrawler(item) {
            this.$router.push({name: 'CrawlerDriving',  params: { 'serial_no':  item.robotStatus.serialNo, 'route_id': item.robotStatus.application.route[1], 'operating_mode': 'check.plant' }})
        },
        checkCreateRouteCrawler(item) {
            this.$router.push({name: 'CrawlerDriving', params: { 'serial_no': item.robotStatus.serialNo, 'operating_mode': 'create.route' }})
        },
        async setCheckRoute(item) {
            this.routeList = await Routes.getRouteList()
            this.crawlerToUse = item
            this.isShowDialog = true
        },
        async setAutonomousDrivingRoute(item) {
            let recvTopic = `${sessionStorage.getItem('userPoolId')}/${item.robotStatus.serialNo}/usr_program/exec-ctrl/reply`
            MqttCommand.subscribe(recvTopic)
            let topicRegExp = /ap-northeast-1_([0-9a-zA-Z]){9}\/([0-9a-z]){12,}\/usr_program\/exec-ctrl\/reply/
            let type = 'cmd'
            let name = 'run'
            let payload = {
                program: 'create.route'
            }
            let packet = await MqttCommand.createPacket(type, name, payload)
            let packetId = packet['packet.id']
            // let timeoutId = ''
            let self = this
            MqttCommand.setSubscribeTopicHandler(topicRegExp, packetId, function(topic, data) {
                if (data['replied.to'] === this) {
                    if (data.payload['has.succeeded']) {
                        self.isShowProgressDialog = false
                        self.progressMessage = ''
                        self.isShowInfoDialog = false
                        self.infoType = 'error'
                        self.infoMessage = ''
                        self.$router.push({name: 'CrawlerDriving', params: { 'serial_no': item.robotStatus.serialNo, 'operating_mode': 'create.route' }})
                    }
                    else {
                        self.infoType = "error"
                        self.infoMessage = "ルート設定の開始に失敗しました。"
                        self.isShowInfoDialog = true
                    }
                    MqttCommand.removeSubscribeTopicHandler(topicRegExp, packetId)
                    MqttCommand.unsubscribe(recvTopic)
                    // clearTimeout(timeoutId)
                }
            }.bind(packetId))
            .then(()=>{
                let sendTopic = `${sessionStorage.getItem('userPoolId')}/${item.robotStatus.serialNo}/usr_program/exec-ctrl`
                MqttCommand.sendPacket(sendTopic, packet)
                this.progressMessage = 'ルート設定を開始しています。'
                this.isShowProgressDialog = true

                // timeoutId = setTimeout(function() {
                //     MqttCommand.removeSubscribeTopicHandler(topicRegExp, name)
                //     MqttCommand.unsubscribe(recvTopic)
                //     clearTimeout(timeoutId)
                //     this.infoType = "error"
                //     this.infoMessage = "ルート設定を開始できませんでした。\nネットワークを確認してください。"
                //     this.isShowInfoDialog = true
                // }.bind(this), 10000)
            })
        },
        closeProgressDialog() {
            this.isShowProgressDialog = false
            this.progressMessage = ''
            this.isShowInfoDialog = false
            this.infoType = 'error'
            this.infoMessage = ''
        },
        async reloadRouteList(){
            Routes.lastUpdate = new Date(0).getTime()
            this.routeList = await Routes.getRouteList()
            console.log("reload")
            this.isShowDialog = false;
            this.$nextTick(() => (this.isShowDialog = true));
        }
    }
}
</script>

<style scoped>
.home {
    min-height: 450px;
}
.home__button--outlined {
    width: 164px;
    height: 54px !important;
    box-shadow: 0px 3px 6px #00000029;
    border: 2px solid;
    border-radius: 26px;
    opacity: 1;
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI !important;
    letter-spacing: 0px;
    opacity: 1;
}
.home__button--filled {
    width: 164px;
    height: 54px !important;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 26px;
    opacity: 1;
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI !important;
    letter-spacing: 0px;
    opacity: 1;
}
.home__category {
    height: 59px !important;
    background: #F1F1F1 0% 0% no-repeat padding-box !important;
    opacity: 1;
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
.home__dialog--front {
    z-index: 1000;
}
.home__item {
    text-align: left;
    font: normal normal normal 24px/32px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
.home__item--thick-text-black {
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
.home__item--thick-text-red {
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
    color: #FF0000;
    opacity: 1;
}
.home__item--thick-text-yellow {
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
    color: #F9A825;
    opacity: 1;
}
.home__item--right {
    margin: 0 0 0 auto;
}
</style>