<template>
  <div>
    <div class="move-reg-button-group__outer-circle">
      <div class="move-reg-button-group__outer-circle--top"> 
        <v-icon x-large class="move-reg-button-group__move-button--tilt-plus" @mousedown.native="_moveUp" @mouseup.native="_stop" @mouseleave.native="_stop" @touchstart.native="_moveUp" @touchend.native="_stop">$vuetify.icons.straight</v-icon>
      </div>
      <div class="move-reg-button-group__outer-circle--middle">
        <v-btn text color="white" class="move-reg-button-group__move-button--pan-minus" @click="_moveLeft" >L90</v-btn>
        <div class="move-reg-button-group__inner-circle">
          <v-icon x-large outlined class="move-reg-button-group__reg-button" v-show="operatingMode==='create.route'&&cameraMode==='ptz'" @click="registerVisual">$vuetify.icons.addPhoto</v-icon>
        </div>
        <v-btn text color="white" class="move-reg-button-group__move-button--pan-plus" @click="_moveRight" >R90</v-btn>
      </div>
      <div class="move-reg-button-group__outer-circle--bottom">
        <v-icon x-large class="move-reg-button-group__move-button--tilt-minus" @mousedown.native="_moveDown" @mouseup.native="_stop" @mouseleave.native="_stop" @touchstart.native="_moveDown" @touchend.native="_stop">$vuetify.icons.back</v-icon>
      </div>
    </div>
    <v-layout>
      <v-btn rounded dark class="ptz-home-back-pos-button ptz-home-pos-button--layout" color="#009999" @click="ptzHome">正面に戻す</v-btn>
      <v-btn rounded dark class="ptz-home-back-pos-button ptz-back-pos-button--layout" color="#009999" @click="ptzBack">背面を向く</v-btn>
    </v-layout>
  </div>
</template>

<script>
export default {
  props: {
    cameraMode: { type: String },
  },
  computed: {
    operatingMode() {
      return this.$store.state.selectedMode
    }
  },
  watch: {
    cameraMode (camera) {
      this.camera = camera
    }
  },
  methods: {
    _moveLeft: function(event) {
      this.$emit('moveLeft')
      if (event.cancelable) {
        event.preventDefault()
      }
    },
    _moveRight: function(event) {
      this.$emit('moveRight')
      if (event.cancelable) {
        event.preventDefault()
      }
    },
    _moveDown: function(event) {
      this.$emit('moveDown')
      if (event.cancelable) {
        event.preventDefault()
      }
    },
    _moveUp: function(event) {
      this.$emit('moveUp')
      if (event.cancelable) {
        event.preventDefault()
      }
    },
    _stop: function(event) {
      this.$emit('stop')
      if (event.cancelable) {
        event.preventDefault()
      }
    },
    ptzHome() {
      this.$emit('ptzHome')
    },
    ptzBack() {
      this.$emit('ptzBack')
    },
    registerVisual() {
      this.$emit('registerVisual')
    }
  }
}
</script>

<style scoped>
.move-reg-button-group__inner-circle {
    position: absolute;
    width: 100px;
    height: 100px;
    left: 72px;
    top: -3px;
    background: #FFFFFF4D 0% 0% no-repeat padding-box;
    border: 5px solid #FFFFFF;
    border-radius: 50%;
    opacity: 1;
}
.move-reg-button-group__move-button--pan-minus {
    left: -2px;
    top: 30px;
    font: normal normal bold 24px/32px Segoe UI !important;
}.move-reg-button-group__move-button--pan-plus {
    left: 85px;
    top: 30px;
    font: normal normal bold 24px/32px Segoe UI !important;
}
.move-reg-button-group__move-button--tilt-minus {
    left: 102px;
    top: 18px;
}
.move-reg-button-group__move-button--tilt-plus {
    left: 102px;
    top: 13px;
}
.move-reg-button-group__outer-circle {
    position: relative;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    border: 5px solid #FFFFFF;
    background: #FFFFFF33 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
}
.move-reg-button-group__outer-circle--bottom {
    position:absolute;
    left: 0;
    top: 70%;
    width: 100%;
    height: 30%;
}
.move-reg-button-group__outer-circle--middle {
    position:absolute;
    left: 0;
    top: 30%;
    width: 100%;
    height: 40%;
}
.move-reg-button-group__outer-circle--top {
    position:absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 30%;
}
.move-reg-button-group__reg-button {
    left: 24px;
    top: 24px;
}
.move-reg-button-group__switch-mode-button {
    width: 82px; 
    height: 82px; 
    border: 3px solid #FFFFFF;
    background: #FFFFFF33 0% 0% no-repeat padding-box; 
    opacity: 1; 
}
.ptz-home-back-pos-button {
  width: 175px;
  height: 64px !important;
  padding: 0 16px;
  background: #009999 0% 0% no-repeat padding-box;
  opacity: 1;
  font: normal normal bold 24px/32px Segoe UI;
  letter-spacing: 0px;
}
.ptz-home-pos-button--layout {
  position: absolute;
  bottom: -70px;
  right: 160%;
}
.ptz-back-pos-button--layout {
  position: absolute;
  bottom: -70px;
  right: 80%;
}
</style>