<template>
    <div ref="battery20"></div>
</template>

<script>
import Battery20 from "!!raw-loader!../../assets/ricoh_assets/icons/ICN001-07_battery_20.svg" // loads SVG Icons as String via Webpack.
export default {
    mounted() {
        let shadowRoot = this.$refs.battery20.attachShadow({mode: 'open'})
        shadowRoot.innerHTML = Battery20

        const style = document.createElement('style')
        style.innerHTML = `svg { width: 100%; height: 100%; }`

        const clonedStyle = style.cloneNode(true)
        shadowRoot.appendChild(clonedStyle)
    }
}
</script>

<style>
/* .battery-20 .cls-3 {
    fill: red;
} */
</style>