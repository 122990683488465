<template>
    <div ref="battery40"></div>
</template>

<script>
import Battery40 from "!!raw-loader!../../assets/ricoh_assets/icons/ICN001-06_battery_40.svg" // loads SVG Icons as String via Webpack.
export default {
    mounted() {
        let shadowRoot = this.$refs.battery40.attachShadow({mode: 'open'})
        shadowRoot.innerHTML = Battery40

        const style = document.createElement('style')
        style.innerHTML = `svg { width: 100%; height: 100%; }`

        const clonedStyle = style.cloneNode(true)
        shadowRoot.appendChild(clonedStyle)
    }
}
</script>

<style>
</style>