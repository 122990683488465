<template>
  <div>
    <v-dialog persistent max-width="550px" :retain-focus="false" v-model="c_progressVisible">
      <template v-slot:activator="{c_progressVisible}"></template> <!-- eslint-disable-line -->
      <v-card class="dialog">
        <v-toolbar flat dark class="dialog__title" color="#009999" height="40">
          <v-icon large class="mr-2">$vuetify.icons.info</v-icon>処理中
        </v-toolbar>
        <v-card-text class="dialog__message mt-10">
          {{progressMessage}}
        </v-card-text>
        <div class="text-center">
          <v-progress-circular indeterminate color="#009999" width="7" size="80"></v-progress-circular>
        </div>
      </v-card>
    </v-dialog>
    <info-dialog :info-visible="c_infoVisible" :info-type="c_infoType" :infoMessage="c_infoMessage" @closeDialog="closeDialog"></info-dialog>
  </div>
</template>

<script>
import InfoDialog from './InfoDialog.vue'
export default {
  components: {
    InfoDialog
  },
  props: {
    progressVisible: { type: Boolean },
    progressMessage: { type: String },
    infoVisible: { type: Boolean, default: false },
    infoType: { type: String, default: 'normal' },
    infoMessage: { type: String, default: '' },
  },
  computed: {
    c_progressVisible: {
      get() {
        return this.progressVisible
      }
    },
    c_infoVisible: { 
      get() {
        return this.infoVisible
      },
    },
    c_infoType: { 
      get() {
        return this.infoType
      },  
    },
    c_infoMessage: { 
      get() {
        return this.infoMessage
      },
    },
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
    }
  }
}
</script>

<style scoped>
.dialog {
  max-width: 550px;
  min-height: 280px;
}
.dialog__message {
  text-align: left;
  font: normal normal bold 24px/32px Segoe UI;
  letter-spacing: 0px;
  color: #000000 !important;
  opacity: 1;
  white-space: pre-wrap;
}
.dialog__title {
  text-align: left;
  font: normal normal bold 24px/32px Segoe UI;
  letter-spacing: 0px;
  opacity: 1;  
}
</style>