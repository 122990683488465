<template>
  <div class="outer">
    <div id="sound-view" ref="sound_view" class="sound-view">
      <v-progress-circular indeterminate class="progress-circular--center" color="#009999" width="10" size="150" v-show="!isMaincamArrived"></v-progress-circular>
    </div>
    <reticle ref="reticle" class="reticle"></reticle>
    <crawler-controller ref="robot_ctrl" class="controller-area" v-show="isAbleToCrawlerCtrl"
      :cameraMode="cameraMode" 
      @registerSound="registerSound"
      @switchCtrlModeResponse="switchCtrlModeResponse($event)">
    </crawler-controller>
  </div>
</template>

<script>
import Reticle from '../parts/Reticle.vue'
import CrawlerController from "../parts/CrawlerController"
export default {
  props: {
    cameraMode: { type: String }
  },
  components: {
    Reticle,
    CrawlerController,
  },
  data() {
    return {
      operatingMode: this.$store.state.selectedMode,
      connectionId: null,
      isAbleToCrawlerCtrl: false,
      isMaincamArrived: false,
    }
  },
  watch: {
    cameraMode (mode) {
      if (mode === 'sound') {
        this.resizeMaincam()
      }
    }
  },
  methods: {
    addMaincam(connectionId) {
      this.isMaincamArrived = true
      this.connectionId = connectionId

      const $videoCanvas = document.createElement('canvas');
      $videoCanvas.id = 'sound-view-canvas'
      $videoCanvas.style.position = 'absolute';
      
      let $soundViewDiv = document.getElementById('sound-view')
      $soundViewDiv.appendChild($videoCanvas);
      
      const $video = document.getElementById(this.connectionId);
      $video.addEventListener("timeupdate", function(){
      $videoCanvas.getContext("2d").drawImage($video, 0, 0, parseInt($video.style.width), parseInt($video.style.height));
      }, true);

      this.resizeMaincam()
      this.$refs.reticle.addReticleCanvas()
    },
    destroyMaincam() {
      this.$refs.reticle.destroyReticleCanvas()
      let $soundViewDiv = document.getElementById('sound-view')
      let $videoCanvas = document.getElementById('sound-view-canvas')
      if ($soundViewDiv != null && $videoCanvas != null && $soundViewDiv.childElementCount > 0) {
        $soundViewDiv.removeChild($videoCanvas)
      }
      this.isMaincamArrived = false
    },
    resizeMaincam() {
      // 画面サイズに応じて表示エリアの背景の大きさを変更
      let $containerDiv = document.getElementById('camera-and-ctrl')
      let $soundViewDiv = document.getElementById('sound-view')
      let $video = document.getElementById(this.connectionId)
      let height = window.innerHeight - document.getElementsByTagName('header')[0].clientHeight
      if ($containerDiv != null && $soundViewDiv != null) {
        if (height <500) {
          $soundViewDiv.style.height = '500px'
        }
        else {
          $soundViewDiv.style.height = `${height}px`
        }

        let soundDivWidth = $containerDiv.clientWidth
        let soundDivHeight = parseFloat($soundViewDiv.style.height)

        // アスペクト比 16:9を維持したまま、配信映像表示エリア内に表示が収まるようサイズを修正する
        let $soundCanvas = document.getElementById('sound-view-canvas')
        if ($soundCanvas != null && $video != null) {
          $soundCanvas.style.marginLeft = `${(soundDivWidth - parseFloat($video.style.width)) / 2}px`
          $soundCanvas.style.marginTop = `${(soundDivHeight - parseFloat($video.style.height)) / 2}px`
          $soundCanvas.width = soundDivWidth - parseFloat($soundCanvas.style.marginLeft)
          $soundCanvas.height = soundDivHeight - parseFloat($soundCanvas.style.marginTop)
        }
        this.$refs.reticle.resizeReticleCanvas()
      }
    },
    registerSound() {
      this.$emit('registerSound', { connectionId: this.connectionId })
    },
    setRobotParameter(linearVelocity, slewingSpeed) {
      this.$refs.robot_ctrl.setDutyScale(linearVelocity)
      this.$refs.robot_ctrl.setDutyScaleTurn(slewingSpeed)
    },
    sendGetCtrlCmd() {
      this.$refs.robot_ctrl.getRobotCtrl()
    },
    switchCtrlModeResponse(event) {
      this.$emit('switchCtrlModeResponse', event)
    },
    disableCrawlerCtrl() {
      this.isAbleToCrawlerCtrl = false
      this.$refs.robot_ctrl.destroy()
    },
    enableCrawlerCtrl() {
      this.$refs.robot_ctrl.destroy()
      this.isAbleToCrawlerCtrl = true
      this.$refs.robot_ctrl.initialize()
    },
  }
}
</script>

<style scoped>
.controller-area {
  position: absolute;
  bottom: 9%;
  right: 5px;
}
.sound-view {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: #000000;
}
.outer {
  width: inherit;
  height: inherit;
}
.progress-circular--center {
  position: absolute;
  margin-top: 18%;
  margin-left: 50%;
  transform: translateX(-50%);
}
.reticle {
  position: absolute;
  top: 0px;
  left: 0px;
}
</style>