// Copyright(c) 2024 RICOH Company, Ltd. All rights reserved.

import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {  // 初期値
    loggedinUser: '',
    selectedCrawler: '',
    selectedRoute: '',
    selectedMode: '',
    playWarningSound: true,
    playErrorSound: true,
    showWarning: true,
  },
  mutations: {
    setLoggedinUser(state, payload) {
      state.loggedinUser = payload.name;
    },
    setSelectedCrawler(state, payload) {
      state.selectedCrawler = payload.selectedCrawler;
    },
    deselectedCrawler(state) {
      state.selectedRobot = '';
    },
    setSelectedRoute(state, payload) {
      state.selectedRoute = payload.selectedRoute;
    },
    deselectedRoute(state) {
      state.selectedRoute = '';
    },
    setSelectedMode(state, payload) {
      state.selectedMode = payload.selectedMode;
    },
    deselectedMode(state) {
      state.selectedMode = '';
    },
    setPlayWarningSound(state, value) {
      state.playWarningSound = value;
    },
    setPlayErrorSound(state, value) {
      state.playErrorSound = value;
    },
    setShowWarning(state, value) {
      state.showWarning = value;
    },
    destroy(state) {
      state.loggedinUser = '';
      state.selectedCrawler = '';
      state.selectedRoute = '';
      state.selectedMode = '';
      state.playWarningSound = true;
      state.playErrorSound = true;
      state.showWarning = true;
    }
  },
  actions: {
    setLoggedinUserAction({ commit }, payload) {
      commit('setLoggedinUser', payload);
    },
    setSelectedCrawlerAction({ commit }, payload) {
      commit('setSelectedCrawler', payload)
    },
    deselectedCrawlerAction({ commit }) {
      commit('deselectedCrawler')
    },
    setSelectedRouteAction({ commit }, payload) {
      commit('setSelectedRoute', payload)
    },
    deselectedRouteAction({ commit }) {
      commit('deselectedRoute')
    },
    setSelectedModeAction({ commit }, payload) {
      commit('setSelectedMode', payload)
    },
    deselectedModeAction({ commit }) {
      commit('deselectedMode')
    },
    destroyAction({ commit }) {
      commit('destroy')
    },
    togglePlayWarningSound({ commit }, value) {
      commit('setPlayWarningSound', value);
    },
    togglePlayErrorSound({ commit }, value) {
      commit('setPlayErrorSound', value);
    },
    toggleShowWarning({ commit }, value) {
      commit('setShowWarning', value);
    },
  },
  getters: {
    playWarningSound: state => state.playWarningSound,
    playErrorSound: state => state.playErrorSound,
    showWarning: state => state.showWarning,
  },
  modules: {
  },
  plugins: [
    createPersistedState({
      key: 'session',
      paths: [
        'loggedinUser',
        'selectedCrawler',
        'selectedRoute',
        'selectedMode',
        'playWarningSound',
        'playErrorSound',
        'showWarning',
      ],
      storage: window.sessionStorage
    }),
    createPersistedState({
      key: 'local',
      paths: [
      ],
      storage: window.localStorage
    })
  ]
})
