// Copyright(c) 2024 RICOH Company, Ltd. All rights reserved.

import { Application } from "./Application";

export class Robot {
    no;
    robotStatus = {
      serialNo: null,
      robotName: null,
      firmwareVersion: null,
      accessories: [],
      status: null,
      batteryLevel_pct: null,
      chargingStatus: null,
      isBackCoverOpened: false, // 充電ポート開閉の検知に対応していない機体でも動かせるようにするため、デフォルト値をfalseとする。
      elapsedTime_sec: null,
      interval_ms: null,
      location: {
        "lat.deg": 35.458026,//null,
        "lon.deg": 139.388377,//null,
        "azimuth.deg": null,
        "precision": null
      },
      errors: [],
      fatalErrors: [],
      recoverableErrors: [],
      warnings: [],
      signalCondition: null,
      speed_pct: null,
      controller: null,
      application: null,
      lastGetStatusTime: null,
    };
    previousStatus = null;
    displayStatus = '状態不明';

    STATUS_STOP_TO_MESSAGE = new Map([
      ['unknown', '状態不明'],
      ['not charging', '停止中'],
      ['discharging', '停止中'],
      ['charging', '充電中'],
      ['autocharging','自動充電中'],
      ['full', '停止中'],
      [null, '状態不明'],
      [undefined, '状態不明']
    ]);

    STATUS_WAITING_TO_MESSAGE = new Map([
      ['unknown', '状態不明'],
      ['not charging', '未使用'],
      ['discharging', '未使用'],
      ['charging', '充電中'],
      ['autocharging','自動充電中'],
      ['full', '充電完了'],
      [null, '状態不明'],
      [undefined, '状態不明']
    ]);

  STATUS_DISABLED_TO_MESSAGE = new Map([
      ['unknown', '状態不明'],
      ['not charging', '要充電-使用不可'],
      ['discharging', '要充電-使用不可'],
      ['autocharging','自動充電中'],
      ['charging', '充電中'],
      [null, '状態不明'],
      [undefined, '状態不明']
    ]);

    STATUS_DONE_TO_MESSAGE = new Map([
      ['check.plant', '点検終了'],
      ['create.route', 'ルート設定終了'],
      [null, '状態不明'],
      [undefined, '状態不明']
    ]);

  constructor(no, serialNo, robotName, firmwareVersion, accessories) {
      this.no = Number(no);
      this.robotStatus.serialNo = String(serialNo);
      this.robotStatus.robotName = robotName != null ? String(robotName) : null;
      this.robotStatus.firmwareVersion = String(firmwareVersion);
      this.robotStatus.accessories = Array(accessories);
      this.robotStatus.application = new Application()
  }

  convertStatus(convMap, key)
  {
    if (!(convMap instanceof Map)) {
      this.displayStatus = '状態不明'
      // throw new Error('1st arg. should be a Map.');
    }
    let val = convMap.get(key);
    return val ? val : convMap.get(null);
  }

  updateRobot(updateInfo) {
    this.previousStatus = JSON.parse(JSON.stringify(this.robotStatus));
    this.robotStatus.status = updateInfo['robot.status'];
    this.robotStatus.batteryLevel_pct = updateInfo['battery.level.pct'];
    this.robotStatus.chargingStatus = updateInfo['charging.status'];
    this.robotStatus.isBackCoverOpened = updateInfo['is.back.cover.opened']!=null ? updateInfo['is.back.cover.opened'] : false;
    this.robotStatus.isEnteringLeavingStation = updateInfo['is.entering.leaving.station'];
    this.robotStatus.elapsedTime_sec = updateInfo['elapsed.time.sec']!=null ? updateInfo['elapsed.time.sec'] : null;
    this.robotStatus.interval_ms = updateInfo['interval.ms'];
    this.robotStatus.location = updateInfo['location']!=null ? updateInfo['location'] : { 'lat.deg': 35.457976, 'lon.deg': 139.388327, 'azimuth.deg': null, 'precision': null };
    this.robotStatus.errors = updateInfo['errors'];
    this.robotStatus.fatalErrors = updateInfo['fatal.errors'];
    this.robotStatus.recoverableErrors = updateInfo['recoverable.errors'];
    this.robotStatus.warnings = updateInfo['warnings'];
    this.robotStatus.signalCondition = updateInfo['signal.condition']!=null ? updateInfo['signal.condition'] : null;
    this.robotStatus.speed_pct = updateInfo['speed.pct']!=null ? updateInfo['speed.pct'] : null;
    this.robotStatus.controller = updateInfo['controller']!=null ? updateInfo['controller'] : null;
    this.robotStatus.application = this.robotStatus.application.updateApplication(updateInfo)
    this.robotStatus.lastGetStatusTime = new Date().getTime();
    this.updateDisplayStatus()
    return this;
  }

  updateDisplayStatus() {
    if (this.robotStatus.lastGetStatusTime === null || new Date().getTime() - this.robotStatus.lastGetStatusTime > this.robotStatus.interval_ms + 30000) {
      this.displayStatus = '状態不明'
      this.robotStatus.location.precision = null
      return
    }
    else {
      let statusRunningToMessage = null
      switch (this.robotStatus.application.appName) {
        case 'check.plant':
          statusRunningToMessage = new Map([
            ['autonomous', `${this.robotStatus.application.route[0]}点検中`],
            ['remote.ctrl', '遠隔操作中'],
            [null, '状態不明'],
            [undefined, '状態不明']
          ]);
          break
        case 'create.route':
          statusRunningToMessage = new Map([
            ['autonomous', 'ルート設定中'],
            ['remote.ctrl', 'ルート設定中'],
            [null, 'ルート設定中'],
            [undefined, 'ルート設定中']
          ]);
      }

      switch(this.robotStatus.status) {
        case 'stop':
          this.displayStatus = this.convertStatus(this.STATUS_STOP_TO_MESSAGE, this.robotStatus.chargingStatus);
          break
        case 'waiting': // batteryLevel >= 50%と同義
          this.displayStatus = this.convertStatus(this.STATUS_WAITING_TO_MESSAGE, this.robotStatus.chargingStatus);
          break;
        case 'disabled': // batteryLevel < 50%と同義
          this.displayStatus = this.convertStatus(this.STATUS_DISABLED_TO_MESSAGE, this.robotStatus.chargingStatus);
          break;
        case 'running':
          if (this.robotStatus.isEnteringLeavingStation) {
            this.displayStatus = '充電動作中';
            break;
          } else {
            this.displayStatus = this.convertStatus(statusRunningToMessage, this.robotStatus.application.drivingMode);
            break;
          }
        case 'shutdown':
          this.displayStatus = 'シャットダウン中'
          break;
        case 'reboot':
          this.displayStatus = '再起動中'
          break;
        case 'error':
          this.displayStatus = 'エラー発生中'
          break;
        case 'done':
          this.displayStatus = this.convertStatus(this.STATUS_DONE_TO_MESSAGE, this.robotStatus.application.appName);
          break;
        default:
          this.displayStatus = '状態不明'
          break;
      }
    }
  }
}