<template>
  <div id="combine-routes-dialog" >
    <v-dialog persistent max-width="850px" v-model="c_dialogVisible">
      <template v-slot:activator="{c_dialogVisible}"></template> <!-- eslint-disable-line -->
      <v-card class="dialog">
        <v-toolbar flat dark class="dialog__title" color="#009999" height="40">
          連結ルートの作成</v-toolbar>
        <v-container fluid>
          <v-row class="pt-5">
            <v-col cols="5" class="dialog__route-list pb-0 pr-0">
              <v-sheet class="dialog__list-box pr-0" outlined>
                <v-card-text v-show="isNoData" class="dialog__no-registering-route-card-text pa-0">
                  表示するデータがありません</v-card-text>
                <v-sheet v-show="!isNoData" class="dialog__route-list-sheet">
                  <div v-for="route in routeList" :key="route.routeId">
                    <v-hover v-slot="{ hover }" >
                      <v-card @click="onClickRoute(route)" outlined tile 
                        @keydown.prevent.shift.down="moveToNextOrPrevRoute('dialog__route-list-card', $event)"
                        @keydown.prevent.shift.up="moveToNextOrPrevRoute('dialog__route-list-card', $event)"
                        class="dialog__route-list-card text-no-wrap pa-1"
                        :class="{ 'on-hover': hover }"
                        :color="!route.isSelected && hover ? '#E3F2FD' : route.color">
                        <v-card-text class="dialog__route-list-card-text pa-0">
                          {{ route.routeName }}</v-card-text>
                      </v-card>
                    </v-hover>
                  </div>
                </v-sheet>
              </v-sheet>
            </v-col>
            <v-col cols="2" class="dialog__list-button-area">
              <v-btn @click="pushRoutesIntoSelectedList" color="#0099FF" 
                class="dialog__list-button" outlined depressed>追加 >></v-btn>
            </v-col>
            <v-col cols="5" class="dialog__registering-list pb-0 pl-0">
              <v-sheet class="dialog__registering-list-box pl-0" outlined>
                <v-card v-show="registeringList.length===0" disabled color="#F5F5F5"
                  class="dialog__no-registering-route-card">
                  <v-card-text class="dialog__no-registering-route-card-text pa-0" >
                    連結されるルート</v-card-text>
                </v-card>
                <v-sheet v-show="registeringList.length!==0" class="dialog__registering-list-sheet">
                  <draggable animation: 200 v-model="registeringList">
                    <div v-for="route in registeringList" :key="route.index">
                      <v-card>
                      <div style="display: flex;">
                        <v-col cols="10" class="pa-0">
                          <v-card @click="onClickRoute(route)" outlined tile 
                            class="dialog__registering-list-card text-no-wrap pa-1"
                            :color="route.color"
                            >
                            <v-card-text class="dialog__registering-list-card-text pa-0">
                              {{ route.routeName }}</v-card-text>
                          </v-card>
                        </v-col>
                        <v-col cols="2" class="pa-0">
                          <v-card outlined tile :color="route.color" 
                            class="dialog__remove-card">
                            <v-btn text fab @click="removeRouteFromSelectedList" 
                              class="dialog__remove-card-button pa-0" :disabled="!route.isSelected">
                              <v-icon>mdi-window-close</v-icon>
                            </v-btn>
                          </v-card>
                        </v-col>
                      </div>
                      </v-card>
                    </div>
                  </draggable>
                </v-sheet>
              </v-sheet>
            </v-col>
            <v-col cols="7"/>
            <v-col cols="5" class="dialog__registering-list px-0 py-0">
              <v-col cols="4" class="pb-0">
                <v-btn @click="selectAllRoute"
                  class="dialog__select-unselect-all-button" outlined depressed>全選択</v-btn>
              </v-col>
              <v-col cols="4" class="pb-0">
                <v-btn @click="removeSelectFromAllRoute"
                  class="dialog__select-unselect-all-button" outlined depressed>選択解除</v-btn>
              </v-col>
              <v-col cols="4"/>
            </v-col>
            <v-col cols="7"/>
            <v-col cols="5" class="dialog__selected-list px-0 py-0">
              <v-card-text class="dialog__combined-route-counter pa-0 ma-3">
                連結されるルート数：{{ routeCount }}</v-card-text>
            </v-col>
            <v-col cols="8" class="dialog__text-field ">
              <v-card-text class="dialog__item py-1">新規ルート名</v-card-text>
              <v-text-field ref="textField" outlined class="ml-8" :rules="[rules.required]" v-model="routeName"></v-text-field>
            </v-col>
            <v-col col="6" class="dialog__buttons py-0"/>
            <v-col col="6" class="dialog__buttons py-0">
              <v-card-actions>
                <v-layout justify-end>
                  <v-btn rounded dark class="dialog__button pt-0" color="#707070" 
                    @click="finishCombinationDialog">
                    キャンセル</v-btn>
                  <v-btn rounded :dark="routeName!=='' && registeringList.length>=2" 
                    :disabled="routeName==='' || registeringList.length<2" color="#0099FF"
                    class="dialog__button mx-3 pt-0" @click="registercombinedRoutes">
                    登録</v-btn>
                </v-layout>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <progress-dialog :progress-visible="isShowProgressDialog" 
      :progress-message="progressMessage" :info-visible="isShowInfoDialog" 
      :info-type="infoType" :info-message="infoMessage" @closeDialog="finishCombinationDialog"></progress-dialog>
  </div>
</template>

<script>
import ProgressDialog from './ProgressDialog.vue'
import Draggable from 'vuedraggable'
import Routes from '../class/Routes'

const SELECTED_COLOR = "#BBDEFB"
const UN_SELECTED_COLOR = "#FFFFFF"

export default {
  components: {
    Draggable,
    ProgressDialog
  },
  props: {
    dialogVisible: { type: Boolean },
  },
  data(){
    return {
        routeName: "",
        progressMessage: "",
        isShowProgressDialog: false,
        infoMessage: "",
        isShowInfoDialog: false,
        infoType: 'nomal',
        routeCount: 0,
        sampleData: [
          {
            checkingRobot : null,
            distanceM : 10.155187031471444,
            registrationTime : "2022/11/10 16:57:50",
            routeId : "20221110165750_22al512a78534fb581ad2d33c9aa8ttt",
            routeName : "not combined sampleaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",
            status : "点検実施予定はありません"
          },
          {
            checkingRobot : null,
            distanceM : null,
            registrationTime : "2022/11/10 13:55:50",
            routeId : "20221110135550_55ef512a78534fb581ad2d33c9aa8kkk",
            routeName : "combined sample",
            status : "点検実施予定はありません"
          },
          {
            checkingRobot : null,
            distanceM : 10.155187031471444,
            registrationTime : "2022/11/10 16:57:50",
            routeId : "20221110165750_22al512a78534fb581aaaad33c9aa8ttt",
            routeName : "サンプルです",
            status : "点検実施予定はありません"
          },
        ],
        routeList: [],
        registeringList: [],
        isNoData: false,
        rules: {
            required: value => !!value || '必須入力です。'
        }
      }
  },
  mounted() {
    this.initialize()
  },
  methods: {
    async initialize() {
      this.routeList.splice(0);
      console.log(this.routeList)
      const data = await Routes.getRouteList()
      if (data != null && data.length !== 0) {
        data.forEach(route => {
          if (route.combinedRoutes === null) {
            let unCombinedRoute = {
              routeId : route.routeId,
              routeName : route.routeName,
              isSelected : false,
              color : UN_SELECTED_COLOR
            }
            this.routeList.push(unCombinedRoute)
          }
        })
      } else {
        this.isNoData = true;
      }
    },
    async registercombinedRoutes(){
      let combinedRoutes = []       
      this.registeringList.forEach(route => {
        combinedRoutes.push(route.routeId)
      })
      this.progressMessage = `ルート名：${this.routeName}を登録中です。`
      this.isShowProgressDialog = true
      await Routes.registerCombinedRoute(this.routeName, combinedRoutes)
      .then(response => {
        console.log(response)
        this.infoType = 'normal'
        this.infoMessage = `ルート名：${this.routeName}を登録しました。`
      })
      .catch(err => {
        console.log(err)
        this.infoType = 'error'
        this.infoMessage = `ルート名：${this.routeName}の登録に失敗しました。`
      })
      .finally(() => {
        this.isShowInfoDialog = true
      })     
    },
    finishCombinationDialog() {
      this.c_dialogVisible = false
      this.routeName = ""
      this.routeList.splice(0)
      this.registeringList.splice(0)
      this.routeCount = 0
      this.isShowProgressDialog = false
      this.progressMessage = ''
      this.isShowInfoDialog = false
      this.infoType = 'normal'
      this.infoMessage = ''
      this.$refs.textField.reset();
      this.initialize()

      this.$emit('finish-routes-combination', { isSuccess: true //this.isSuccessRegistration 
      })
    },
    /**
     * route-listでrouteのカードが選択された時、isSelectedフラグと表示colorを反転させる
     */
    onClickRoute(route) {
      route.isSelected = !route.isSelected
      route.color = route.isSelected ? SELECTED_COLOR : UN_SELECTED_COLOR
    },
    /**
     * route-listで選択されている全てのrouteをselected-listに表示する
     * route-listの選択状態をリセットする
     */
    pushRoutesIntoSelectedList() {
      this.routeList.forEach(route => {
        if(route.isSelected) {
          this.registeringList.push({
            index: this.registeringList.length,
            routeId: route.routeId,
            routeName: route.routeName,
            isSelected: false,
            color: UN_SELECTED_COLOR
          })
        }
        route.isSelected = false
        route.color = UN_SELECTED_COLOR
      });
      this.routeCount = this.registeringList.length
    },
    /**
     * registeringListから選択されているrouteを全て取り除く
     */
    removeRouteFromSelectedList() {
      for (let i = this.registeringList.length -1; i >= 0; i--) {
        if (this.registeringList[i].isSelected) {
          this.registeringList.splice(i, 1)
        }
      }
      this.routeCount = this.registeringList.length
    },
    /**
     * リストの一つ次/一つ前のrouteに移動してclickイベントを発火する
     */
    moveToNextOrPrevRoute(className, event) {
      let e = new Event('click');
      const elements = document.getElementsByClassName(className)
      const index = [].findIndex.call(elements, e => e === event.target)
      if (event.key === "ArrowDown" && elements[index + 1]) {
        elements[index + 1].focus()
        elements[index + 1].dispatchEvent(e)
      } else if (event.key === "ArrowUp" && elements[index - 1]) {
        elements[index - 1].focus()
        elements[index - 1].dispatchEvent(e)
      }
    },
    selectAllRoute() {
      for (let i = 0; i < this.registeringList.length; i++) {
        this.$set(this.registeringList[i], 'isSelected', true)
        this.$set(this.registeringList[i], 'color', SELECTED_COLOR)
      }
    },
    removeSelectFromAllRoute() {
      for (let i = 0; i < this.registeringList.length; i++) {
        this.$set(this.registeringList[i], 'isSelected', false)
        this.$set(this.registeringList[i], 'color', UN_SELECTED_COLOR)
      }
    }
  },
  computed: {
        c_dialogVisible: {
            get() {
                return this.dialogVisible
            },
            set(val) {
                this.$emit('dialog-visible', val)
            }
        },
    },
    watch: {
      /**
       * registeringListに変更があれば、'index'を格納順にセットしなおす
       */
      registeringList: function(){
        for(let i=0; i<this.registeringList.length; i++) {
          // Vueが変更を検知しDOMに反映させるために、$setを使う
          // $set 第一引数：オブジェクト 第二引数：オブジェクト要素のキー 第三引数：値
          this.$set(this.registeringList[i], 'index', i)
        }
      }
    }
  
}
</script>

<style scoped>
.dialog {
  min-width: 850px;
  height: 555px;
  position: relative
}
.dialog__button {
  width: 164px !important;
  height: 54px !important;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 26px;
  opacity: 1;
  text-align: left;
  font: normal normal bold 24px/32px Segoe UI !important;
  letter-spacing: 0px;
}
.dialog__title {
  text-align: left;
  font: normal normal bold 24px/32px Segoe UI;
  letter-spacing: 0px;
  opacity: 1;
}
.dialog__item {
  max-width: 580px;
  text-align: left;
  font: normal normal bold 24px/32px Segoe UI;
  letter-spacing: 0px;
  color: #000000 !important;
  opacity: 1;
}
.dialog__item-selector {
  width: 341px !important;
}
.dialog__route-list {
  display: flex;
  justify-content: flex-end;
}
.dialog__list-box {
  overflow-y: auto;
  overflow-x: auto;
  height: 200px;
  width: 290px; 
}
.dialog__route-list-sheet {
  min-width: fit-content;
}
.dialog__route-list-card {
  cursor: default;
  height: 38px;
  min-width: 100%; 
}
.dialog__route-list-card-text {
  font: normal normal 18px/26px Segoe UI;
  letter-spacing: 0px;
}
.v-card--link:before {
  background: none;
}
.dialog__list-button-area {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dialog__list-button {
  font: normal normal bold 18px/24px Segoe UI !important;
  letter-spacing: 0px;
  height: 28px;
  width: 100px;
}
.dialog__registering-list {
  display: flex;
  justify-content: flex-start;
}
.dialog__registering-list-box {
  overflow-y: auto;
  overflow-x: hidden;
  height: 200px;
  width: 290px; 
}
.dialog__no-registering-route-card {
  height: 198px;
  width: 288px;
}
.dialog__no-registering-route-card-text {
  font: normal normal 14px/22px Segoe UI;
  letter-spacing: 0px;
}
.dialog__registering-list-sheet {
  min-height: 201px;
}
.dialog__registering-list-card {
  overflow-x: scroll !important;
  overflow-y: hidden !important;
  cursor: pointer;
  height: 38px;
  width: 260px;
}
.dialog__registering-list-card::-webkit-scrollbar{
  display: none;
}
.dialog__registering-list-card-text {
  font: normal normal 18px/26px Segoe UI;
  letter-spacing: 0px;
  height: 30px;
  width: 240px;
}
.dialog__remove-card {
  height: 38px;
}
.dialog__remove-card-button {
  height: 37px !important;
  width: 25px !important;
}
.dialog__select-unselect-all-button {
  height: 30px !important;
  font: normal normal bold 18px/24px Segoe UI !important;
  color: #424242 !important;
}
.dialog__combined-route-counter {
  max-width: 200px;
  font: normal bold 16px Segoe UI;
  letter-spacing: 1px;
  color: #424242 !important;
}
.dialog__text-field {
  position: absolute;
  top: 330px;
}
.dialog__buttons {
  position: absolute;
  top: 460px;
}
</style>


