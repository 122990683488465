<template>
  <v-dialog persistent overlay-opacity=0.1 max-width="621px" v-model="c_checkFinishFromCrawlerConfirmDialogVisible">
      <template v-slot:activator="{c_dialogVisible}"></template> <!-- eslint-disable-line -->
      <v-card class="dialog">
        <v-toolbar flat dark class="dialog__title" color="#4A4B4C" height="40" v-show="operatingMode==='check.plant'">
            <v-icon large class="mr-2">$vuetify.icons.info</v-icon>点検終了
        </v-toolbar>
        <v-card-text class="dialog__message mt-10" v-show="operatingMode==='check.plant'">点検が終了しました。ホーム画面に戻ります。
        </v-card-text>

        <v-toolbar flat dark class="dialog__title" color="#4A4B4C" height="40" v-show="operatingMode==='create.route'">
            <v-icon large class="mr-2">$vuetify.icons.info</v-icon>ルート設定終了
        </v-toolbar>
        <v-card-text class="dialog__message mt-10" v-show="operatingMode==='create.route'">ロボット側でルート設定が終了されました。<br>ホーム画面に戻ります。
        </v-card-text>

        <v-card-actions>
            <v-layout justify-end class="mb-5">
                <v-btn rounded dark class="dialog__button" color="#0099FF" @click="confirm">OK</v-btn>
            </v-layout>
        </v-card-actions>
      </v-card>
  </v-dialog>

</template>

<script>
export default {
    props: {
        checkFinishFromCrawlerConfirmDialogVisible: { type: Boolean },
    },
    data() {
        return {
            operatingMode: this.$store.state.selectedMode
        }
    },
    computed: {
        c_checkFinishFromCrawlerConfirmDialogVisible: {
            get() {
                return this.checkFinishFromCrawlerConfirmDialogVisible
            }
        }
    },
    methods: {
        confirm() {
            this.$emit('finishFromCrawler')
        }
    }
}
</script>

<style scoped>
.dialog {
    max-width: 621px;
    min-height: 200px;
}
.dialog__button {
    min-width: 164px !important;
    height: 54px !important;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 26px;
    opacity: 1;
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
}
.dialog__message {
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
    color: #000000 !important;
    opacity: 1;
    white-space: pre-wrap;
}
.dialog__title {
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
    opacity: 1;
}
</style>