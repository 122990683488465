// Copyright(c) 2024 RICOH Company, Ltd. All rights reserved.

'use strict'

export class BeepSound {
  audioContext = null
  oscillator = null
  gain = null
  time = null

  constructor(type, frequency, volume, time) {
    this.audioContext = new window.AudioContext();
    this.oscillator = this.audioContext.createOscillator();
    this.gain = this.audioContext.createGain();
    this.time = time
    
    this.oscillator.connect(this.gain);
    this.gain.connect(this.audioContext.destination);

    this.oscillator.type = type;
    this.oscillator.frequency.value = frequency;
    this.gain.gain.value = volume;
  }

  play() {
    this.oscillator.start()
    if (this.time != null) {
      this.oscillator.stop(this.time)
      let self = this
      this.oscillator.onended = () => {
        self.gain.disconnect(self.audioContext.destination);
        self.oscillator.disconnect(self.gain);
      }
    }
  }

  stop() {
    this.oscillator.stop()
    let self = this
    this.oscillator.onended = () => {
      self.gain.disconnect(self.audioContext.destination);
      self.oscillator.disconnect(self.gain);
    }
  }
}