<template>
    <div id="route-list">
        <v-container fluid class="route-list">
            <v-row>
                <v-col cols="5" sm="5" md="6" lg="7" xl="8">
                    <route-view ref="route_view"
                    :initCenter="[35.29708092453554, 139.57834696746266]"
                    :initZoom="18"
                    :enableContextMenu="false"
                    :enableMultiSelect="false"
                    :showRobot="false"
                    :centerRobot="false"
                    :enableExpandShrinkCtrl="false"
                    :enableWaypointRemove="false"
                    :enableWaypointSelect="false"
                    ></route-view>
                </v-col>

                <v-col cols="7" sm="7" md="6" lg="5" xl="4">
                    <v-card elevation="0">
                        <v-row class="mt-2 mb-4 mr-2">
                            <v-col col="5"/>
                            <v-col col="7">
                                <v-btn rounded block class="route-list__button mr-10 white--text" color="#009999" @click="clickCombineRoutes">連結ルート作成</v-btn>
                            </v-col>
                        </v-row>
                        <v-toolbar flat class="route-list__title">ルート一覧
                            <v-btn class="ml-auto mr-2" color="#0099FF" dark @click="reloadRouteList"><v-icon>mdi-autorenew</v-icon></v-btn>
                        </v-toolbar>
                        <v-list class="overflow-y-auto" :height="routeListTab">
                            <v-list-item-group v-model="selectedItem">
                                <template v-for="(item, index) in routeList">
                                    <v-list-item :key="item.routeId" @click="displayRoute(item.routeId, item.combinedRoutes)">
                                        <v-list-item-content>
                                            <v-col cols="1" v-show="selectedItem===index">
                                                <div class="route-list__selected-bar"></div>
                                            </v-col>
                                            <v-col cols="10">
                                                <v-layout>
                                                    <v-card-text class="route-list__item--thick-text">{{item.routeName}}</v-card-text>
                                                    <v-icon x-large justify-end @click="confirmDeleteRoute(item)">mdi-delete</v-icon>
                                                </v-layout>
                                                <v-card-text class="route-list__item--thin-text">{{item.status}}</v-card-text>
                                                <v-card-text class="route-list__item--thin-small-text">作成日時：{{item.registrationTime}}</v-card-text>
                                                <v-card-text class="route-list__item--thin-small-text">総距離：{{item.distanceM != null ? Number(item.distanceM).toFixed(1) + ' m' : null}}</v-card-text>
                                                <v-layout justify-end class="mt-5">
                                                    <v-btn rounded disabled class="route-list__button mr-5" color="#0099FF">ルート編集</v-btn>
                                                    <v-btn rounded :dark="item.status==='点検実施予定はありません'" class="route-list__button" color="#0099FF" :disabled="item.status!=='点検実施予定はありません'" @click="startCheck(item)">点検開始</v-btn>
                                                </v-layout>
                                            </v-col>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider :key="index"></v-divider>
                                </template>
                            </v-list-item-group>
                        </v-list>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <progress-dialog class="route-list__dialog--front" :progress-visible="isShowProgressDialog" :progress-message="progressExecuteMessage" :info-visible="isShowExecuteInfoDialog" :info-type="executeInfoType" :info-message="executeInfoMessage" @closeDialog="closeDialog"></progress-dialog>
        <combine-routes-dialog class="route-list__dialog--front" ref="route_registration_dialog" :dialog-visible="isShowCombinationDialog"  @dialog-visible="isShowCombinationDialog=$event" @finish-routes-combination="finalizeRoutesCombination"></combine-routes-dialog>
        <check-start-confirm-dialog class="route-list__dialog--front" :check-start-confirm-dialog-visible="isShowDialog" :route-id="selectedRouteId" :route-name="selectedRouteName" :crawler-name="''" :available-crawler-list="availableRobotList" :route-list="[]" :isFromRouteList=true :isFromHome=false @startCheckExecution="executeCheck"></check-start-confirm-dialog>
        <confirm-dialog class="route-list__dialog--front" :confirm-visible="isShowConfirmDialog" :confirm-message="confirmMessage" @confirm-visible="isShowConfirmDialog=$event" @confirm="deleteRoute" @closeDialog="endDelete" :progress-visible="isProgressing" :progress-message="progressMessage" :info-visible="isShowInfoDialog" :info-type="infoType" :info-message="infoMessage"></confirm-dialog>
    </div>
</template>

<script>
import Robots from '../class/Robots'
import Routes from '../class/Routes'
import RobotAliveMonitoringCommand from '../util/RobotAliveMonitoringCommand'
import CombineRoutesDialog from './CombineRoutesDialog.vue'
import CheckStartConfirmDialog from './CheckStartConfirmDialog.vue'
import ConfirmDialog from './ConfirmDialog.vue'
import MqttCommand from '../util/MqttCommand'
import ProgressDialog from './ProgressDialog.vue'
import RouteView from '@ricoh-srg-system/routeview-component'

export default {
    components: { 
        RouteView,
        CheckStartConfirmDialog,
        ConfirmDialog,
        ProgressDialog,
        CombineRoutesDialog
    },
    props: {
    },
    computed: {
        checkingStatus() {
            return this.routeList.filter(item => (item.status !== '点検実施予定はありません'))
        },
        notCheckingStatus() {
            return this.routeList.filter(item => (item.status === '点検実施予定はありません'))
        }
    },
    data() {
        return {
            routeListContainer: '450px',
            routeListTab: '600px',
            managedRobotList: [],
            routeList: [],
            robotObjList: null,
            selectedItem: -1,
            selectedRouteId: '',
            selectedRouteName: '',
            isShowDialog: false,
            isShowConfirmDialog: false,
            confirmMessage: '',
            isProgressing: false,
            progressMessage: '',
            isShowInfoDialog: false,
            infoType: 'normal',
            infoMessage: '',
            isShowProgressDialog: false,
            progressExecuteMessage: '',
            isShowExecuteInfoDialog: false,
            executeInfoType: 'normal',
            executeInfoMessage: '',
            isShowCombinationDialog: false
        }
    },
    beforeDestroy() {
        RobotAliveMonitoringCommand.deleteRobotMonitoringCallback(this.updateCheckRouteStatus)
        window.removeEventListener('resize', this.fitRouteListWindowSize, false)
    },
    mounted() {
        window.addEventListener('resize', this.fitRouteListWindowSize, false)
        this.fitRouteListWindowSize()
        this.initialize()
    },
    computed: {
        availableRobotList() {
            return this.managedRobotList.filter(item => ((item.robotStatus.status === 'waiting' || item.robotStatus.status === 'stop')))
        },
    },
    methods: {
        fitRouteListWindowSize() {
            this.routeListContainer = document.getElementById('tabs')
            this.routeListContainer.style.height = `${window.innerHeight - document.getElementsByTagName('header')[0].clientHeight}px`
            
            this.routeListTab = this.routeListContainer.clientHeight * 0.85
        },
        async initialize() {
            this.routeList = await Routes.getRouteList()
            this.managedRobotList = await Robots.getManagedRobotList()
            // ロボット状態の変化からルートの点検実施状況をモニタリングする
            RobotAliveMonitoringCommand.setRobotMonitoringCallback(this.updateCheckRouteStatus)
            this.$refs.route_view.redraw()
        },
        updateCheckRouteStatus(robot) {
            // ルートのステータスを変更する
            let checkFinishRoute = this.routeList.find(val => val.checkingRobot === robot.robotStatus.serialNo)
            if (checkFinishRoute != null) {
                if (robot.robotStatus.status === 'waiting') {
                    checkFinishRoute.checkingRobot = null
                    checkFinishRoute.status = '点検実施予定はありません'
                }
            }

            if (robot.robotStatus.status !== 'waiting') {
                if (robot.robotStatus.application.appName === 'check.plant' 
                    && robot.robotStatus.application.route.length !== 0) {
                        let checkingRoute = this.routeList.find(val => val.routeId === robot.robotStatus.application.route[1])
                        if (checkingRoute != null) {
                            checkingRoute.checkingRobot = robot.robotStatus.serialNo
                            checkingRoute.status = robot.robotStatus.robotName != null ? `${robot.robotStatus.robotName}が点検実施中` : `${robot.robotStatus.serialNo}のcrawlerが点検実施中`
                        }
                }
            }
        },
        startCheck(item) {
            this.selectedRouteId = item.routeId
            this.selectedRouteName = item.routeName
            this.isShowDialog = true
        },
        async executeCheck(obj) {
            if(obj.serialNo !== null && obj.routeId === null) {
                let recvTopic = `${sessionStorage.getItem('userPoolId')}/${obj.serialNo}/usr_program/exec-ctrl/reply`
                MqttCommand.subscribe(recvTopic)
                let topicRegExp = /ap-northeast-1_([0-9a-zA-Z]){9}\/([0-9a-z]){12,}\/usr_program\/exec-ctrl\/reply/
                let type = 'cmd'
                let name = 'run'
                let payload = {
                    param:  {
                        "route": `plant_check/routes/${this.selectedRouteId}`,
                        "repeat.count": obj.isExecuteContinuousCheck ? -1 : null
                    },
                    program: 'check.plant',
                    experimental: {
                        'does.start.from.charging.station': obj.isStartingFromChargingStation,
                        'does.return.to.charging.station': obj.isReturningToChargingStation,
                        "does.reset.ptz.origin.position": obj.isExecuteResetPtzOriginPosition
                    }
                }
                let packet = await MqttCommand.createPacket(type, name, payload)
                let packetId = packet['packet.id']
                // let timeoutId = ''
                let self = this
                MqttCommand.setSubscribeTopicHandler(topicRegExp, packetId, function(topic, data) {
                    if (data['replied.to'] === this) {
                        if (data.payload['has.succeeded']) {
                            self.isShowDialog = false
                            self.isShowProgressDialog = false
                            self.progressExecuteCheckMessage = ''
                            self.isShowExecuteInfoDialog = false
                            self.executeInfoType = 'normal'
                            self.executeInfoMessage = ''
                            self.$router.push({name: 'CrawlerDriving',  params: { 'serial_no': obj.serialNo, 'route_id': self.selectedRouteId, 'operating_mode': 'check.plant' }})
                        }
                        else {
                            self.executeInfoType = "error"
                            self.executeInfoMessage = "点検開始に失敗しました。"
                            self.isShowExecuteInfoDialog = true
                        }
                        MqttCommand.removeSubscribeTopicHandler(topicRegExp, packetId)
                        MqttCommand.unsubscribe(recvTopic)
                        // clearTimeout(timeoutId)
                    }
                }.bind(packetId))
                .then(()=>{
                    let sendTopic = `${sessionStorage.getItem('userPoolId')}/${obj.serialNo}/usr_program/exec-ctrl`
                    MqttCommand.sendPacket(sendTopic, packet)
                    this.progressExecuteMessage = '点検を開始しています。'
                    this.isShowProgressDialog = true

                    // // timeoutId = setTimeout(function() {
                    //     MqttCommand.removeSubscribeTopicHandler(topicRegExp, packetId)
                    //     MqttCommand.unsubscribe(recvTopic)
                    //     this.executeInfoType = "error"
                    //     this.executeInfoMessage = "点検開始に失敗しました。\nネットワークを確認してください。"
                    //     this.isShowExecuteInfoDialog = true
                    //     clearTimeout(timeoutId)
                    // }.bind(this), 10000)
                })
            }
            else if (obj.serialNo === null && obj.routeId === null) {
                this.selectedRouteId = ''
                this.selectedRouteName = ''
                this.isShowDialog = false
            }
            else {
                // nothing
            }
        },
        closeDialog() {
            this.isShowProgressDialog = false
            this.progressExecuteCheckMessage = ''
            this.isShowExecuteInfoDialog = false
            this.executeInfoType = 'normal'
            this.executeInfoMessage = ''
        },
        confirmDeleteRoute(item) {
            this.selectedRouteId = item.routeId
            this.selectedRouteName = item.routeName
            this.confirmMessage = `ルート名：${this.selectedRouteName}を削除します。\nよろしいですか。`
            this.isShowConfirmDialog = true
        },
        async deleteRoute() {
            this.progressMessage = `ルート名：${this.selectedRouteName}を削除しています。`
            this.isProgressing = true

            Routes.deleteRoute(this.selectedRouteId)
            .then(() => {
                let deleteIndex = this.routeList.findIndex((item)=> {
                   return item.routeId === this.selectedRouteId
                })
                if(deleteIndex !== -1) {
                    this.routeList.splice(deleteIndex, 1)
                }
                this.$refs.route_view.undrawRoute()
                this.infoMessage = `ルート名：${this.selectedRouteName}を削除しました。`
                this.infoType = 'normal'
                this.isShowInfoDialog = true
            })
            .catch(() => {
                this.infoMessage = `ルート名：${this.selectedRouteName}を削除に失敗しました。`
                this.infoType = 'error'
                this.isShowInfoDialog = true
            })
        },
        endDelete() {
            this.selectedRouteId = ''
            this.selectedRouteName = ''
            this.isShowConfirmDialog = false
            this.confirmMessage = ''
            this.isProgressing = false
            this.progressMessage = ''
            this.isShowInfoDialog = false
            this.infoType = 'normal'
            this.infoMessage = ''
        },
        async displayRoute(routeId, combinedRoutes) {
            try {
                let cache = require('js-cache')
                let ttl = 1200000
                
                let cachedRouteGpxList = cache.get(routeId)
                if (cachedRouteGpxList != null) {
                    this.$refs.route_view.setWaypointsFromGpx(cachedRouteGpxList)
                }
                else {
                    let routeGpxList = []
                    if (combinedRoutes == null) {
                        routeGpxList.push(await Routes.getRouteGpx(routeId))
                    }
                    else {
                        for (let routeId of combinedRoutes) {
                            routeGpxList.push(await Routes.getRouteGpx(routeId))
                        }
                    }
                    cache.set(routeId, routeGpxList, ttl)
                    this.$refs.route_view.setWaypointsFromGpx(routeGpxList)
                }
            }
            catch(error) {
                // タイトル文字色を変える
                // 点検開始ボタンを押下できなくする
            }
        },
        clickCombineRoutes(){
            this.isShowCombinationDialog = true
        },
        finalizeRoutesCombination(){
            setTimeout(() => {
                Routes.lastUpdate = new Date(0).getTime()
            , 2000});
        },
        async reloadRouteList(){
            Routes.lastUpdate = new Date(0).getTime()
            this.routeList = await Routes.getRouteList()
        }
    }
}
</script>

<style scoped>
.route-list {
    min-height: 500px;
}
.route-list__button {
    width: 164px;
    height: 54px !important;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 26px;
    opacity: 1;
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI !important;
    letter-spacing: 0px;
    opacity: 1;
}
.route-list__dialog--front {
    z-index: 1000;
}
.route-list__item--thick-text {
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
.route-list__item--thin-text {
    text-align: left;
    font: normal normal normal 24px/32px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
.route-list__item--thin-small-text {
    text-align: left;
    font: normal normal normal 18px/24px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    padding-left: 30px !important;
    padding-top: 3px !important;
    padding-bottom: 3px !important;
}
.route-list__selected-bar{
    width: 10px !important;
    max-width: 10px;
    height: 135px;
    background: #009999;
}
.route-list__title {
    height: 59px !important;
    background: #F1F1F1 0% 0% no-repeat padding-box !important;
    opacity: 1;
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
</style>