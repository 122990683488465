<template>
  <v-dialog persistent max-width="600px" v-model="c_checkFinishConfirmDialogVisible">
      <template v-slot:activator="{c_dialogVisible}"></template> <!-- eslint-disable-line -->
      <v-card class="dialog">
        <v-toolbar flat dark class="dialog__title" color="#FF0000" height="71">
            <v-icon large class="mr-2">$vuetify.icons.infoWarning</v-icon>点検終了ボタンが押されました。
        </v-toolbar>
        <v-card-text class="dialog__message mt-10">本当に終了しますか？点検は再開できません。
        </v-card-text>
        <v-card-actions class="mb-3">
            <v-layout class="mb-5" justify-end>
              <v-btn outlined rounded class="dialog__button" color="#0099FF" @click="cancel">遠隔操作</v-btn>
              <v-btn rounded dark class="dialog__button mx-3" color="#707070" @click="finishCheck">点検終了</v-btn>
            </v-layout>
        </v-card-actions>
      </v-card>
  </v-dialog>

</template>

<script>
export default {
    props: {
        checkFinishConfirmDialogVisible: { type: Boolean },
    },
    data() {
        return {

        }
    },
    computed: {
        c_checkFinishConfirmDialogVisible: {
            get() {
                return this.checkFinishConfirmDialogVisible
            }
        }
    },
    methods: {
      finishCheck() {
        this.$emit('confirmCheckFinish')
      },
      cancel() {
        this.$emit('cancelCheckFinish')
      }
    }
}
</script>

<style scoped>
.dialog {
  max-width: 600px;
  min-height: 216px;
}
.dialog__button {
  min-width: 202px !important;
  height: 54px !important;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 21px;
  opacity: 1;
  text-align: left;
  font: normal normal bold 24px/32px Segoe UI;
  letter-spacing: 0px;
}
.dialog__message {
  text-align: left;
  font: normal normal normal 24px/32px Segoe UI;
  letter-spacing: 0px;
  color: #000000 !important;
  opacity: 1;
}
.dialog__title {
  text-align: left;
  font: normal normal bold 24px/32px Segoe UI;
  letter-spacing: 0px;
  opacity: 1;
}
</style>