<template>
  <v-dialog persistent max-width="600px" :retain-focus="false" v-model="c_resumeDialogVisible">
      <template v-slot:activator="{c_resumeDialogVisible}"></template> <!-- eslint-disable-line -->
      <v-card class="dialog">
        <v-toolbar flat dark class="dialog__title" color="#FF0000" height="71">
            <v-icon large class="mr-2">$vuetify.icons.infoWarning</v-icon>
            {{dialogItem.title}}
        </v-toolbar>
        <v-card-text class="dialog__message mt-10">
            {{dialogItem.text}}
        </v-card-text>
        <v-card-actions>
            <v-layout justify-end class="mb-5">
                <v-btn outlined rounded :disabled="!c_liftStop" class="dialog__button" color="#0099FF" v-show="operatingMode==='check.plant'" @click="resumeByAutonomousDriving">自律走行で再開</v-btn>
                <v-btn rounded :dark="c_liftStop" :disabled="!c_liftStop" class="dialog__button mx-3" color="#0099FF" @click="resumeByRemoteControlDriving">遠隔操作で再開</v-btn>
            </v-layout>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
export default {
    props: {
        resumeDialogVisible: { type: Boolean },
        dialogItem: {},
        operatingMode: { type: String },
        liftStop: { type: Boolean }
    },
    data() {
        return {
        }
    },
    computed: {
        c_resumeDialogVisible: {
            get() {
                return this.resumeDialogVisible
            }
        },
        c_liftStop: {
            get() {
                return this.liftStop
            }
        }
    },
    methods: {
        resumeByAutonomousDriving() {
            this.$emit('resume', { mode: 'autonomous', from: this.dialogItem.key })
        },
         resumeByRemoteControlDriving() {
            this.$emit('resume', { mode: 'remote.ctrl', from: this.dialogItem.key })
        }
    }
}
</script>

<style scoped>
.dialog {
    max-width: 600px;
    min-height: 216px;
}
.dialog__button {
  min-width: 202px !important;
  height: 54px !important;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 21px;
  opacity: 1;
  text-align: left;
  font: normal normal bold 24px/32px Segoe UI;
  letter-spacing: 0px;
}
.dialog__message {
  text-align: left;
  font: normal normal normal 24px/32px Segoe UI;
  letter-spacing: 0px;
  color: #000000 !important;
  opacity: 1;
}
.dialog__title {
  text-align: left;
  font: normal normal bold 24px/32px Segoe UI;
  letter-spacing: 0px;
  opacity: 1;
}
</style>