import LSKeepState from './LSKeepState'

class LSStartState {
    constructor() {}

    doReply(lsContext, reply) {
        if (reply.name === 'rsi.livestreaming.start') {
            lsContext.changeState(LSKeepState)
        }
    }

    doAction(lsContext, randomStr) {
        let payload = {
            token: randomStr,
            'video.source': "maincam:00", 
        }
        lsContext.publish('cmd', 'rsi.livestreaming.start', payload)
    }
}

const lsStartState = new LSStartState()
Object.freeze(lsStartState)
export default lsStartState