// robotcontrolutil.js

'use strict'

export default {
    zeroJoy: function () {
        return {
            axes: [
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 
            ],
            buttons: [
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 
            ]
        }
    },
    isBlankJoy: function(joy) {
        return joy.axes.every(v => v == 0) && joy.buttons.every(v => v == 0)
    },
    isCrossCursor: function(key) {
        return key === 'left' || key === 'right' || key === 'up' || key === 'down'
    },
    getCrossCursorKey: function(cursors) {
        let key = ''
        if (cursors['left'] && cursors['up']) 
        {
            key = 'leftup'
        } else if (cursors['right'] && cursors['up']) 
        {
            key = 'rightup'
        } else if (cursors['left'] && cursors['down']) 
        {
            key = 'leftdown'
        } else if (cursors['right'] && cursors['down']) 
        {
            key = 'rightdown'
        } else if (cursors['left']) 
        {
            key = 'left'
        } else if (cursors['right']) 
        {
            key = 'right'
        } else if (cursors['up']) 
        {
            key = 'up'
        } else if (cursors['down']) 
        {
            key = 'down'
        }
        return key
    }
}