import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css' // CDNから引っ張ってくるのではなく、material design iconを利用するためのimport

import User from '../components/icons/ICN001-01_user.vue';
import Battery20 from '../components/icons/ICN001-07_battery_20.vue';
import Battery40 from '../components/icons/ICN001-06_battery_40.vue';
import Battery60 from '../components/icons/ICN001-05_battery_60.vue';
import Battery80 from '../components/icons/ICN001-04_battery_80.vue';
import Battery100 from '../components/icons/ICN001-03_battery_100.vue';
import BatteryUnknown from '../components/icons/ICN001-08_battery_unknown.vue';

import ThreeBar from '../components/icons/ICN002-08_three_bar.vue';
import TwoBar from '../components/icons/ICN002-09_two_bar.vue';
import OneBar from '../components/icons/ICN002-10_one_bar.vue';
import NoBar from '../components/icons/ICN002-11_no_bar.vue';

import EmergencyStop from '../components/icons/ICN003-01_emergency_stop.vue';
import Info from '../components/icons/ICN003-02_info.vue';
import InfoPoint from '../components/icons/ICN003-03_info_point.vue';
import InfoWarning from '../components/icons/ICN003-04_info_warning.vue';
import Menu from '../components/icons/ICN003-05_menu.vue';

import AddPoint from '../components/icons/ICN004-01_add_point.vue';
import AddPhoto from '../components/icons/ICN004-02_add_photo.vue';
import ZoomUp from '../components/icons/ICN004-03_zoom_up.vue';
import ZoomDown from '../components/icons/ICN004-04_zoom_down.vue';

import Straight from '../components/icons/BTN001n_straight.vue';
import Back from '../components/icons/BTN002n_back.vue';
import DiagonalRight from '../components/icons/BTN003n_diagonal_ right.vue';
import DiagonalLeft from '../components/icons/BTN004n_diagonal_ left.vue';
import TurningRight from '../components/icons/BTN005n_turning_right.vue';
import TurningLeft from '../components/icons/BTN006n_turning_left.vue';
import Right from '../components/icons/BTN016n_right.vue'
import Left from '../components/icons/BTN017n_left.vue'
import Switching from '../components/icons/BTN007n_switching.vue';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',    // CDNから引っ張ってくるのではなく、material design iconを利用するための設定
        values: {
            user: {
                component: User
            },
            battery20: {
                component: Battery20
            },
            battery40: {
                component: Battery40
            },
            battery60: {
                component: Battery60
            },
            battery80: {
                component: Battery80
            },
            battery100: {
                component: Battery100
            },
            batteryUnknown: {
                component: BatteryUnknown
            },

            threeBar: {
                component: ThreeBar
            },
            twoBar: {
                component: TwoBar
            },
            oneBar: {
                component: OneBar
            },
            noBar: {
                component: NoBar
            },

            emergencyStop: {
                component: EmergencyStop
            },
            info: {
                component: Info
            },
            infoPoint: {
                component: InfoPoint
            },
            infoWarning: {
                component: InfoWarning
            },
            menu: {
                component: Menu
            },

            addPoint: {
                component: AddPoint
            },
            addPhoto: {
                component: AddPhoto
            },
            zoomUp: {
                component: ZoomUp
            },
            zoomDown: {
                component: ZoomDown
            },

            straight: {
                component: Straight
            },
            back: {
                component: Back
            },
            diagonalRight: {
                component: DiagonalRight
            },
            diagonalLeft: {
                component: DiagonalLeft
            },
            turningRight: {
                component: TurningRight
            },
            turningLeft: {
                component: TurningLeft
            },
            right: {
                component: Right
            },
            left: {
                component: Left
            },
            switching: {
                component: Switching
            }
        }
    }
});
