<template>
  <div class="outer">
    <ptz-camera ref="ptz_camera" class="ptz-camera-view" 
      @addPtz="addPtz($event)"
      @resizePtz="resizePtz"
      @destroyPtz="destroyPtz">
    </ptz-camera>
    <ptz-camera-zoom-controller class="zoom-controller-area" 
      v-show="operatingMode==='create.route' || (operatingMode==='check.plant'&&mode==='remote.ctrl')"
      @focusFar="focusFar" @focusNear="focusNear" @focusAuto="focusAuto"
      @zoomIn="zoomIn" @zoomOut="zoomOut" @zoomInDrastically="zoomInDrastically" @zoomOutDrastically="zoomOutDrastically" @zoomStop="zoomStop">
    </ptz-camera-zoom-controller>
    
    <ptz-camera-direction-and-shoot-controller class="direction-and-shoot-controller-area" 
      v-show="operatingMode==='create.route' || (operatingMode==='check.plant'&&mode==='remote.ctrl')"
      :cameraMode="cameraMode"
      @moveLeft="moveLeft" @moveRight="moveRight" @moveDown="moveDown" @moveUp="moveUp" @stop="stop" @ptzHome="ptzHome" @ptzBack="ptzBack" @registerVisual="registerVisual">
    </ptz-camera-direction-and-shoot-controller>
    
    <ptz-camera-rect-touch-select-controller ref="rect_touch_select_ctrl" class="rect-select-controller" 
      v-show="operatingMode==='create.route' || (operatingMode==='check.plant'&&mode==='remote.ctrl')"
      @centeringZoomWithRect="centeringZoomWithRect($event)"
      @clickAndCentering="clickAndCentering($event)"
      @touchAndCentering="touchAndCentering($event)">
    </ptz-camera-rect-touch-select-controller>

  </div>
</template>

<script>
import PtzCamera from "../parts/PtzCamera.vue"
import PtzCameraZoomController from '../parts/PtzCameraZoomController'
import PtzCameraDirectionAndShootController from "../parts/PtzCameraDirectionAndShootController"
import PtzCameraRectTouchSelectController from '../parts/PtzCameraRectTouchSelectController'
import { PtzController } from '../../class/PtzController'

export default {
  components: {
    PtzCamera,
    PtzCameraZoomController,
    PtzCameraDirectionAndShootController,
    PtzCameraRectTouchSelectController
  },
  props: {
    drivingMode: { type: String },
    cameraMode: { type: String }
  },
  data() {
    return {
      operatingMode: this.$store.state.selectedMode,
      mode: this.drivingMode,
      ptzConnectionId: null,
    }
  },
  watch: {
    drivingMode (mode) {
      this.mode = mode
    },
    cameraMode(mode) {
      if (mode === 'ptz') {
        this.$refs.rect_touch_select_ctrl.renderReticleAndCaption()
      }
      else {
        this.$refs.rect_touch_select_ctrl.destroyReticleAndCaption()
      }
    }
  },
  computed: {
  },
  beforeDestroy() {
  },
  created() {
    this.PtzController = new PtzController(this.$store.state.selectedCrawler)
  },
  mounted() {
  },
  methods: {
    addPtz(event) {
      this.ptzConnectionId = event.connectionId
      this.$refs.rect_touch_select_ctrl.destroyPtzCanvas()
      this.$refs.rect_touch_select_ctrl.addPtzCanvas(this.ptzConnectionId)
    },
    resizePtz() {
      this.$refs.rect_touch_select_ctrl.resizePtzCanvas()
    },
    destroyPtz() {
      this.$refs.rect_touch_select_ctrl.destroyPtzCanvas()
    },
    setLiveStreamingEvent(liveStreaming) {
      this.$refs.ptz_camera.setLiveStreamEventCallback(liveStreaming) 
    },
    reconnect() {
      this.$refs.rect_touch_select_ctrl.destroyPtzCanvas()
      this.$refs.ptz_camera.reconnect()
    },
    focusFar() {
      this.PtzController.focusFar()
    },
    focusNear() {
      this.PtzController.focusNear()
    },
    focusAuto() {
      this.PtzController.focusAuto()
    },
    zoomIn() {
      this.PtzController.zoomIn()
    },
    zoomOut() {
      this.PtzController.zoomOut()
    },
    zoomInDrastically() {
      this.PtzController.zoomInDrastically()
    },
    zoomOutDrastically() {
      this.PtzController.zoomOutDrastically()
    },
    zoomStop() {
      this.PtzController.zoomStop()
    },
    moveLeft() {
      this.PtzController.moveLeft()
    },
    moveRight() {
      this.PtzController.moveRight()
    },
    moveDown() {
      this.PtzController.moveDown()
    },
    moveUp() {
      this.PtzController.moveUp()
    },
    stop() {
      this.PtzController.stop()
    },
    ptzHome() {
      this.PtzController.returnHomePos()
    },
    ptzBack() {
      this.PtzController.moveBackPos()
    },
    centeringZoomWithRect(event) {
      this.PtzController.centeringZoomWithRect(event.left, event.top, event.width, event.height, event.connectionId, event.callback)
    },
    clickAndCentering(event) {
      this.PtzController.ptzClickAndCentering(event.clickEvent, event.connectionId)
    },
    touchAndCentering(event) {
      this.PtzController.ptzTouchAndCentering(event.touchEvent, event.connectionId)
    },
    registerVisual() {
      this.$emit('registerVisual', { connectionId: this.ptzConnectionId , resetZoom: this.PtzController.resetZoom.bind(this.PtzController) })
    },
    resetZoom() {
      this.PtzController.resetZoom()
    },
  }
}
</script>

<style scoped>
.direction-and-shoot-controller-area {
  position: absolute;
  bottom: 9%;
  right: 5px;
}
.outer {
  width: inherit;
  height: inherit;
}
.ptz-camera-view {
  width: 100%;
  height: 100%;
  background-color: #000000;
}
.rect-select-controller {
  position: absolute;
  top: 55%;
  margin: 20px;
}
.zoom-controller-area {
  position: absolute;
  bottom: 50%;
  right: 80px;
}
</style>