<template>
  <div ref="maincam" id="maincam">
    <v-progress-circular indeterminate class="progress-circular--center" color="#009999" width="10" size="150" v-show="!isMaincamArrived"></v-progress-circular>
  </div>
</template>

<script>
export default {
  data() {
    return {
      connectionId: null,
      isMaincamArrived: false
    }
  },
  created() {
  },
  mounted() {
    window.addEventListener('resize', this.resizeMaincam)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeMaincam)
  },
  methods: {
    setLiveStreamEventCallback(liveStreaming) {
      let self = this
      liveStreaming.on('maincam_start', function($video, connection_id, mediaStreamTrack, stream) {
        self.startMaincam($video, connection_id, mediaStreamTrack, stream)
      })

      liveStreaming.on('maincam_stop', function() {
        self.stopMaincam()
      })
    },
    startMaincam($video, connection_id, mediaStreamTrack, stream) {
        this.isMaincamArrived = true
        this.connectionId = connection_id
        let $maincamDiv = document.getElementById('maincam')
        $maincamDiv.appendChild($video);
        // 今回追加
        $video.zIndex = 1000;
        $video.style.position = 'absolute'
        
        if ($video.srcObject) {
          $video.srcObject.addTrack(mediaStreamTrack);
        } else {
          $video.srcObject = stream;
        }
        let isVideoPlaying = ($video.currentTime > 0 && !$video.paused && !$video.ended && $video.readyState > $video.HAVE_CURRENT_DATA)
        if (!isVideoPlaying) {
            $video.play()
        }
        this.resizeMaincam()
        this.$emit('addMaincam', { connectionId : connection_id })
    },
    stopMaincam() {
      this.destroy()
    },
    resizeMaincam() {
      // 画面サイズに応じて表示エリアの背景の大きさを変更
      let $containerDiv = document.getElementById('camera-and-ctrl') 
      let $maincamDiv = document.getElementById('maincam')
      let $video = document.getElementById(this.connectionId)
      let height = window.innerHeight - document.getElementsByTagName('header')[0].clientHeight
      if (height <500) {
        $maincamDiv.style.height = '500px'
      }
      else {
        $maincamDiv.style.height = `${height}px`
      }

      let maincamDivWidth = $containerDiv.clientWidth
      let maincamDivHeight = parseFloat($maincamDiv.style.height)

      // アスペクト比 16:9を維持したまま、配信映像表示エリア内に表示が収まるようサイズを修正する
      if ($video != null) {
        if (maincamDivWidth * 9 / 16 <= maincamDivHeight) {
          $video.style.height = `${maincamDivWidth * 9 / 16}px`
          $video.style.width = `${maincamDivWidth}px`
        }
        else if (maincamDivWidth * 9 / 16 > maincamDivHeight) {
          $video.style.height =`${maincamDivHeight}px`
          $video.style.width = `${maincamDivHeight * 16 / 9}px`
        }
        else {
          // nothing
        }
        $video.style.marginLeft = `${(maincamDivWidth - parseFloat($video.style.width)) / 2}px`
        $video.style.marginTop = `${(maincamDivHeight - parseFloat($video.style.height)) / 2}px`
        this.$emit('resizeMaincam')
      }
    },
    reconnect() {
      this.destroy()
    },
    destroy() {
      let $video = document.getElementById(this.connectionId)
      if ($video != null) {
        $video.pause()
      }
      let $maincamDiv = document.getElementById('maincam')
      if ($maincamDiv != null && $video != null && $maincamDiv.childElementCount > 0) {
        $maincamDiv.removeChild($video)
      }
      this.connectionId = null
      this.isMaincamArrived = false
      this.$emit('destroyMaincam')
    }
  }
}
</script>

<style scoped>
.progress-circular--center {
  margin-top: 18%;
  margin-left: 50%;
  transform: translateX(-50%);
}
.maincam {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #000000;
}
</style>