<template>
  <v-card id="user-card">
    <v-list>
        <v-list-item-group color="#0099FF">
        <v-list-item v-for="(item, i) in items" :key="i">
            <v-list-item-icon>
                <v-icon large v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content @click="selectOperationExecute(i)">
                <v-list-item-title class="card__item-text" v-text="item.text"></v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import AWSUtil from '../class/AWSUtil'
export default {
    data() {
        return {
            index: 0,
            items: [
                {text: 'Logout', icon: 'mdi-account-arrow-right'}
            ]
        }
    },
    methods: {
        selectOperationExecute(selectedItem) {
            switch(this.items[selectedItem].text) {
                case 'Logout' :
                    AWSUtil.disableAuthentication()
                    .then(() => {
                        this.$emit('logout', true)
                    })
                    .catch(() => {
                        this.$emit('logout', false)
                    })
                    break
                default:
                    break
            }
        }
    }
}
</script>

<style scoped>
.card__item-text {
    height: 40px;
    text-align: left;
    font: normal normal normal 24px/32px Segoe UI;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
}
</style>