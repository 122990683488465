// Copyright(c) 2024 RICOH Company, Ltd. All rights reserved.

'use strict'

import AWSUtil from '../class/AWSUtil'
import Robots from '../class/Robots'
import Routes from '../class/Routes'

export class Schedules {
  
  constructor() {
  }

  async getRobotList() {
    return await Robots.getManagedRobotList()
  }

  async getRouteList() {
    return await Routes.getRouteList()
  }

  getUserId() {
    let clientId = JSON.parse(sessionStorage.getItem('vuex')).clientId
    return sessionStorage.getItem(`CognitoIdentityServiceProvider.${clientId}.LastAuthUser`)
  }

  async getSchedules(selectedRobotList, selectListFromDate, selectListToDate) {
    const axios = require('axios')
    const url = `https://api.dev-crawlerrobo.trial.ricoh/tenants/${'M0001'}/schedules`
    
    // ヘッダーの署名を取得する
    let signedHeaderInfo = await AWSUtil.createSigV4(url, 'get')

    console.log('getSchedules: from date:' + selectListFromDate + ' , to date:' + selectListToDate);
        
    // 認可情報と署名したヘッダーを利用してAPIをコールする
    return axios.get(url, {
      headers: {
        'Authorization': signedHeaderInfo.Authorization,
        'X-Host-Override': signedHeaderInfo.Host,
        'X-Amz-Date': signedHeaderInfo['X-Amz-Date'],
        'X-Amz-Security-Token': signedHeaderInfo['x-amz-security-token'],
        'Robot-IDs': selectedRobotList,
        'Start-Date': new Date(selectListFromDate).getTime() / 1000,
        'End-Date': new Date(selectListToDate).getTime() / 1000
      }
    })
    .then((res) => {
      console.log('get schedules:')
      console.log(res.data)
      return res.data;
    })
    .catch((e) => {
      console.log(e);
    });
  }

  async registerSchedule(routeId, robotId, startDate, endDate, expectedWorktime, userId, recurringType, isSelectedStartingFromChargingStation, isSelectedReturningToChargingStation) {
    const axios = require('axios')

    let data = {
      routeId: routeId,
      robotId: robotId,
      startDate: startDate,
      endDate: endDate,
      expectedWorktime: expectedWorktime,
      userId: userId,
      recurringType: recurringType,
      doesStartFromChargingStation: isSelectedStartingFromChargingStation === false ?  0 : 1,
      doesReturnToChargingStation: isSelectedReturningToChargingStation === false ? 0 : 1
    }

    const url = `https://api.dev-crawlerrobo.trial.ricoh/tenants/${'M0001'}/schedules`
    
    // ヘッダーの署名を取得する
    let signedHeaderInfo = await AWSUtil.createSigV4(url, 'post', data)
        
    // 認可情報と署名したヘッダーを利用してAPIをコールする
    return axios.post(url, JSON.stringify(data), {
        headers: {
          'Authorization': signedHeaderInfo.Authorization,
          'X-Host-Override': signedHeaderInfo.Host,
          'X-Amz-Date': signedHeaderInfo['X-Amz-Date'],
          'X-Amz-Security-Token': signedHeaderInfo['x-amz-security-token'],
        },
      })
      .then((res) =>res.data)
      .catch((e) => {
        console.log(e);
      });
  }
  async editSchedule(scheduleId, userId, routeId, robotId, startDate, endDate, recurringType, isSelectedStartingFromChargingStation, isSelectedReturningToChargingStation, expectedWorktime) {
    const axios = require('axios')
    let data = {
      userId: userId,
      routeId: routeId,
      robotId: robotId,
      startDate: startDate,
      endDate: endDate,
      recurringType: recurringType,
      doesStartFromChargingStation: isSelectedStartingFromChargingStation === false ? 0 : 1,
      doesReturnToChargingStation: isSelectedReturningToChargingStation === false ? 0 : 1,
      expectedWorktime: expectedWorktime
    }

    const url = `https://api.dev-crawlerrobo.trial.ricoh/tenants/${'M0001'}/schedules/${scheduleId}`
    
    // ヘッダーの署名を取得する
    let signedHeaderInfo = await AWSUtil.createSigV4(url, 'put', data)
      
    // 認可情報と署名したヘッダーを利用してAPIをコールする
    return axios.put(url, JSON.stringify(data), {
      headers: {
        'Authorization': signedHeaderInfo.Authorization,
        'X-Host-Override': signedHeaderInfo.Host,
        'X-Amz-Date': signedHeaderInfo['X-Amz-Date'],
        'X-Amz-Security-Token': signedHeaderInfo['x-amz-security-token'],
      },
    })
    .then((res) =>res.data)
    .catch((e) => {
      console.log(e);
    });
  }

  async deleteSchedule(scheduleId) {
    const axios = require('axios')

    const url = `https://api.dev-crawlerrobo.trial.ricoh/tenants/${'M0001'}/schedules/${scheduleId}`
    
    // ヘッダーの署名を取得する
    let signedHeaderInfo = await AWSUtil.createSigV4(url, 'delete')
      
    // 認可情報と署名したヘッダーを利用してAPIをコールする
    return axios.delete(url, {
      headers: {
        'Authorization': signedHeaderInfo.Authorization,
        'X-Host-Override': signedHeaderInfo.Host,
        'X-Amz-Date': signedHeaderInfo['X-Amz-Date'],
        'X-Amz-Security-Token': signedHeaderInfo['x-amz-security-token'],
      },
    })
    .then((res) =>res.data)
    .catch((e) => {
      console.log(e);
    });
  }
}
