<template>
  <div>
    <v-btn fab outlined class="switch-button"
      :class="[{'switch-button__left' : mode==='ptz' }, {'switch-button__middle' : mode==='thermal'}, {'switch-button__right' : mode==='sound'}]" 
      v-show="mode!=='maincam'" 
      @click="changeCameraMode('maincam')">
        <v-icon x-large>$vuetify.icons.addPoint</v-icon>
    </v-btn>
    <v-btn fab outlined class="switch-button"
      :class="[{'switch-button__left' : mode==='thermal' }, {'switch-button__middle' : mode==='sound'}, {'switch-button__right' : mode==='maincam'}]"
      v-show="mode!=='ptz'" 
      @click="changeCameraMode('ptz')">
        <v-icon x-large>$vuetify.icons.addPhoto</v-icon>
    </v-btn>
    <v-btn fab outlined class="switch-button"
      :class="[{'switch-button__left' : mode==='sound' }, {'switch-button__middle' : mode==='maincam'}, {'switch-button__right' : mode==='ptz'}]"
      v-show="mode!=='thermal'" 
      @click="changeCameraMode('thermal')">
        <v-icon x-large color="#FFFFFF">mdi-thermometer-lines</v-icon>
    </v-btn>
    <v-btn fab outlined class="switch-button"
      :class="[{'switch-button__left' : mode==='maincam' }, {'switch-button__middle' : mode==='ptz'}, {'switch-button__right' : mode==='thermal'}]"
      v-show="mode!=='sound'"
      @click="changeCameraMode('sound')">
        <v-icon x-large color="#FFFFFF">mdi-microphone</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
  },
  data() {
    return {
      mode: 'maincam'
    }
  },
  methods: {
    changeCameraMode(cameraMode) {
      this.mode = cameraMode
      this.$nextTick(function() {
        this.$emit('changeCameraMode', {'cameraMode': this.mode})
      })
    }
  }
}
</script>
<style scoped>
.switch-button {
  width: 82px; 
  height: 82px; 
  border: 3px solid #FFFFFF;
  background: #FFFFFF33 0% 0% no-repeat padding-box; 
  opacity: 1;
}
.switch-button__left {
  position: absolute;
  right: 190px;
}
.switch-button__middle {
  position: absolute;
  right: 95px;
}
.switch-button__right {
  position: absolute;
  right: 0px;
}
</style>