<template>
<div class="robot-ctrl-button-group__outer-circle">
    <div class="robot-ctrl-button-group__outer-circle--top"> 
        <v-icon x-large class="robot-ctrl-button-group__move-button--left-forward" v-show="cameraMode==='maincam'" @mousedown.native="_frontLeft" @mouseup.native="_stop" @touchstart.native="_frontLeft" @touchend.native="_stop">$vuetify.icons.diagonalLeft</v-icon>
        <v-icon x-large class="robot-ctrl-button-group__move-button--forward" v-show="cameraMode==='maincam'" @mousedown.native="_front" @mouseup.native="_stop" @touchstart.native="_front" @touchend.native="_stop">$vuetify.icons.straight</v-icon>
        <v-icon x-large class="robot-ctrl-button-group__move-button--right-forward" v-show="cameraMode==='maincam'" @mousedown.native="_frontRight" @mouseup.native="_stop" @touchstart.native="_frontRight" @touchend.native="_stop">$vuetify.icons.diagonalRight</v-icon>
    </div>
    <div class="robot-ctrl-button-group__outer-circle--middle">
        <v-icon x-large class="robot-ctrl-button-group__move-button--left" @mousedown.native="_left" @mouseup.native="_stop" @touchstart.native="_left" @touchend.native="_stop">$vuetify.icons.turningLeft</v-icon>
        <div class="robot-ctrl-button-group__inner-circle">
            <v-icon x-large outlined class="robot-ctrl-button-group__reg-button" v-show="operatingMode==='create.route'&&cameraMode==='maincam'" @click="registerCurrentLocation">$vuetify.icons.addPoint</v-icon>
            <v-icon x-large outlined class="robot-ctrl-button-group__reg-button" color="#FFFFFF" v-show="operatingMode==='create.route'&&cameraMode==='thermal'" @click="registerThermal">mdi-thermometer-lines</v-icon>
            <v-icon x-large outlined class="robot-ctrl-button-group__reg-button" color="#FFFFFF" v-show="operatingMode==='create.route'&&cameraMode==='sound'" @click="registerSound">mdi-volume-high</v-icon>
        </div>
        <v-icon x-large class="robot-ctrl-button-group__move-button--right" @mousedown.native="_right" @mouseup.native="_stop" @touchstart.native="_right" @touchend.native="_stop">$vuetify.icons.turningRight</v-icon>
    </div>
    <div class="robot-ctrl-button-group__outer-circle--bottom">
        <v-icon x-large class="robot-ctrl-button-group__move-button--back" v-show="cameraMode==='maincam'" @mousedown.native="_rear" @mouseup.native="_stop" @touchstart.native="_rear" @touchend.native="_stop">$vuetify.icons.back</v-icon>
    </div>
</div>
</template>

<script>
import MqttCommand from '../../util/MqttCommand'
import RobotControlUtil from '../../util/robotcontrolutil'
import moveContext from '../../class/MoveContext'
import KeyInputManager  from '../../class/KeyInputManager'
import RobotControlCommandWorker from 'worker-loader!@/workers/RobotControlCommand.js'
import AWSUtil from '../../class/AWSUtil'

const DEF_INPUT_DEVICE = 'Key'

export default {
    components: { 
    },
    props: {
        cameraMode: { type: String },
    },
    data() {
        return {
            worker: null,
            camera: this.cameraMode,
            isPause: false,
            switchDrivingModeTopicRegExp: /ap-northeast-1_([0-9a-zA-Z]){9}\/([0-9a-z]){12,}\/usr_program\/check_plant\/reply/,
            switchDrivingModeTimeoutId: '',
            isSwitchedRemoteControlDriving: false,
            moveContext: null,
            crawler: {
                dutyScale: 0.4,
                dutyScaleTurn: 0.4,
            },
            joy: {
                axes: [
                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 
                ],
                buttons: [
                    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 
                ]
            },
            inputDevice: DEF_INPUT_DEVICE,
            keyInputManager: null,
            wheelTimerId: 0,
            lastKeyTime: 0,
            inputDeviceClearTimerId: 0,
            isWheelCommandDetected: {
                previous: false,
                current: false,
                offDetected: false,
                set: function(detected) {
                    this.previous = this.current; 
                    this.current = detected;
                    if (this.previous && !this.current) {
                        this.offDetected = true;
                    }
                },
                getOffDetected: function() {
                    let detected = this.offDetected;
                    this.offDetected = false;
                    return detected && !this.current;
                }
            },
            routeMeterRegisterationTopicRegExp: /ap-northeast-1_([0-9a-zA-Z]){9}\/([0-9a-z]){12,}\/usr_program\/route-check-subject-registration\/reply/,
            registeredWayPointList: [],
            registerCurrLocTimeoutId: '',
            isShowProgressDialog: false,
            progressMessage: ''
        }    
    },
    computed: {
        operatingMode() {
            return this.$store.state.selectedMode
        }
    },
    created() {
    },
    watch: {
        cameraMode (camera) {
            this.camera = camera
        }
    },
    methods: {
        initialize() {
            this.moveContext = new moveContext.moveContext(this.notifyMoveDirection)
            // キー入力
            this.keyInputManager = new KeyInputManager('default')
            this._setKeyInputManagerHandlers(this.keyInputManager)
            this.worker = new RobotControlCommandWorker('./RobotControlCommand.js')
            this.initializeWorker()
        },
        _frontLeft(event) {
            if (event.cancelable) {
                event.preventDefault()
            }
            this.moveContext.setInput('leftup')
        },
        _front(event) {
            if (event.cancelable) {
                event.preventDefault()
            }
            this.moveContext.setInput('up')
        },
        _frontRight(event) {
            if (event.cancelable) {
                event.preventDefault()
            }
            this.moveContext.setInput('rightup')
        },
        _left(event) {
            if (event.cancelable) {
                event.preventDefault()
            }
            this.moveContext.setInput('left')
        },
        _right(event) {
            if (event.cancelable) {
                event.preventDefault()
            }
            this.moveContext.setInput('right')
        },
        _rear(event) {
            if (event.cancelable) {
                event.preventDefault()
            }
            this.moveContext.setInput('down')
        },
        _stop(event) {
            if (event.cancelable) {
                event.preventDefault()
            }
            this._handleWheelInput('stop', false)
        },
        notifyMoveDirection(state, direction) {
            this.keyInputManager.setMoveState(state)
            if (state !== 'backReady' && state!== 'normalReady' && direction !== null) {
                this._handleWheelInput(direction)
            }
            else if (state === 'backReady' || state === 'normalReady') {
                this.$emit('moveDirection', { state: state, direction: direction })
            }
        },
        async initializeWorker() {
            let subscribeTopic = `${sessionStorage.getItem('userPoolId')}/${this.$store.state.selectedCrawler}/server/crawler-monitoring`
            AWSUtil.getAwsCredentialInfo()
            .then((val) => {
                const sendTopic = `${sessionStorage.getItem('userPoolId')}/${this.$store.state.selectedCrawler}/robot_system/crawler-control`
                const type = 'cmd.noreply'
                const name = 'crawler.move'
                const axes = [0,0,0,0,0,0,0,0,0,0]
                const awsCredentials = {
                    accessKeyId: val.accessKeyId,
                    secretAccessKey: val.secretAccessKey,
                    sessionToken: val.sessionToken
                }
                this.worker.postMessage({
                    type: 'initialize', 
                    serialNo: this.$store.state.selectedCrawler, 
                    credentials: awsCredentials, 
                    command: {
                        topic: sendTopic, 
                        type: type, 
                        name: name, 
                        axes: axes
                    }
                })
            })
        },
        publishWheel() {
            const sendTopic = `${sessionStorage.getItem('userPoolId')}/${this.$store.state.selectedCrawler}/robot_system/crawler-control`
            const type = 'cmd.noreply'
            const name = 'crawler.move'

            if (this.isPause) {
                this.joy = {
                    axes: [
                        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 
                        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 
                        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 
                    ],
                    buttons: [
                        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 
                        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 
                        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 
                    ]
                }
            }
            const scales = [this.crawler.dutyScaleTurn, this.crawler.dutyScale]
            const axes = this.joy.axes.map((v, idx) => v * scales[idx % 2])
            this.worker.postMessage({
                type: 'move', 
                command: {
                    topic: sendTopic, 
                    type: type, 
                    name: name, 
                    axes: axes
                }
            })
        },
        stopWheel() {
            const sendTopic = `${sessionStorage.getItem('userPoolId')}/${this.$store.state.selectedCrawler}/robot_system/crawler-control`
            const type = 'cmd.noreply'
            const name = 'crawler.move'

            const axes = [0,0,0,0,0,0,0,0,0,0]
            this.worker.postMessage({
                type: 'stop', 
                command: {
                    topic: sendTopic, 
                    type: type, 
                    name: name, 
                    axes: axes
                }
            })
        },
        changeWheel() {
            const sendTopic = `${sessionStorage.getItem('userPoolId')}/${this.$store.state.selectedCrawler}/robot_system/crawler-control`
            const type = 'cmd.noreply'
            const name = 'crawler.move'
            if (this.isPause) {
                this.joy = {
                    axes: [
                        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 
                        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 
                        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 
                    ],
                    buttons: [
                        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 
                        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 
                        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 
                    ]
                }
            }
            const scales = [this.crawler.dutyScaleTurn, this.crawler.dutyScale]
            const axes = this.joy.axes.map((v, idx) => v * scales[idx % 2])
            this.worker.postMessage({
                type: 'changeJoy', 
                command: {
                    topic: sendTopic, 
                    type: type, 
                    name: name, 
                    axes: axes
                }
            })
        },
        terminateWheel() {
            // const sendTopic = `${sessionStorage.getItem('userPoolId')}/${this.$store.state.selectedCrawler}/robot_system/crawler-control`
            // const type = 'cmd.noreply'
            // const name = 'crawler.move'

            // const axes = [0,0,0,0,0,0,0,0,0,0]
            // this.worker.postMessage({
                // type: 'terminate', 
                // command: {
                //     topic: sendTopic, 
                //     type: type, 
                //     name: name, 
                //     axes: axes
                // }
            // })
        },
        _setKeyInputManagerHandlers(keyInputManager) {
            keyInputManager.on('key', function(key, type) {
                if (type === 'keyup') {
                    this._handleWheelInput('stop', false)
                }
                else {
                    let wheelInputKey = ''
                    const wheelKeys = {}
                    switch(key) {
                        case 'left':
                        case 'right':
                        case 'up':
                        case 'down':
                            if (this.cameraMode === 'ptz') {
                                break
                            }
                            if ((this.cameraMode === 'thermal' || this.cameraMode === 'sound')  
                            && (key === 'up' || key === 'down')) {
                                break
                            }
                            wheelKeys['left'] = this.keyInputManager.isPushed('left')
                            wheelKeys['right'] = this.keyInputManager.isPushed('right')
                            wheelKeys['up'] = this.keyInputManager.isPushed('up')
                            wheelKeys['down'] = this.keyInputManager.isPushed('down')

                            wheelInputKey = RobotControlUtil.getCrossCursorKey(wheelKeys)

                            if (this.prevWheelInputKey !== '' && this.prevWheelInputKey !== wheelInputKey) {
                                this.prevWheelInputKey = wheelInputKey
                                this._handleWheelInput(wheelInputKey, true)
                            }
                            this.prevWheelInputKey = wheelInputKey
                            if (wheelInputKey === '') {
                                this._handleWheelInput(wheelInputKey, false)
                            }
                            else {
                                this.moveContext.setInput(wheelInputKey)
                            }
                            break
                    }
                }
            }.bind(this))
        },
        _handleWheelInput(key, isChangeWheelInput) {
            const joy = this._keyToJoy(key)

            if (this.inputDevice === '') {
                if (!RobotControlUtil.isBlankJoy(joy)) {
                this.inputDevice = 'Key'
                }
            }
      
            if(this.inputDevice === 'Key') {
                this.joy = joy
            }

            if (isChangeWheelInput) {
                this.changeWheel()
            }
            else {
                if (key === 'stop') {
                    this.prevWheelInputKey = ''
                    this.stopWheel()
                }
                else {
                    this.publishWheel()
                }
            }
        },
        _keyToJoy(key) {
            const joy = RobotControlUtil.zeroJoy()
            switch (key) {
                case 'leftup':
                    joy.axes[1] = 0.9
                    joy.axes[0] = 0.5
                    break
                case 'up':
                    joy.axes[1] = 1.0
                    break
                case 'rightup':
                    joy.axes[1] = 0.9
                    joy.axes[0] = -0.5
                    break
                case 'left':
                    joy.axes[0] = 1.0
                    break
                case 'right':
                    joy.axes[0] = -1.0
                    break
                case 'leftdown':
                    joy.axes[1] = -0.9
                    joy.axes[0] = 0.5
                    break
                case 'down':
                    joy.axes[1] = -1.0
                    break
                case 'rightdown':
                    joy.axes[1] = -0.9
                    joy.axes[0] = -0.5
                    break
            }   
            return joy
        },
        registerCurrentLocation() {
            this.$emit('registerCurrentLoc')
        },
        registerThermal() {
            this.$emit('registerThermal')
        },
        registerSound() {
            this.$emit('registerSound')
        },
        changeVideoMode() {
            this.$nextTick(function() {
                this.$emit('videoMode', { 'isPtz': true })
            })
        },
        async getRobotCtrl() {
            let recvTopic = `${sessionStorage.getItem('userPoolId')}/${this.$store.state.selectedCrawler}/robot_system/reply`
            let topicRegExp = /ap-northeast-1_([0-9a-zA-Z]){9}\/([0-9a-z]){12,}\/robot_system\/reply/

            MqttCommand.subscribe(recvTopic)

            let type = 'cmd'
            let name = 'switch.manual.drive.ctrl'
            let payload = {
                controller: 'aws.iot'
            }
            let packet = await MqttCommand.createPacket(type, name, payload)
            let packetId = packet['packet.id']

            let self = this
            MqttCommand.setSubscribeTopicHandler(topicRegExp, packetId, function(topic, data) { // eslint-disable-line
                if (data['replied.to'] === this) {
                    if (data.payload['controller'] === 'aws.iot') {
                        self.$emit('switchCtrlModeResponse', {'result': true})
                    }
                    else {
                        self.$emit('switchCtrlModeResponse', {'result': false})
                    }
                    MqttCommand.removeSubscribeTopicHandler(topicRegExp, packetId)
                    MqttCommand.unsubscribe(topic)      
                }
            }.bind(packetId))
            .then(()=>{
                let sendTopic = `${sessionStorage.getItem('userPoolId')}/${this.$store.state.selectedCrawler}/robot_system`
                MqttCommand.sendPacket(sendTopic, packet)
            })
        },
        setDutyScale(dutyScale) {
            this.crawler.dutyScale = dutyScale  
        },
        setDutyScaleTurn(dutyScaleTurn) {
            this.crawler.dutyScaleTurn = dutyScaleTurn
        },
        async pause(from) {
            this.isPause = true

            let recvTopic = `${sessionStorage.getItem('userPoolId')}/${this.$store.state.selectedCrawler}/usr_program/check_plant/reply`
            MqttCommand.subscribe(recvTopic)

            let type = 'cmd'
            let name = 'switch.driving.mode'
            let payload = {
                mode: 'remote.control'
            }
            let packet = await MqttCommand.createPacket(type, name, payload)
            let packetId = packet['packet.id']

            let self = this
            MqttCommand.setSubscribeTopicHandler(this.switchDrivingModeTopicRegExp, packetId, function(topic, data) { // eslint-disable-line
                if (data['replied.to'] === this) {
                    if (data.payload['has.succeeded']) {
                        self.$emit('pauseResponse', {'result': true , 'from': from})
                    }
                    else {
                        self.$emit('pauseResponse', {'result': false, 'from': from})
                    }
                    MqttCommand.removeSubscribeTopicHandler(this.switchDrivingModeTopicRegExp, packetId)
                    MqttCommand.unsubscribe(topic)      
                }
            }.bind(packetId))
            .then(()=>{
                let sendTopic = `${sessionStorage.getItem('userPoolId')}/${this.$store.state.selectedCrawler}/usr_program/check_plant`
                MqttCommand.sendPacket(sendTopic, packet)
            })
        },
        async resume(mode, from) {
            this.isPause = false
            let recvTopic = `${sessionStorage.getItem('userPoolId')}/${this.$store.state.selectedCrawler}/usr_program/check_plant/reply`
            MqttCommand.subscribe(recvTopic)

            let type = 'cmd'
            let name = 'switch.driving.mode'
            let payload = null
            if (mode === 'autonomous' || mode === 'skippoint.autonomous') {
                payload = {
                    mode: 'autonomous'
                }
            }
            else {
                payload = {
                    mode: 'remote.control'
                }
            }

            let packet = await MqttCommand.createPacket(type, name, payload)
            let packetId = packet['packet.id']
            let self = this
            MqttCommand.setSubscribeTopicHandler(this.switchDrivingModeTopicRegExp, packetId, function(topic, data) { // eslint-disable-line
                if (data['replied.to'] === this) {
                    if (data.payload['has.succeeded']) {
                        self.$emit('resumeResponse', {'result': true, 'from': `lift.${from}`, 'switch.mode.to': mode})
                    }
                    else {
                        self.$emit('resumeResponse', {'result': false, 'from': `lift.${from}`, 'switch.mode.to': mode})
                    }
                    clearTimeout(self.switchDrivingModeTimeoutId)
                    MqttCommand.removeSubscribeTopicHandler(self.switchDrivingModeTopicRegExp, packetId)
                    MqttCommand.unsubscribe(recvTopic)
                }
            }.bind(packetId))
            .then(()=>{
                let sendTopic = `${sessionStorage.getItem('userPoolId')}/${this.$store.state.selectedCrawler}/usr_program/check_plant`
                MqttCommand.sendPacket(sendTopic, packet)
                this.switchDrivingModeTimeoutId = setTimeout(function() {
                    this.$emit('resumeResponse', {'result': false, 'from': `lift.${from}`, 'switch.mode.to': mode})
                    clearTimeout(this.switchDrivingModeTimeoutId)
                    MqttCommand.removeSubscribeTopicHandler(this.switchDrivingModeTopicRegExp, packetId)
                    MqttCommand.unsubscribe(recvTopic)
                }.bind(this), 10000)
            })
        },
        async skipPointAndResume(mode, from) {
            let recvTopic = `${sessionStorage.getItem('userPoolId')}/${this.$store.state.selectedCrawler}/usr_program/check_plant/reply`
            let topicRegExp = /ap-northeast-1_([0-9a-zA-Z]){9}\/([0-9a-z]){12,}\/usr_program\/check_plant\/reply/
            MqttCommand.subscribe(recvTopic)

            let type = 'cmd'
            let name = 'skip.waypoint'
            let payload = {}

            let packet = await MqttCommand.createPacket(type, name, payload)
            let packetId = packet['packet.id']
            let skipAndResumeTimeoutId = null
            let self = this
            MqttCommand.setSubscribeTopicHandler(topicRegExp, packetId, function(topic, data) { // eslint-disable-line
            if (data['replied.to'] === this) {
                if (data.payload['has.succeeded']) {
                    self.$emit('resumeResponse', {'result': true, 'from': `lift.${from}`, 'switch.mode.to': mode})
                }
                else {
                    self.$emit('resumeResponse', {'result': false, 'from': `lift.${from}`, 'switch.mode.to': mode})
                }
                clearTimeout(skipAndResumeTimeoutId)
                MqttCommand.removeSubscribeTopicHandler(topicRegExp, packetId)
            }
            }.bind(packetId))
            .then(()=>{
                let sendTopic = `${sessionStorage.getItem('userPoolId')}/${this.$store.state.selectedCrawler}/usr_program/check_plant`
                MqttCommand.sendPacket(sendTopic, packet)
                skipAndResumeTimeoutId = setTimeout(function() {
                    this.$emit('resumeResponse', {'result': false, 'from': `lift.${from}`, 'switch.mode.to': mode})
                    clearTimeout(skipAndResumeTimeoutId)
                    MqttCommand.removeSubscribeTopicHandler(topicRegExp, packetId)
                    MqttCommand.unsubscribe(recvTopic)
                }.bind(this), 10000)
            })
        },
        async resetError() {
            let recvTopic = `${sessionStorage.getItem('userPoolId')}/${this.$store.state.selectedCrawler}/robot_system/crawler-error-reset/reply`
            let topicRegExp = /ap-northeast-1_([0-9a-zA-Z]){9}\/([0-9a-z]){12,}\/robot_system\/crawler-error-reset\/reply/
            MqttCommand.subscribe(recvTopic)

            let type = 'cmd'
            let name = 'error.reset'
            let payload = {}

            let packet = await MqttCommand.createPacket(type, name, payload)
            let packetId = packet['packet.id']
            let resetErrorTimeoutId = null
            let self = this
            MqttCommand.setSubscribeTopicHandler(topicRegExp, packetId, function(topic, data) { // eslint-disable-line
            if (data['replied.to'] === this) {
                if (data.payload['has.succeeded']) {
                    self.$emit('resetErrorResponse', {'result': true})
                }
                else {
                    self.$emit('resetErrorResponse', {'result': false})
                }
                clearTimeout(resetErrorTimeoutId)
                MqttCommand.removeSubscribeTopicHandler(topicRegExp, packetId)
            }
            }.bind(packetId))
            .then(()=>{
                let sendTopic = `${sessionStorage.getItem('userPoolId')}/${this.$store.state.selectedCrawler}/robot_system/crawler-error-reset`
                MqttCommand.sendPacket(sendTopic, packet)
                resetErrorTimeoutId = setTimeout(function() {
                    this.$emit('resetErrorResponse', {'result': false})
                    clearTimeout(resetErrorTimeoutId)
                    MqttCommand.removeSubscribeTopicHandler(topicRegExp, packetId)
                    MqttCommand.unsubscribe(recvTopic)
                }.bind(this), 10000)
            })
        },
        destroy() {
            if (this.worker) {
                this.terminateWheel()
                this.worker.terminate()
            }
            if (this.keyInputManager != null) {
                this.keyInputManager.destroy()
                this.keyInputManager = null
                this.moveContext = null
            }
        }
    }
}

</script>

<style scoped>
.robot-ctrl-button-group__inner-circle {
    position: absolute;
    width: 100px;
    height: 100px;
    left: 70px;
    top: -3px;
    background: #FFFFFF4D 0% 0% no-repeat padding-box;
    border: 5px solid #FFFFFF;
    border-radius: 50%;
    opacity: 1;
}
.robot-ctrl-button-group__move-button--back {
    left: 100px;
    top: 20px;
}
.robot-ctrl-button-group__move-button--forward {
    left: 60px;
    top: 5px;
}
.robot-ctrl-button-group__move-button--left {
    left: 15px;
    top: 35px;
}
.robot-ctrl-button-group__move-button--left-forward {
    left: 34px;
    top: 42px;
}
.robot-ctrl-button-group__move-button--right {
    left: 145px;
    top: 35px;
}
.robot-ctrl-button-group__move-button--right-forward {
    left: 88px;
    top: 42px;
}
.robot-ctrl-button-group__move-button--top {
    left: 15px;
    top: 35px;
}
.robot-ctrl-button-group__outer-circle {
    position: relative;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    border: 5px solid #FFFFFF;
    background: #FFFFFF33 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
}
.robot-ctrl-button-group__outer-circle--bottom {
    position:absolute;
    left: 0;
    top: 70%;
    width: 100%;
    height: 30%;
}
.robot-ctrl-button-group__outer-circle--middle {
    position:absolute;
    left: 0;
    top: 30%;
    width: 100%;
    height: 40%;
}
.robot-ctrl-button-group__outer-circle--top {
    position:absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 30%;
}
.robot-ctrl-button-group__reg-button {
    left:25px; 
    top:23px;
}
.robot-ctrl-button-group__switch-mode-button {
    width: 82px; 
    height: 82px; 
    border: 3px solid #FFFFFF;
    background: #FFFFFF33 0% 0% no-repeat padding-box; 
    opacity: 1;
}
</style>