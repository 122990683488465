<template>
  <div>
    <v-dialog persistent max-width="700px" v-model="c_shutdownConfirmDialogVisible">
        <template v-slot:activator="{c_shutdownConfirmDialogVisible}"></template> <!-- eslint-disable-line -->
        <v-card class="dialog">
          <v-toolbar flat dark class="dialog__title" color="#FF0000" height="71">
              <v-icon large class="mr-2">$vuetify.icons.infoWarning</v-icon>シャットダウンボタンが押されました。
          </v-toolbar>
          <v-card-text class="dialog__message mt-10">シャットダウンしますか？
          </v-card-text>
          <v-container fluid>
            <v-row>
              <v-col>
                <v-card-text class="dialog__item">{{crawlerName != null ? crawlerName : crawlerId}}</v-card-text>
              </v-col>
              <v-col>
                <v-text-field class="dialog__text" label="開始遅延時間" v-model="delayTime" suffix="秒" type="number" :rules="[value => !!value || '数値を入力してください']"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
              <v-layout justify-center class="mb-3">
                <v-btn outlined rounded dark class="dialog__button mx-3" color="#707070" @click="cancel">
                  キャンセル
                </v-btn>
                <v-btn rounded dark class="dialog__button mx-3" color="#0099FF" @click="confirmReboot">
                  <v-icon>mdi-restart</v-icon>
                  再起動
                </v-btn>
                <v-btn rounded dark class="dialog__button mx-3" color="#0099FF" @click="confirmShutdown">
                  <v-icon>mdi-power</v-icon>
                  シャットダウン
                </v-btn>
              </v-layout>
          </v-card-actions>
        </v-card>
    </v-dialog>
    <confirm-dialog :confirm-visible="confirmVisible" :confirmMessage="confirmMessage" :info-visible="confirmInfoVisible" :info-type="confirmInfoType" :info-message="confirmInfoMessage" @confirm="confirmDialogOK" @cancel="confirmDialogCancel"></confirm-dialog>
  </div>
</template>
  
<script>
import ConfirmDialog from './ConfirmDialog.vue';

export default {
    components: {
      ConfirmDialog
    },
    props: {
        shutdownConfirmDialogVisible: { type: Boolean },        
        crawlerId: { type: String },
        crawlerName: { type: String },
    },
    beforeDestroy() {
    },
    data() {
      return {
          reboot: false,
          delayTime: 5,
          confirmVisible: false,
          confirmMessage: '',
          confirmInfoVisible: false,
          confirmInfoType: 'normal',
          confirmInfoMessage: ''
      }
    },
    computed: {
        c_shutdownConfirmDialogVisible: {
            get() {
                return this.shutdownConfirmDialogVisible
            }
        },
    },
    methods: {
      confirmReboot() {
        this.reboot = true;
        this.confirmVisible = true;
        this.confirmMessage = "本当に再起動しますか？"
      },
      confirmShutdown() {
        this.reboot = false;
        this.confirmVisible = true;
        this.confirmMessage = "本当にシャットダウンしますか？"
      },

      cancel() {
        this.$emit('cancelCheckFinish')
      },
      confirmDialogOK() {
        this.confirmVisible = false;
        this.$emit('confirmShutdownCheckFinish', { 'reboot': this.reboot, 'delayTime': this.delayTime })
      },
      confirmDialogCancel() {
        this.confirmVisible = false;
      },
    }
}
</script>

<style scoped>
.dialog {
  max-width: 700px;
  min-height: 216px;
}
.dialog__button {
  min-width: 202px !important;
  height: 54px !important;
  box-shadow: 0px 3px 6px #00000029;
  border: 2px solid;
  border-radius: 26px;
  opacity: 1;
  text-align: left;
  font: normal normal bold 20px/24px Segoe UI !important;
  letter-spacing: 0px;
  opacity: 1;
}
.dialog__message {
  text-align: left;
  font: normal normal normal 24px/32px Segoe UI;
  letter-spacing: 0px;
  color: #000000 !important;
  opacity: 1;
}
.dialog__item {
  text-align: right;
  font: normal normal 24px/32px Segoe UI;
  letter-spacing: 0px;
  color: #000000 !important;
  opacity: 1;
  padding: 1px !important;
  margin-top: 0px !important;
}
.dialog__text {
  width: 20%;
  min-width: 100px;
  text-align: right;
  font: normal normal 24px/32px Segoe UI;
  letter-spacing: 0px;
  color: #000000 !important;
  opacity: 1;
  padding: 1px !important;
  margin-top: 0px !important;
}
.dialog__title {
  text-align: left;
  font: normal normal bold 24px/32px Segoe UI;
  letter-spacing: 0px;
  opacity: 1;
}
</style>