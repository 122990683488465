// RobotAliveMonitoringCommand.js

'use strict'

import MqttCommand from '../util/MqttCommand'

class RobotAliveMonitoringCommand {
    id = ''
    monitoringRobotList = []
    monitoringRobot = null
    callbackList = []
    topicRegExp = /ap-northeast-1_([0-9a-zA-Z]){9}\/([0-9a-z]){12,}\/server\/crawler-monitoring/
    robotsMonitoringTimerId = ''
    robotMonitoringTimerId = ''
    
    constructor() {
    }

    startMonitoringAllRobots(robotList) {
        let userPoolId = sessionStorage.getItem('userPoolId')
        this.id = userPoolId
        this.monitoringRobotList = robotList
        MqttCommand.setSubscribeTopicHandler(this.topicRegExp, null, this.updateStatus.bind(this))
        .then(()=>{
            for (let robot of this.monitoringRobotList) {
                let recvTopic = `${userPoolId}/${robot.robotStatus.serialNo}/server/crawler-monitoring`
                MqttCommand.subscribe(recvTopic)
                .then(()=>{
                    this.sendGetCurrentStatus(robot.robotStatus.serialNo, 1000)
                })
            }
            this.robotsMonitoringTimerId = setInterval(function() {
                for (let robot of this.monitoringRobotList) {
                    robot.updateDisplayStatus()
                }
            }.bind(this), 30000)
        })
    }

    startMonitoringRobot(robot) {
        const userPoolId = sessionStorage.getItem('userPoolId')
        this.id = userPoolId
        this.monitoringRobot = robot
        this.monitoringRobotList = []
        MqttCommand.setSubscribeTopicHandler(this.topicRegExp, null, this.updateStatus.bind(this))
        .then(()=>{
            let recvTopic = `${userPoolId}/${robot.robotStatus.serialNo}/server/crawler-monitoring`
            MqttCommand.subscribe(recvTopic)
            .then(()=>{
                this.sendGetCurrentStatus(robot.robotStatus.serialNo, 1000)
            })
            this.robotMonitoringTimerId = setInterval(function() {
                this.monitoringRobot.updateDisplayStatus()
            }.bind(this), 30000)
        })
    }
    
    sendGetCurrentStatus(serialNo, interval) {
        let sendTopic = `${sessionStorage.getItem('userPoolId')}/${serialNo}/robot_system`
        let name = 'get.curr.status'
        let type = 'cmd.noreply'
        let payload = {}
        if (interval != null) {
            payload = {
                'interval.ms': interval
            }
        }
        else {
            payload = {}
        }
        MqttCommand.publish(sendTopic, type, name, payload)
    }

    stopMonitoring(multi) {
        return new Promise((resolve) => {
            if (multi) {
                clearInterval(this.robotsMonitoringTimerId)
                for (let robot of this.monitoringRobotList) {
                    this.unsubscribeRobot(robot)
                }
            }
            else {
                clearInterval(this.robotMonitoringTimerId)
                this.unsubscribeRobot(this.monitoringRobot)
            }
            MqttCommand.removeSubscribeTopicHandler(this.topicRegExp, null)
            resolve()
        })
    }
    
    unsubscribeRobot(robot) {
        return new Promise((resolve) => {
            let recvTopic = `${this.id}/${robot.robotStatus.serialNo}/server/crawler-monitoring`
            MqttCommand.unsubscribe(recvTopic)
            resolve()
        })
    }

    setRobotMonitoringCallback(callback) {
        this.callbackList.push(callback)
    }

    deleteRobotMonitoringCallback(callback) {
        for (let i = 0; i < this.callbackList.length; i++) {
            if (this.callbackList[i] === callback) {
                this.callbackList.splice(i, 1)
                break
            }
        }
    }

    deleteRobotErrorsWarningCallback() {
        this.fatalErrorsCallback = null
        this.recoverableErrorsCallback = null
        this.warningsCallback = null
    }

    updateStatus(topic, data) {
        let tmpSerialNo = topic.substr(topic.indexOf('/') + 1)
        let serialNo = tmpSerialNo.substr(0, tmpSerialNo.indexOf('/'))
        if (this.monitoringRobotList.length === 0) {
            if (this.monitoringRobot != null) {
                this.monitoringRobot.updateRobot(data.payload)
                for (let callback of this.callbackList) {
                    callback(this.monitoringRobot)
                }
            }
        }
        else {
            for (let robot of this.monitoringRobotList) {
                if (robot.robotStatus.serialNo === serialNo) {
                    robot.updateRobot(data.payload)

                    for (let callback of this.callbackList) {
                        callback(robot)
                    }
                    break;
                }
            }
        }
    }
}

export default new RobotAliveMonitoringCommand();