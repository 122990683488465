// Copyright(c) 2024 RICOH Company, Ltd. All rights reserved.

import BackReadyState from './BackReadyState'

class NormalState {
    constructor() {}

    doInput(moveContext, input) {
        if (input === 'down') {
            moveContext.changeState(BackReadyState)
        }
        else {
            this.doAction(moveContext, input)
        }
    }

    doAction(moveContext, input) {
        moveContext.notify('normal', input)
    }
}

const normalState = new NormalState()
Object.freeze(normalState)
export default normalState