<template>
    <div>
        <div class="round--normal" v-show="status!=='error'">
            <p>{{no}}</p>
        </div>
         <div class="round--error" v-show="status==='error'">
            <p>{{no}}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        no: { type: Number },
        status: { type: String }
    }
}
</script>

<style scoped>
.round--normal {
    width:36px;
    height:36px;
    border-radius: 50%;
    background-color: #009999;
    color:#FFFFFF;
    position: relative;
}
.round--error {
    width:36px;
    height:36px;
    border-radius: 50%;
    background-color: #FF0000;
    color:#FFFFFF;
    position: relative;
}
p {
    font-weight: bold;
    margin: 0;
    position: absolute;
    top: 45%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}
</style>