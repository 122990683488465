import { render, staticRenderFns } from "./LampSwitchSettings.vue?vue&type=template&id=038aa781&scoped=true&"
import script from "./LampSwitchSettings.vue?vue&type=script&lang=js&"
export * from "./LampSwitchSettings.vue?vue&type=script&lang=js&"
import style0 from "./LampSwitchSettings.vue?vue&type=style&index=0&id=038aa781&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "038aa781",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCardText,VCheckbox,VContainer,VForm,VLayout,VResponsive,VRow,VTextField})
