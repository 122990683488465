'use strict'

import AWSUtil from './AWSUtil'

class Routes {
  routeList = []
  intervalId = null
  lastUpdate = new Date(0).getTime()

  constructor() {
  }

  async createRouteList() {
    // RouteListを初期化する
    this.routeList.splice(0)
    const axios = require('axios')
    const url = `https://api.dev-crawlerrobo.trial.ricoh/route?isUseIndex=${true}`
    
    // ヘッダーの署名を取得する
    let signedHeaderInfo = await AWSUtil.createSigV4(url, 'get')
    
    let self = this
    // 認可情報と署名したヘッダーを利用してAPIをコールする
    return axios.get(url, {
      headers: {
        'Authorization': signedHeaderInfo.Authorization,
        'X-Host-Override': signedHeaderInfo.Host,
        'X-Amz-Date': signedHeaderInfo['X-Amz-Date'],
        'X-Amz-Security-Token': signedHeaderInfo['x-amz-security-token'],
      },
    })
    .then((res) => {
      let routes = JSON.parse(JSON.stringify(res.data))
      if (routes !== '') {
        for (let route of routes) {
          if (self.routeList.find( item => item.routeId === route['route_id']) == null) {
            self.routeList.push({
              routeId: route['route_id'],
              routeName: route['route_name'],
              registrationTime: route['registration_time'] != null ? new Date(Date.parse(route['registration_time'])).toLocaleString() : null,
              distanceM: route['distance_m'] != null ? route['distance_m'] : null,
              combinedRoutes: route['combined_routes'] != null ? route['combined_routes'] :  null,
              checkingRobot: null,
              status: '点検実施予定はありません',
            })
          }
        }

        // 初回のルート一覧取得時から2秒毎にルート一覧の更新要否をチェックする
        if (self.intervalId === null) {
          self.intervalId = setInterval(function() {
            self.updateRouteListIfNeeded()
          }, 2000)
        }

        // ルート一覧取得日時を記録する
        self.lastUpdate = new Date().getTime()
      }
    })
    .catch((err) => {
      self.routeList.splice(0)
    })
  }

  async getRouteGpx(routeId) {
    const axios = require('axios')
    const url = `https://api.dev-crawlerrobo.trial.ricoh/route/gpx`

    // ヘッダーの署名を取得する
    let signedHeaderInfo = await AWSUtil.createSigV4(url, 'get')
    
    // 認可情報と署名したヘッダーを利用してAPIをコールする
    return axios.get(url, {
      headers: {
        'Authorization': signedHeaderInfo.Authorization,
        'X-Host-Override': signedHeaderInfo.Host,
        'X-Amz-Date': signedHeaderInfo['X-Amz-Date'],
        'X-Amz-Security-Token': signedHeaderInfo['x-amz-security-token'],
        'Route-ID': routeId
      },
    })
    .then((res) => res.data)
  }

  async updateRouteListIfNeeded() {
    // 最後にルート一覧を更新したタイミングから5分以上更新がない場合、再度RouteListを取得する
    if (new Date().getTime() - this.lastUpdate > 300000 || this.routeList.length === 0) {
      return this.createRouteList()
    }
    else {
      return new Promise((resolve) => {
        resolve()
      })
    }
  }

  getRouteList() {
    let self = this
    return new Promise((resolve)=> {
      self.updateRouteListIfNeeded()
      .then(()=> {
        resolve(self.routeList)
      })
    })
  }

  async getRoute(routeId) {
      return (await this.getRouteList()).find(val => val.routeId === routeId)
  }

  async deleteRoute(routeId) {
    const axios = require('axios')
    const url = `https://api.dev-crawlerrobo.trial.ricoh/route/${routeId}`
    
    let self = this
    // ヘッダーの署名を取得する
    let signedHeaderInfo = await AWSUtil.createSigV4(url, 'delete')
    
    // 認可情報と署名したヘッダーを利用してAPIをコールする
    return axios.delete(url, {
        headers: {
            'Authorization': signedHeaderInfo.Authorization,
            'X-Host-Override': signedHeaderInfo.Host,
            'X-Amz-Date': signedHeaderInfo['X-Amz-Date'],
            'X-Amz-Security-Token': signedHeaderInfo['x-amz-security-token'],
        },
    })
    .then(() => {
      self.lastUpdate = new Date(0).getTime()
    })
  }

  /**
   * 連結ルートを登録する
   * @param {String} routeName 登録される連結ルートのルート名
   * @param {Array} combinedRoutes 連結されるルートのルートIDを格納した配列
   * @returns 登録成功したらメッセージ"success register route."
   */
  async registerCombinedRoute(routeName, combinedRoutes) {
    const axios = require('axios')
    const url = "https://api.dev-crawlerrobo.trial.ricoh/route"

    let body = {
      'Route-Name': routeName,
      'Combined-Routes': combinedRoutes
    }

    // ヘッダーの署名を取得する
    let signedHeaderInfo = await AWSUtil.createSigV4(url, 'post', body)

    //認可情報と署名したヘッダーを利用してAPIをコールする
    return axios.post(url, JSON.stringify(body), {
      headers: {
        'Authorization': signedHeaderInfo.Authorization,
        'X-Host-Override': signedHeaderInfo.Host,
        'X-Amz-Date': signedHeaderInfo['X-Amz-Date'],
        'X-Amz-Security-Token': signedHeaderInfo['x-amz-security-token'],
      },
    })
    .then((res) => res.data)

  }  
}

export default new Routes()