<template>
   <div ref="add_point"></div>
</template>

<script>
import AddPoint from "!!raw-loader!../../assets/ricoh_assets/icons/ICN004-01_add_point.svg" // loads SVG Icons as String via Webpack.
export default {
    mounted() {
        let shadowRoot = this.$refs.add_point.attachShadow({mode: 'open'})
        shadowRoot.innerHTML = AddPoint

        const style = document.createElement('style')
        style.innerHTML = `svg { width: 100%; height: 100%; }`

        const clonedStyle = style.cloneNode(true)
        shadowRoot.appendChild(clonedStyle)
    }
}
</script>

<style>
</style>