<template>
  <div class="outer">
      <maincam-view ref="maincam_view" class="maincam-view" 
        @addMaincam="addMaincam($event)"
        @destroyMaincam="destroyMaincam"
        @resizeMaincam="resizeMaincam">
      </maincam-view>

      <crawler-controller ref="robot_ctrl" class="controller-area" 
        v-show="isAbleToCrawlerCtrl" 
        :cameraMode="cameraMode"
        @moveDirection="ctrlBackNotification($event)"
        @registerCurrentLoc="registerCurrentLoc"
        @pauseResponse="pauseResponse($event)"
        @resumeResponse="resumeResponse($event)"
        @resetErrorResponse="resetErrorResponse($event)"
        @switchCtrlModeResponse="switchCtrlModeResponse($event)">
      </crawler-controller>
    </div>
</template>

<script>
import MaincamView from "../parts/Maincam"
import CrawlerController from "../parts/CrawlerController"
export default {
  props: {
    drivingMode: { type: String },
    cameraMode: { type: String }
  },
  components: {
    MaincamView,
    CrawlerController,
  },
  data() {
    return {
      operatingMode: this.$store.state.selectedMode,
      mode: this.drivingMode,
      connectionId: null,
      isAbleToCrawlerCtrl: false,
    }
  },
  watch: {
    drivingMode (mode) {
      this.mode = mode
    },
    cameraMode (mode) {
      if (mode === 'maincam') {
        this.$refs.maincam_view.resizeMaincam()
      }
      this.cameraMode = mode
    }
  },
  computed: {
  },
  beforeDestroy() {
  },
  mounted() {
    if (this.$store.state.selectedMode === 'create.route') {
      this.$refs.robot_ctrl.initialize()
    }
  },
  methods: {
    setLiveStreamingEvent(liveStreaming) {
      this.$refs.maincam_view.setLiveStreamEventCallback(liveStreaming)
    },
    addMaincam(event) {
      this.connectionId = event.connectionId
      this.$emit('addMaincam', { connectionId : this.connectionId })
    },
    destroyMaincam() {
      this.displayBackNotification('hide')
      this.$emit('destroyMaincam')
    },
    displayBackNotification(toggle) {
      this.$emit('displayBackNotification', { toggle: toggle })
    },
    ctrlBackNotification(event) {
      let toggle = 'hide'
      switch (event.state) {
        case 'backReady':
          // バックの注意表示を表示する
          toggle = 'show'
          break
        case 'normalReady':
          // バックの注意表示を非表示にする
          toggle = 'hide'
          break
        default:
          // nothing
          break
      }
      this.displayBackNotification(toggle)
    },
    resizeMaincam() {
      this.$emit('resizeMaincam')
    },
    registerCurrentLoc() {
      this.$emit('registerCurrentLoc')
    },
    disableCrawlerCtrl() {
      this.isAbleToCrawlerCtrl = false
      this.$refs.robot_ctrl.destroy()
      this.displayBackNotification('hide')
    },
    enableCrawlerCtrl() {
      this.$refs.robot_ctrl.destroy()
      this.isAbleToCrawlerCtrl = true
      this.$refs.robot_ctrl.initialize()
    },
    pause(from) {
      this.$refs.robot_ctrl.pause(from)
    },
    pauseResponse(event) {
      this.$emit('pauseResponse', event)
    },
    resume(mode, from) {
      this.displayBackNotification('hide')
      if (mode === 'skippoint.autonomous') {
        this.$refs.robot_ctrl.skipPointAndResume(mode, from)
      }
      else {
        this.$refs.robot_ctrl.resume(mode, from)
      }
    },
    resumeResponse(event) {
      this.$emit('resumeResponse', event)
    },
    resetError() {
      this.$refs.robot_ctrl.resetError()
    },
    resetErrorResponse(event) {
      this.$emit('resetErrorResponse', event)
    },
    reconnect() {
      this.$refs.maincam_view.reconnect()
    },
    setRobotParameter(linearVelocity, slewingSpeed) {
      this.$refs.robot_ctrl.setDutyScale(linearVelocity)
      this.$refs.robot_ctrl.setDutyScaleTurn(slewingSpeed)
    },
    sendGetCtrlCmd() {
      this.$refs.robot_ctrl.getRobotCtrl()
    },
    switchCtrlModeResponse(event) {
      this.$emit('switchCtrlModeResponse', event)
    }
  }
}
</script>

<style scoped>
.controller-area {
  position: absolute;
  bottom: 9%;
  right: 5px;
}
.outer {
  width: inherit;
  height: inherit;
}
.maincam-view {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #000000;
}
</style>