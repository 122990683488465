<template>
  <div id="crawler-driving" class="crawler-driving">
    <camera-and-ctrl class="camera-and-ctrl" ref="camera_and_ctrl" 
      :drivingMode="drivingMode"
      :cameraMode="cameraMode"
      @displayBackNotification="displayBackNotification($event)"
      @registerCurrentLoc="registerCurrentLoc"
      @registerCheckPoint="registerCheckPoint($event)"
      @pauseResponse="pauseResponse($event)"
      @resumeResponse="resumeResponse($event)"
      @resetErrorResponse="resetErrorResponse($event)"
      @switchCtrlModeResponse="switchCtrlModeResponse($event)">
    </camera-and-ctrl>
    
    <div id="notification-area" 
      :class="operatingMode === 'create.route' || (operatingMode === 'check.plant' && drivingMode === 'remote.ctrl') ? 'notification-area' : 'notification-area--under-stop-button'">
      <communication-disruption-card v-show="isCommunicationDisruption"></communication-disruption-card>
      <notification-card v-show="isNotifying" :iconType="notifyDialogIconType" :dialogMessage="notifyDialogMessage"></notification-card>
    </div>

    <progress-dialog class="forefront" 
      :progress-visible="isShowProgressDialog" :progress-message="progressMessage" 
      :info-visible=null :info-type=null :info-message=null>
    </progress-dialog>
    
    <check-finish-from-crawler-confirm-dialog class="forefront" 
      :check-finish-from-crawler-confirm-dialog-visible="isCrawlerFinishDriving"
      @finishFromCrawler="returnToHome">
    </check-finish-from-crawler-confirm-dialog>
    
    <error-and-back-home-dialog class="forefront" 
      :error-and-back-home-dialog-visible="isShowErrorAndBackHomeDialog" :error-message="errorAndBackHomeMessage" 
      @returnToHome="forcedReturnToHome" @ok="cancelReturnToHome">
    </error-and-back-home-dialog>

    <recoverable-error-dialog class="forefront" 
      :recoverable-error-dialog-visible="hasRecoverableError" 
      :recoverable-errors="recoverableErrors" 
      @resetError="resetError">
    </recoverable-error-dialog>
    
    <crawler-driving-map-template ref="map_template" 
      :displayStatus="robot !== null ? robot.displayStatus : '状態不明'" 
      :precision="robot !== null ? robot.robotStatus.location.precision : null"
      :controller="robot !== null ? robot.robotStatus.controller :  null"
      :drivingMode="drivingMode"
      @reconnect='reconnect'
      @sendGetCtrlCmd='sendGetCtrlCmd'>
    </crawler-driving-map-template>
    
    <camera-mode-switch ref="camera_mode_switch" class="camera-mode-switch"
      @changeCameraMode="changeCameraMode($event)">
    </camera-mode-switch>
    
    <route-create-template ref="route_create" class="route-create-template" v-show="operatingMode==='create.route'" 
      :cameraMode="cameraMode"
      @addDialog="addDialog($event)"
      @removeDialog="removeDialog($event)"
      @returnToHome="returnToHome">
    </route-create-template>
    <check-template ref="check" class="check-template" v-show="operatingMode==='check.plant'" 
      :drivingMode="drivingMode" :cameraMode="cameraMode"
      :isPause="isPause" :isEnteringLeavingStation="robot !== null ? robot.robotStatus.isEnteringLeavingStation : null"
      @pause="pause"
      @resume="resume($event)"
      @cancelFinishCheck="cancelFinishCheck"
      @finishCheck="finishCheck"
      @addDialog="addDialog($event)"
      @removeDialog="removeDialog($event)"
      @returnToHome="returnToHome">
    </check-template>

    <v-container class="bottom">
      <v-row>
        <v-col cols="3" class="bottom__col--ver-size">
          <p class="bottom__license-display" v-html="leafletLicense"></p>
        </v-col>
        <v-col cols="4" class="bottom__col--ver-size"></v-col>
        <v-col cols="5" class="bottom__col--ver-size">
          <v-layout>
            <div class="bottom__ctrl-mode-text">{{drivingModeString}}</div>
            <crawler-speed-controller 
              :drivingMode="drivingMode" 
              @setRobotParameter="setRobotParameter($event)">
            </crawler-speed-controller>
          </v-layout>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import CameraAndCtrl from '../components/views/CameraAndCtrl.vue'
import ProgressDialog from '../components/ProgressDialog.vue'
import CheckFinishFromCrawlerConfirmDialog from '../components/CheckFinishFromCrawlerConfirmDialog.vue'
import ErrorAndBackHomeDialog from '../components/ErrorAndBackHomeDialog.vue'
import RecoverableErrorDialog from '../components/RecoverableErrorDialog.vue'

import ErrorWarningCard from '../components/ErrorWarningCard.vue'
import NotificationCard from '../components/NotificationCard.vue'
import CommunicationDisruptionCard from '../components/CommunicationDisruptionCard.vue'
import CrawlerDrivingMapTemplate from '../components/templates/CrawlerDrivingMapTemplate.vue'
import CameraModeSwitch from '../components/parts/CameraModeSwitch.vue'
import RouteCreateTemplate from '../components/templates/RouteCreateTemplate.vue'
import CheckTemplate from '../components/templates/CheckTemplate.vue'
import CrawlerSpeedController from '../components/parts/CrawlerSpeedController.vue'

import Vue from 'vue'
import Robots from '../class/Robots'
import MqttCommand from '../util/MqttCommand'
import RobotAliveMonitoringCommand from '../util/RobotAliveMonitoringCommand'
import { BeepSound } from '../class/BeepSound'
export default {
  components: {
    CameraAndCtrl,
    ProgressDialog,
    CheckFinishFromCrawlerConfirmDialog,
    ErrorAndBackHomeDialog,
    RecoverableErrorDialog,
    NotificationCard,
    CommunicationDisruptionCard,
    CrawlerDrivingMapTemplate,
    CameraModeSwitch,
    RouteCreateTemplate,
    CheckTemplate,
    CrawlerSpeedController,
  },
  props: {
    serial_no: String,
    route_id: String,
    operating_mode: String
  },
  computed: {
    operatingMode: {
      get() {
        if (this.operating_mode != null) {
          return this.operating_mode
        }
        else {
          return this.$store.state.selectedMode
        }
      },
    },
    drivingMode() {
      if (this.robot != null && this.robot.robotStatus.application != null && this.robot.robotStatus.application.drivingMode != null) {
        return this.robot.robotStatus.application.drivingMode
      }
      else {
        return 'remote.ctrl'
      }
    },
    controller() {
      if (this.robot != null) {
        return this.robot.robotStatus.controller
      }
      else {
        return 'joycon'
      }
    },
    drivingModeString: {
      cache: false,
      get() {
        if (this.robot !== null && this.robot.robotStatus.isEnteringLeavingStation) {
          return '充電動作中'
        } else if (this.operatingMode === 'create.route') {
          return '遠隔操作中'
        }
        else {
          if (this.drivingMode === 'remote.ctrl') {
            return '遠隔操作中'
          }
          else if (this.drivingMode === 'autonomous') {
            return '自律走行中'
          }
          else {
            return '遠隔操作中'
          }
        }
      },
    }
  },
  watch: {
    operatingMode() {
      this.changeEnableAndDisableCrwalerCtrl()
    },
    drivingMode() {
      this.changeEnableAndDisableCrwalerCtrl()
    },
    cameraMode() {
      this.changeEnableAndDisableCrwalerCtrl()
    },
    dialogs() {
      this.changeEnableAndDisableCrwalerCtrl()
    },
    controller() {
      this.changeEnableAndDisableCrwalerCtrl()
    },
    isCrawlerFinishDriving(status) {
      if (status) {
        this.addDialog({ dialog_name: 'crawlerFinishDrivingDialog' })
      }
      else {
        this.removeDialog({ dialog_name: 'crawlerFinishDrivingDialog' })
      }
    },
    isShowErrorAndBackHomeDialog(status) {
      if (status) {
        this.addDialog({ dialog_name: 'errorAndBackHomeDialog' })
      }
      else {
        this.removeDialog({ dialog_name: 'errorAndBackHomeDialog' })
      }
    },
    isShowProgressDialog(status) {
      if (status) {
        this.addDialog({ dialog_name: 'progressDialog' })
      }
      else {
        this.removeDialog({ dialog_name: 'progressDialog' } )
      }
    }, 
    hasRecoverableError(status) {
      if (status) {
        this.addDialog({ dialog_name: 'recoverableErrorDialog' })
      }
      else {
        this.removeDialog({ dialog_name: 'recoverableErrorDialog' })
      }
    }
  },
  data() {
    return {
      leafletLicense:'&copy<a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/" target="_blank">CC-BY-SA</a>',
      robot: null,
      cameraMode: 'maincam',
      registeredWayPoint: [],
      notifyDialogIconType: 'normal',
      notifyDialogMessage: '',
      isNotifying: false,
      isCommunicationDisruption: false,
      isCrawlerFinishDriving: false,
      isShowErrorAndBackHomeDialog: false,
      errorAndBackHomeMessage: '',
      isShowProgressDialog: false,
      progressMessage: '',
      errorWarningCards: [],
      noWebappCtrlModeCard: null,
      backNotificationCard: null,
      hasRecoverableError: false,
      recoverableErrors: [],
      isPause: false,
      dialogs: []
    } 
  },
  beforeDestroy() {
    this.$refs.camera_and_ctrl.finalize()
    
    // ロボット状態監視トピックのサブスクライブを解除
    RobotAliveMonitoringCommand.deleteRobotErrorsWarningCallback()
    RobotAliveMonitoringCommand.deleteRobotMonitoringCallback(this.updateRobot)
    RobotAliveMonitoringCommand.stopMonitoring(false)

    MqttCommand.endIot()

    this.$store.dispatch('deselectedCrawlerAction')
    this.$store.dispatch('deselectedRouteAction')
    this.$store.dispatch('deselectedModeAction')
  },
  created() {
    this.setSelectedOperationInfo()
  },
  mounted() {
    this.setRobotAndRoute()
    this.mqtt = new MqttCommand()
    this.mqtt.on('reconnect', function() {
      RobotAliveMonitoringCommand.deleteRobotErrorsWarningCallback()
      RobotAliveMonitoringCommand.deleteRobotMonitoringCallback(this.updateRobot)
      RobotAliveMonitoringCommand.stopMonitoring(false)
      MqttCommand.endIot()
      .then(() => {
        MqttCommand.initIot(this.$store.state.selectedCrawler)
        .then(() => {
          RobotAliveMonitoringCommand.setRobotMonitoringCallback(this.updateRobot)
          RobotAliveMonitoringCommand.startMonitoringRobot(this.robot)
        })
      })
    })
  },
  methods: {
    setSelectedOperationInfo() {
      if (this.serial_no != null) {
        this.$store.dispatch('setSelectedCrawlerAction', {
          selectedCrawler: this.serial_no
        })
      }
      if (this.route_id != null) {
        this.$store.dispatch('setSelectedRouteAction', {
          selectedRoute: this.route_id
        })
      }
      if (this.operating_mode != null) {
        this.$store.dispatch('setSelectedModeAction', {
          selectedMode: this.operating_mode
        })
      }
    },
    setRobotAndRoute() {
      Robots.getManagedRobotList()
        .then(() => {
          this.robot =  Robots.getSpecificRobot(this.$store.state.selectedCrawler)
          MqttCommand.initIot(this.$store.state.selectedCrawler)
          .then(() => {
            RobotAliveMonitoringCommand.setRobotMonitoringCallback(this.updateRobot)
            RobotAliveMonitoringCommand.startMonitoringRobot(this.robot)
            this.$refs.camera_and_ctrl.initialize()
            this.$refs.map_template.initMap()
            if (this.operatingMode === 'create.route') {
              this.recreateRoute()
            }
          })
        })
    },
    async recreateRoute() {
      let recvTopic = `${sessionStorage.getItem('userPoolId')}/${this.$store.state.selectedCrawler}/usr_program/route-check-subject-registration/reply`
      MqttCommand.subscribe(recvTopic)
      let topicRegExp = /ap-northeast-1_([0-9a-zA-Z]){9}\/([0-9a-z]){12,}\/usr_program\/route-check-subject-registration\/reply/
      let type = 'cmd'
      let name = 'get.route'
      let payload = {}
      let packet = await MqttCommand.createPacket(type, name, payload)
      let packetId = packet['packet.id']
      let self = this
      let timeoutId = ''
      MqttCommand.setSubscribeTopicHandler(topicRegExp, packetId, function(topic, data) {
        if (data['replied.to'] === this) {
          // 受け取ったdataのwaypointを地図にプロットして表示する
          self.registeredWayPoint = data.payload['way.points']
          for (let wayPoint of data.payload['way.points']) {
              self.$refs.map_template.addWayPoint(wayPoint['lat.deg'], wayPoint['lon.deg'], wayPoint['elev.m'], null, wayPoint['wait'])
          }
        }
        clearTimeout(timeoutId)
        MqttCommand.removeSubscribeTopicHandler(topicRegExp, packetId)
        MqttCommand.unsubscribe(recvTopic)
      }.bind(packetId))
      .then(()=>{
        let sendTopic = `${sessionStorage.getItem('userPoolId')}/${this.$store.state.selectedCrawler}/usr_program/route-check-subject-registration`
        MqttCommand.sendPacket(sendTopic, packet)
        timeoutId = setTimeout(function() {
          MqttCommand.removeSubscribeTopicHandler(topicRegExp, packetId)
          MqttCommand.unsubscribe(recvTopic)
          clearTimeout(timeoutId)
          this.notifyDialogIconType = "error"
          this.notifyDialogMessage = `作成中のルートの取得に失敗しました。\n今回設定したポイントは\n以前のポイントに続いて登録されます。\n最終的なルートは登録時にご確認ください。`
          this.isNotifying = true
          setTimeout(function() {
              this.isNotifying = false
          }.bind(this), 10000)
        }.bind(this), 10000)
      })
    },
    updateRobot(robot) {
      // マップ更新
      this.$refs.map_template.updateRobotInfo(robot)
      
      // エラー表示
      this.displayErrorWarnings(robot)
      
      // ロボット状態がdoneの時に、点検/ルート設定終了ダイアログを表示する
      if (robot.robotStatus.status === 'done') {
        this.isCrawlerFinishDriving = true
      }

      // ロボットが自律走行中以外で、ロボット操作権がWebアプリにない時には、その旨をカード表示する
      if (this.operatingMode === 'check.plant' && this.drivingMode === 'autonomous') {
        if (this.noWebappCtrlModeCard != null) {
          this.noWebappCtrlModeCard.$destroy()
          this.noWebappCtrlModeCard.$el.parentNode.removeChild(this.noWebappCtrlModeCard.$el)
          this.noWebappCtrlModeCard = null
        }
      }
      else {
        if (this.noWebappCtrlModeCard != null && robot.robotStatus.controller === 'aws.iot') {
          this.noWebappCtrlModeCard.$destroy()
          this.noWebappCtrlModeCard.$el.parentNode.removeChild(this.noWebappCtrlModeCard.$el)
          this.noWebappCtrlModeCard = null
        }
        
        if (this.noWebappCtrlModeCard == null && robot.robotStatus.controller !== 'aws.iot') {
          let self = this
          let ComponentClass = Vue.extend(NotificationCard)
          let instance = new ComponentClass({
            parent: self,
            propsData: {
              iconType: 'error',
              dialogMessage: 'ロボット操作権がWebアプリにありません。',
            }
          }).$mount()
          this.noWebappCtrlModeCard = instance
          document.getElementById('notification-area').appendChild(instance.$el)
        }
      }
    },
    displayErrorWarnings(robot) {
      // 今表示されているfatalErrorとwarningの表示を一旦全てなくす
      for (let errorWarningCard of this.errorWarningCards) {
        errorWarningCard.$destroy()
        errorWarningCard.$el.parentNode.removeChild(errorWarningCard.$el)
      }
      this.errorWarningCards = []

      // 改めて現在ロボット側で出ているfatalErrorとwarningを表示する
      for (let fatalError of robot.robotStatus.fatalErrors) {
        this.displayErrorWarningCard('fatal', fatalError)
      }
      for (let warning of robot.robotStatus.warnings) {
        this.displayErrorWarningCard('warning', warning)
      }
      
      this.recoverableErrors = robot.robotStatus.errors
      // 解除可能なエラーをダイアログで表示する
      if (robot.robotStatus.status === 'stop') {
        this.hasRecoverableError = true
      }
      else {
        this.hasRecoverableError = false
      }
    },
    displayErrorWarningCard(type, errorWarning) {
      let self = this
      let ComponentClass = Vue.extend(ErrorWarningCard)
      let instance = new ComponentClass({
        parent: self,
        propsData: {
          type: type,
          errorWarning: errorWarning
        }
      }).$mount()
      this.errorWarningCards.push(instance)
      document.getElementById('notification-area').appendChild(instance.$el)
    },
    registerCurrentLoc() {
      this.$refs.route_create.registerCurrentLoc(this.addWaypointToMap)
    },
    registerCheckPoint(event) {
      this.$refs.route_create.registerCheckPoint(event.connectionId, this.registeredWayPoint, event.resetZoom, this.addWaypointToMap)
    },
    addWaypointToMap(obj) {
      if (obj.wayPointId != null && obj.wayPoint['lat.deg'] != null && obj.wayPoint['lon.deg'] != null && obj.wayPoint['elev.m'] != null) {
        this.registeredWayPoint.push(obj.wayPoint)

        this.notifyDialogIconType = "normal"
        let wait = false
        if (obj.type === 'current.loc') {
          this.notifyDialogMessage = "現在地を登録しました"
        }
        else {
          wait = obj.wayPoint['wait']
          this.notifyDialogMessage = "点検ポイントを登録しました"
        }
        this.$refs.map_template.addWayPoint(obj.wayPoint['lat.deg'], obj.wayPoint['lon.deg'], obj.wayPoint['elev.m'], obj.wayPointId, wait);
      }
      else {
        this.notifyDialogIconType = "error"
        if (obj.type === 'current.loc') {
          this.notifyDialogMessage = "現在地登録に失敗しました"
        } 
        else {
          this.notifyDialogMessage = "点検ポイント登録に失敗しました"
        }
        let beep = new BeepSound('sine', 450, 0.2, 2)
        beep.play()
      }

      this.isNotifying = true
      setTimeout(function() {
        this.isNotifying = false
      }.bind(this), 5000)
    },
    disableCrawlerCtrl() {
      this.$refs.camera_and_ctrl.disableCrawlerCtrl()
    },
    enableCrawlerCtrl() {
      this.$refs.camera_and_ctrl.enableCrawlerCtrl(this.cameraMode)
    },
    displayBackNotification(event) {
      // バック注意表示の表示/非表示を切り替える
      if (event.toggle === 'show' && this.backNotificationCard == null) {
        let self = this
        let ComponentClass = Vue.extend(NotificationCard)
        let instance = new ComponentClass({
          parent: self,
          propsData: {
            iconType: 'normal',
            dialogMessage: 'バックします。後方の安全を確認してください。',
          }
        }).$mount()
        this.backNotificationCard = instance
        document.getElementById('notification-area').appendChild(instance.$el)
      }
      else if (event.toggle === 'hide' && this.backNotificationCard != null) {
        this.backNotificationCard.$destroy()
        this.backNotificationCard.$el.parentNode.removeChild(this.backNotificationCard.$el)
        this.backNotificationCard = null
      }
      else {
        // nothing
      }
    },
    pause(from) {
      this.progressMessage = 'クローラー一時停止中です。'
      this.isShowProgressDialog = true
      this.$refs.camera_and_ctrl.pause(from)
    },
    pauseResponse(event) {
      this.progressMessage = ''
      this.isShowProgressDialog = false
      if (event.result) {
        // ダイアログを表示する
        if (event.from === 'check.finish') {
          this.$refs.check.confirmFinishCheck()
        }
        else {
          this.isPause = true
        }
      }
      else {
        this.notifyDialogIconType = "error"
        this.notifyDialogMessage = "一時停止できませんでした。\nネットワークを確認してください。"
        this.isNotifying = true
        setTimeout(function() {
          this.isNotifying = false
        }.bind(this), 5000)
      }
    },
    resume(event) {
      switch(event.from) {
        case 'pause':
          if (this.recoverableErrors.length !== 0) {
            this.isPause = false
          }
          break
        case 'resume.autonomous':
          break
        case 'resume.skippoint.autonomous':
          break
        default:
          break
      }
            
      switch (event.mode) {
        case 'autonomous':
          this.progressMessage = '自律走行を再開しています。'
          break
        case 'remote.ctrl':
          this.progressMessage = '遠隔操作で再開しています。'
          break
        case 'skippoint.autonomous':
          this.progressMessage = 'ポイントをスキップして自律走行を再開しています。'
          break
        default:
          break
      }
      this.isShowProgressDialog = true
      this.$refs.camera_and_ctrl.resume(event.mode, event.from)
    },
    resumeResponse(event) {
      this.isShowProgressDialog = false
      if (event.result) {
        switch(event.from) {
          case 'lift.pause':
            this.isPause = false
            break
          case 'lift.resume.autonomous':
            break
          case 'lift.resume.skippoint.autonomous':
            break
          case 'cancel.finish.check':
            break
          default:
            break
        }

        if(this.operatingMode === 'check.plant') {
          switch(event['switch.mode.to']) {
            case 'autonomous':
              this.notifyDialogMessage = '自律走行を再開しました。'
              break
            case 'remote.ctrl':
              this.notifyDialogMessage = '自律走行再開のための遠隔操作に移行しました。'
              break
            case 'skippoint.autonomous':
              this.notifyDialogMessage = 'ポイントをスキップして自律走行を再開しました。'
              break
            default:
              break
          }
        }
        else {
          this.notifyDialogMessage = '遠隔操作を再開しました。'
        }
        this.notifyDialogIconType = 'normal'
        this.isNotifying = true
        setTimeout(function() {
          this.isNotifying = false
        }.bind(this), 5000)
      }
      else {
        switch(event['switch.mode.to']) {
          case 'autonomous':
            if (event.from === 'lift.resume.autonomous') {
              this.notifyDialogIconType = "error"
              this.notifyDialogMessage = "自律走行再開に失敗しました。\nネットワークを確認してください。"
              this.isNotifying = true
              setTimeout(function() {
                this.isNotifying = false
              }.bind(this), 5000)
            }
            else {
              this.errorAndBackHomeMessage = '自律走行での再開に失敗しました。\nネットワークを確認してください。'
              this.isShowErrorAndBackHomeDialog = true
            }
            break
          case 'remote.ctrl':
            this.errorAndBackHomeMessage = '遠隔操作での再開に失敗しました。\nネットワークを確認してください。'
            this.isShowErrorAndBackHomeDialog = true
            break
          case 'skippoint.autonomous':
            this.notifyDialogMessage = 'ポイントのスキップに失敗しました。'
            this.notifyDialogIconType = 'error'
            this.isNotifying = true
            setTimeout(function() {
              this.isNotifying = false
            }.bind(this), 5000)
            break
          default:
            break
        }
      }
    },
    resetError() {
      this.progressMessage = 'エラー解除を試行しています。'
      this.isShowProgressDialog = true
      this.$refs.camera_and_ctrl.resetError()
    },
    resetErrorResponse(event) {
      this.progressMessage = ''
      this.isShowProgressDialog = false
      if (event.result) {
        this.hasRecoverableError = false
      }
    },
    switchCtrlModeResponse(event) {
      if (event.result) {
        this.notifyDialogIconType = "normal"
        this.notifyDialogMessage = `ロボット操作権限を取得しました。`
      }
      else {
        this.notifyDialogIconType = "error"
        this.notifyDialogMessage = `ロボット操作権限の取得に失敗しました。`
      }
      this.isNotifying = true
      setTimeout(function() {
        this.isNotifying = false
      }.bind(this), 5000)
    },
    finishCheck() {
      return new Promise(async(resolve, reject) => {
        this.progressMessage = '点検終了中です。'
        this.isShowProgressDialog = true

        let recvTopic = `${sessionStorage.getItem('userPoolId')}/${this.$store.state.selectedCrawler}/usr_program/exec-ctrl/reply`
        MqttCommand.subscribe(recvTopic)

        let topicRegExp = /ap-northeast-1_([0-9a-zA-Z]){9}\/([0-9a-z]){12,}\/usr_program\/exec-ctrl\/reply/

        let type = 'cmd'
        let name = 'halt'
        let payload = {}
        let timeoutId = ''

        let packet = await MqttCommand.createPacket(type, name, payload)
        let packetId = packet['packet.id']
        let self = this
        MqttCommand.setSubscribeTopicHandler(topicRegExp, packetId, function(topic, data) { // eslint-disable-line
          if (data['replied.to'] === this) {
            self.progressMessage = ''
            self.isShowProgressDialog = false
            clearTimeout(timeoutId)
            MqttCommand.removeSubscribeTopicHandler(topicRegExp, packetId)
            MqttCommand.unsubscribe(recvTopic)
            if (data.payload['has.succeeded']) {
              self.$refs.check.closeConfirmFinishCheckDialog()
            }
            else {
              self.errorAndBackHomeMessage = '点検の終了に失敗しました。\nネットワークを確認してください。'
              self.isShowErrorAndBackHomeDialog = true
            }
          }
        }.bind(packetId))
        .then(()=>{
          let sendTopic = `${sessionStorage.getItem('userPoolId')}/${this.$store.state.selectedCrawler}/usr_program/exec-ctrl`
          MqttCommand.sendPacket(sendTopic, packet)
          timeoutId = setTimeout(function() {
            this.progressMessage = ''
            this.isShowProgressDialog = false
            clearTimeout(timeoutId)
            this.errorAndBackHomeMessage = '点検の終了に失敗しました。\nネットワークを確認してください。'
            this.isShowErrorAndBackHomeDialog = true
            MqttCommand.removeSubscribeTopicHandler(topicRegExp, packetId)
            MqttCommand.unsubscribe(recvTopic)
            reject()
          }.bind(this), 10000)
        })
      })
    },
    cancelFinishCheck() {
      this.$refs.camera_and_ctrl.resume('remote.ctrl', 'cancel.finish.check')
    },
    reconnect() {
      this.$refs.camera_and_ctrl.reconnect()
      this.$refs.camera_mode_switch.changeCameraMode('maincam')
    },
    changeCameraMode(event) {
      // TODO: LSのライブラリ更新時には、ここでLiveStreamingの配信映像を変更するコマンドを送信する
      this.cameraMode = event.cameraMode
      this.notifyDialogIconType = "normal"
      switch (event.cameraMode) {
        case 'maincam':
          this.notifyDialogMessage = "メインカメラに切り替えました。"
          break
        case 'ptz':
          this.notifyDialogMessage = "パンチルトカメラに切り替えました。"
          break
        case 'thermal':
          this.notifyDialogMessage = "サーモカメラに切り替えました。"
          break
        case 'sound':
          this.notifyDialogMessage = "音画面に切り替えました。"
          break
        default:
          break
      }
      this.isNotifying = true
      setTimeout(function() {
        this.isNotifying = false
      }.bind(this), 5000)
    },
    setRobotParameter(event) {
      if (event.autoChargingMode) {
        this.executeCheck(event)
      }
      this.$refs.camera_and_ctrl.setRobotParameter(event.linearVelocity, event.slewingSpeed)
    },
    sendGetCtrlCmd() {
      this.$refs.camera_and_ctrl.sendGetCtrlCmd()
    },
    returnToHome() {
      switch (this.$store.state.selectedMode) {
        case 'create.route':
          if (!this.isCrawlerFinishDriving) {
            this.finishRouteCreate()
            .then(() => {
              this.forcedReturnToHome()
            })
            .catch(() => {
              this.errorAndBackHomeMessage = 'ルート設定の終了に失敗しました。\nネットワークを確認してください。'
              this.isShowErrorAndBackHomeDialog = true
            })
          }
          else {
            this.forcedReturnToHome()
          }
          break
        case 'check.plant':
          this.forcedReturnToHome()
          break
        default:
          break
      }
    },
    forcedReturnToHome() {
      this.$store.dispatch('deselectedCrawlerAction')
      this.$router.push('/Menu')
    },
    cancelReturnToHome() {
      this.isShowErrorAndBackHomeDialog = false
    },
    finishRouteCreate() {
      // MQTT関連(ロボット操作コマンド送信、(PTZカメラ操作コマンド送信)、ロボット状態管理コマンド受信)の終了処理を行う
      // LiveStreamingの配信継続コマンド送信についてはkeepコマンドを送らなければ自動で停止するため明示的に行わない
      return new Promise(async(resolve, reject) => {
        this.progressMessage = 'ルート設定を終了しています。'
        this.isShowProgressDialog = true

        let recvTopic = `${sessionStorage.getItem('userPoolId')}/${this.$store.state.selectedCrawler}/usr_program/exec-ctrl/reply`
        MqttCommand.subscribe(recvTopic)

        let topicRegExp = /ap-northeast-1_([0-9a-zA-Z]){9}\/([0-9a-z]){12,}\/usr_program\/exec-ctrl\/reply/

        let type = 'cmd'
        let name = 'halt'
        let payload = {}
        let timeoutId = ''

        let packet = await MqttCommand.createPacket(type, name, payload)
        let packetId = packet['packet.id']
        let self = this
        MqttCommand.setSubscribeTopicHandler(topicRegExp, packetId, function(topic, data) { // eslint-disable-line
            if (data['replied.to'] === this) {
                self.progressMessage = ''
                self.isShowProgressDialog = false
                clearTimeout(timeoutId)
                MqttCommand.removeSubscribeTopicHandler(topicRegExp, packetId)
                MqttCommand.unsubscribe(recvTopic)
                if (data.payload['has.succeeded']) {
                    resolve()
                }
                else {
                    reject()
                }
            }
        }.bind(packetId))
        .then(()=>{
            let sendTopic = `${sessionStorage.getItem('userPoolId')}/${this.$store.state.selectedCrawler}/usr_program/exec-ctrl`
            MqttCommand.sendPacket(sendTopic, packet)
            timeoutId = setTimeout(function() {
                this.progressMessage = ''
                this.isShowProgressDialog = false
                clearTimeout(timeoutId)
                MqttCommand.removeSubscribeTopicHandler(topicRegExp, packetId)
                MqttCommand.unsubscribe(recvTopic)
                reject()
            }.bind(this), 10000)
        })
      })
    },
    addDialog(event) {
      this.dialogs.push(event.dialog_name)
    },
    removeDialog(event) {
      this.dialogs.splice(this.dialogs.indexOf(event.dialog_name), 1)
    },
    changeEnableAndDisableCrwalerCtrl() {
      if ((this.cameraMode === 'maincam' || this.cameraMode === 'thermal' || this.cameraMode === 'sound')
        && this.dialogs.length === 0 && this.controller === 'aws.iot') {
        if (this.operatingMode === 'check.plant' && this.drivingMode === 'autonomous') {
          this.disableCrawlerCtrl()
          return 
        }
        this.enableCrawlerCtrl()
        return
      }
      this.disableCrawlerCtrl()
    },
    async executeCheck(obj) {
            if(obj.autoChargingMode !== null) {
                let recvTopic = `${sessionStorage.getItem('userPoolId')}/${this.$store.state.selectedCrawler}/usr_program/exec-ctrl/reply`
                MqttCommand.subscribe(recvTopic)
                let topicRegExp = /ap-northeast-1_([0-9a-zA-Z]){9}\/([0-9a-z]){12,}\/usr_program\/exec-ctrl\/reply/
                let type = 'cmd'
                let name = 'autocharge'
                let param = ""
                if (obj.autoChargingMode === 'docking') {
                  param = "dock.charging.station"
                } else if (obj.autoChargingMode === 'undocking') {
                  param = "undock.charging.station"
                }
                let payload = {
                    param: param
                }
                let packet = await MqttCommand.createPacket(type, name, payload)
                let packetId = packet['packet.id']
                // let timeoutId = ''
                let self = this
                MqttCommand.setSubscribeTopicHandler(topicRegExp, packetId, function(topic, data) {
                    if (data['replied.to'] === this) {
                        if (data.payload['has.succeeded']) {
                            self.isShowDialog = false
                            self.isShowProgressDialog = false
                            self.progressMessage = ''
                            self.isShowInfoDialog = false
                            self.infoType = 'error'
                            self.infoMessage = ''
                        }
                        else {
                            self.infoType = "error"
                            self.infoMessage = "実行失敗しました。"
                            self.isShowInfoDialog = true
                        }
                        MqttCommand.removeSubscribeTopicHandler(topicRegExp, packetId)
                        MqttCommand.unsubscribe(recvTopic)
                        // clearTimeout(timeoutId)
                    }
                }.bind(packetId))
                .then(()=>{
                    let sendTopic = `${sessionStorage.getItem('userPoolId')}/${this.$store.state.selectedCrawler}/usr_program/exec-ctrl`
                    MqttCommand.sendPacket(sendTopic, packet)
                    this.progressMessage = '入出庫モード実行を開始しています。'
                    this.isShowProgressDialog = true
                })
            }
            else {
                // nothing
            }
        },
  }
}
</script>

<style scoped>
.bottom {
    position: absolute;
    bottom: 0px;
    width: 70%;
    height:85px;
    z-index: 1000000;
}
.bottom__button--layout {
    text-align: right;
}
.bottom__col--ver-size {
    height:85px;
}
.bottom__ctrl-mode-text {
    width: 258px;
    height: 64px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px 5px 0px 0px;
    opacity: 1;
    text-align: left;
    line-height: 64px !important;
    padding-left: 20px;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
    color: #707070;
}
.bottom__license-display {
    position: absolute;
    bottom: 0;
    font-size: 15px;
    color: #FFFFFF;
}
.camera-mode-switch {
  position: absolute;
  top: 462px;
  right: 0;
  z-index: 1000000;
}
.camera-and-ctrl {
  width: 100%;
  height: 100%;
}
.crawler-driving {
  width: 100%;
  height: 100%;
}
.check-template {
  position: absolute;
  width: 100%;
  top: 0px;
  z-index: 1000000;
}
.forefront {
  z-index: 2000000;
}
.notification-area {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 650px;
  height: 66px;
  pointer-events: none
}
.notification-area--under-stop-button {
  position: absolute;
  top: 85px;
  left: 50%;
  transform: translateX(-50%);
  width: 600px;
  height: 66px;
  pointer-events: none;
}
.route-create-template {
  position: absolute;
  width: 100%;
  top: 0px;
  z-index: 1000000;
}
</style>