<template>
  <v-layout>
    <div class="focus-button-group">
      <v-btn text large class="focus-button-group__btn-text mt-2" @click="_focusFar">F</v-btn>
      <v-divider class="focus-button-group__separator"></v-divider>
      <v-icon large class="mt-2" color="#FFFFFF" @click="_focusAuto">mdi-focus-auto</v-icon>
      <v-divider class="focus-button-group__separator"></v-divider>
      <v-btn text large class="focus-button-group__btn-text mt-2" @click="_focusNear">N</v-btn>
    </div>
    <div class="zoom-button-group">
      <v-icon large class="mt-2" @mousedown.native="_zoomInDrastically" @mouseup.native="_zoomStop" @mouseleave.native="_zoomStop" @touchstart.native="_zoomInDrastically" @touchend.native="_zoomStop">$vuetify.icons.zoomUp</v-icon>
      <v-divider class="zoom-button-group__separator"></v-divider>
      <v-icon class="mt-2" @mousedown.native="_zoomIn" @mouseup.native="_zoomStop" @mouseleave.native="_zoomStop" @touchstart.native="_zoomIn" @touchend.native="_zoomStop">$vuetify.icons.zoomUp</v-icon>
      <v-divider class="zoom-button-group__separator"></v-divider>
      <v-icon class="mt-2" @mousedown.native="_zoomOut" @mouseup.native="_zoomStop" @mouseleave.native="_zoomStop" @touchstart.native="_zoomOut" @touchend.native="_zoomStop">$vuetify.icons.zoomDown</v-icon>
      <v-divider class="zoom-button-group__separator"></v-divider>
      <v-icon large class="mt-2" @mousedown.native="_zoomOutDrastically" @mouseup.native="_zoomStop" @mouseleave.native="_zoomStop" @touchstart.native="_zoomOutDrastically" @touchend.native="_zoomStop">$vuetify.icons.zoomDown</v-icon>
    </div>
  </v-layout>
</template>

<script>
export default {
  methods: {
    _focusFar: function(event) {
      this.$emit('focusFar')
      if (event.cancelable) {
          event.preventDefault()
      }
    },
    _focusNear: function(event) {
      this.$emit('focusNear')
      if (event.cancelable) {
          event.preventDefault()
      }
    },
    _focusAuto: function(event) {
      this.$emit('focusAuto')
      if (event.cancelable) {
          event.preventDefault()
      }
    },
    _zoomIn: function(event) {
      this.$emit('zoomIn')
      if (event.cancelable) {
          event.preventDefault()
      }
    },
    _zoomOut: function(event) {
      this.$emit('zoomOut')
      if (event.cancelable) {
          event.preventDefault()
      }
    },
    _zoomInDrastically: function(event) {
      this.$emit('zoomInDrastically')
      if (event.cancelable) {
          event.preventDefault()
      }
    },
    _zoomOutDrastically: function(event) {
      this.$emit('zoomOutDrastically')
      if (event.cancelable) {
          event.preventDefault()
      }
    },
    _zoomStop: function(event) {
      this.$emit('zoomStop')
      if (event.cancelable) {
          event.preventDefault()
      }
    },
  }
}
</script>

<style scoped>
.focus-button-group {
    position: relative;
    width: 82px;
    height: 190px;
    margin-top: 0%;
    margin-left: 20%;
    background: #FFFFFF4D 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 3px solid #FFFFFF;
    opacity: 1;
    text-align: center;
}
.focus-button-group__btn-text {
    color: #FFFFFF;
    font: normal normal bold 24px/32px Segoe UI;
}
.focus-button-group__separator {
    margin: 10px auto 0;
    opacity: 1;
    border-top: 3px solid #FFFFFF;
    width: 54px;
}
.zoom-button-group {
    position: relative;
    width: 82px;
    height: 210px;
    margin-top: 0%;
    margin-left: 20%;
    border-radius: 41px;
    background: #FFFFFF4D 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 3px solid #FFFFFF;
    opacity: 1;
    text-align: center;
}
.zoom-button-group__separator {
    margin: 10px auto 0;
    opacity: 1;
    border-top: 3px solid #FFFFFF;
    width: 54px;
}
</style>