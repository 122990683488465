<template>
    <v-dialog persistent max-width="621px" :retain-focus="false" v-model="c_errorAndBackHomeDialogVisible">
        <template v-slot:activator="{c_errorAndBackHomeDialogVisible}"></template> <!-- eslint-disable-line -->
        <v-card class="dialog">
        <v-toolbar flat dark class="dialog__title" color="#FF0000" height="40">
            エラー
        </v-toolbar>
        <v-card-text class="dialog__message mt-10">
            {{ errorMessage }}
        </v-card-text>
        <v-card-actions>
            <v-layout justify-end>
                <v-btn rounded dark class="dialog__button" color="#707070" @click="returnToHome">ホーム画面へ戻る</v-btn>
                <v-btn rounded dark class="dialog__button mx-3" color="#0099FF" @click="closeDialog">OK</v-btn>
            </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        errorAndBackHomeDialogVisible: { type: Boolean },
        errorMessage: { type: String }
    },
    data() {
        return {
        }
    },
    computed: {
        c_errorAndBackHomeDialogVisible: {
            get() {
                return this.errorAndBackHomeDialogVisible
            },
            set(val) {
                this.$emit('error-and-back-home-dialog-visible', val)
            }
        }
    },
    methods: {
        returnToHome() {
            this.$emit('returnToHome')
        },
        closeDialog() {
            this.$emit('ok')
        },
    }
}
</script>

<style scoped>
.dialog {
    max-width: 621px;
    min-height: 236px;
}
.dialog__button {
    min-width: 164px !important;
    height: 54px !important;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 26px;
    opacity: 1;
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
}
.dialog__message {
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
    color: #000000 !important;
    opacity: 1;
    white-space: pre-wrap;
}
.dialog__title {
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
    opacity: 1;
}
</style>