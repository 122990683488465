import BackState from './BackState'
import NormalReadyState from './NormalReadyState'

class BackReadyState {
    constructor() {}

    doInput(moveContext, input) {
        if (input !== 'down') {
            moveContext.changeState(NormalReadyState)
        }
        else {
            moveContext.changeState(BackState, input)
        }
    }

    doAction(moveContext, input) {
        if (input === undefined) {
            moveContext.notify('backReady', null)
        }
    }
}

const backReadyState = new BackReadyState()
Object.freeze(backReadyState)
export default backReadyState