<template>
<div></div>
</template>

<script>
import { fabric } from 'fabric'
export default {
  props: {
  },
  data() {
    return {
      connectionId: null,
    }
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    addReticleCanvas(connectionId) {
      this.connectionId = connectionId
      let $soundView = document.getElementById('sound-view')
      const $baseCanvas = document.createElement('canvas')
      $baseCanvas.id = 'drawing-reticle-canvas'
      $baseCanvas.style.position = 'absolute'

      if ($soundView != null) {
        $soundView.appendChild($baseCanvas)
        this.canvas = new fabric.Canvas('drawing-reticle-canvas')
        this.adjustCanvasSize()
        this.drawReticle()
      }
    },
    resizeReticleCanvas() {
      this.adjustSizeAndPosition()
    },
    destroyReticleCanvas() {
      this.clearAllCanvas()
      const $baseCanvas = document.getElementById('drawing-reticle-canvas')
      if ($baseCanvas != null && $baseCanvas.parentElement != null && $baseCanvas.parentElement.childElementCount > 0) {
          $baseCanvas.parentElement.removeChild($baseCanvas)
      }
    },
    drawReticle() {
      let $soundView = document.getElementById('sound-view')
      if ($soundView != null) {
        let scale = window.devicePixelRatio
        this.verticalLine = new fabric.Line(
          [$soundView.clientWidth / 2 / scale, (($soundView.clientHeight / 2) - 50) / scale , $soundView.clientWidth / 2 / scale, (($soundView.clientHeight / 2) + 50) / scale], {
          stroke: 'black',
          strokeWidth: 2,
          selectable: false,
          hasControls: false,
          evented: false
        })
        this.horizontalLine = new fabric.Line(
          [(($soundView.clientWidth / 2) - 50) / scale, $soundView.clientHeight / 2 / scale, (($soundView.clientWidth / 2) + 50) / scale, $soundView.clientHeight / 2 / scale], {
          stroke: 'black',
          strokeWidth: 2,
          angle: 0,
          selectable: false,
          hasControls: false,
          evented: false
          })
        this.canvas.add(this.verticalLine)
        this.canvas.add(this.horizontalLine)
      }
    },
    adjustCanvasSize() {
      const $soundView = document.getElementById('sound-view')
      if (this.canvas != null && this.canvas.contextContainer != null && $soundView != null) {
        this.canvas.setWidth($soundView.clientWidth)
        this.canvas.setHeight(parseInt($soundView.style.height))
        this.canvas.renderAll()

        let scale = window.devicePixelRatio
        const $baseCanvas = document.getElementById('drawing-reticle-canvas')
        if ($baseCanvas != null) {
          let $baseCanvasContext = $baseCanvas.getContext('2d')
          $baseCanvasContext.scale(scale, scale)
        }

        const $upperCanvas = document.getElementsByClassName('upper-canvas')[1]
        if ($upperCanvas != null) {
          let $upperCanvasContext = $upperCanvas.getContext('2d')
          $upperCanvasContext.scale(scale, scale)
        }
      }
    },
    adjustReticlePosition() {
      const $soundView = document.getElementById('sound-view')
      if ($soundView != null) {  
        let scale = window.devicePixelRatio
        if (this.verticalLine != null && this.horizontalLine != null) {
          this.verticalLine.set({'x1': $soundView.clientWidth / 2 / scale, 'y1': (($soundView.clientHeight / 2) - 50) / scale, 'x2': $soundView.clientWidth / 2 / scale, 'y2': (($soundView.clientHeight / 2) + 50) / scale})
          this.horizontalLine.set({'x1': (($soundView.clientWidth / 2) - 50) / scale, 'y1': $soundView.clientHeight / 2 / scale, 'x2': (($soundView.clientWidth / 2) + 50) / scale, 'y2': $soundView.clientHeight / 2 / scale})
          if (this.canvas != null && this.canvas.contextContainer != null) {
            this.canvas.renderAll();
          }
        }
      }
    },
    adjustSizeAndPosition() {
      this.adjustCanvasSize()
      this.adjustReticlePosition()
    },
    clearAllCanvas() {
      if (this.canvas != null && this.canvas.contextContainer != null) {
        this.canvas.clear()
        this.canvas.dispose()
      }
    },
    renderReticle() {
      this.drawReticle()
    },
    destroyReticle() {
      if (this.canvas != null && canvas.contextContainer != null) {
        this.canvas.remove(this.verticalLine)
        this.canvas.remove(this.horizontalLine)
      }
    }
  }
}
</script>

<style scoped>
</style>