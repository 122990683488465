<template>
<div id="crawler-web-app" class='crawler-web-app__window--min-size'>
  <v-app>
      <Header></Header>
      <v-main class='crawler-web-app__window--min-size'>
        <router-view></router-view>
      </v-main>
  </v-app>
</div>
</template>

<script>
import Header from './global/Header.vue';

export default {
  name: 'App',

  components: {
    Header,
  },

  data() {
    return {
    }
  }
};
</script>

<style>
html, body {
  height: 100%;
  overflow-x: auto;
  overflow-y: auto;
}
html::-webkit-scrollbar{
  display: none;
}
.crawler-web-app__window--min-size {
  min-width: 900px;
  min-height: 500px;
}
</style>
