// Copyright(c) 2024 RICOH Company, Ltd. All rights reserved.

// keyinputmanager.js

'use strict'

import {EventEmitter} from 'events'

const blankKeyMap = {}

const defKeyMap = {
  'left': 37, // LEFT key
  'up': 38, // UP key
  'right': 39, // RIGHT key
  'down': 40, // DOWN key
  // 'speed1': 49, // 1 key
  // 'speed2': 50, // 2 key
  // 'speed3': 51, // 3 key
  // 'speed4': 52, // 4 key
  // 'drive': 68, // D key
  // 'parking': 80, // P key
  // 'rear': 82, // R key
  // 'thetaFpv': 16, // SHIFT key
  // 'emergency': 66, // B key
};
const wasdKeyMap = {
  'left': 65, // A key
  'up': 87, // W key
  'right': 68, // D key
  'down': 83, // S key
  // 'speed1': 49, // 1 key
  // 'speed2': 50, // 2 key
  // 'speed3': 51, // 3 key
  // 'speed4': 52, // 4 key
  // 'drive': 70, // F key
  // 'parking': 80, // P key
  // 'rear': 82, // R key
  // 'thetaFpv': 16, // SHIFT key
  // 'emergency': 66, // B key
};

const moveMode = {
  normal: Symbol(0),
  backReady: Symbol(1),
  back: Symbol(2),
  normalReady: Symbol(3)
}

let moveState = moveMode.normal

export default class KeyInputManager extends EventEmitter {
  constructor (mode) {
    super()

    this.keyMaps = {
      'blank': this._invertKeyValue(blankKeyMap),
      'default': this._invertKeyValue(defKeyMap), 
      'wasd': this._invertKeyValue(wasdKeyMap)
    }
    this._setKeyMap(mode)
  }

  _setKeyMap (mode='blank') {
    if (!(mode in this.keyMaps)) {
      throw new Error('Bad Parameter. mode:' + mode)
    }

    this.mode = mode

    this._keyDownHandler = function(event) {
      if (moveState === moveMode.backReady || moveState === moveMode.normalReady) {
        if(!event.repeat) {
          this._keyHandler(event, true)
        }
      }
      else {
        this._keyHandler(event, true)
      }
    }.bind(this)
    this._keyUpHandler = function(event) {
      this._keyHandler(event, false)
    }.bind(this)
  
    document.addEventListener('keydown', this._keyDownHandler, false)
    document.addEventListener('keyup', this._keyUpHandler, false)

    this.keyPushed = {}

    this.clearAll()

  }
  _keyHandler(event, stat) {
    if (this._keyChange(event, event.keyCode, stat)) {
      if (event.cancelable) {
        event.preventDefault()
      }
    }
  }
  _invertKeyValue(dic) {
    const kv = {}
    for (let key in dic) {
      const value = dic[key]
      if (value > 0) {
        kv[value] = key
      }
    }
    return kv
  }
  _keyChange(event, keyCode, stat) {
    const keyMap = this.keyMaps[this.mode]
    if (keyCode in keyMap) {
      const key = keyMap[keyCode]

      let repeat = 0
      if (stat) {
        repeat = 1 + event.repeat
      }
      this.keyPushed[key] = repeat
 
      this.emit('key', key, event.type)
      if (stat) {
        return true
      } else {
        return false
      }
    }
  }
  clearAll() {
    const keyPushed = this.keyPushed
    this.keyPushed = {}

    for (let key in keyPushed) {
      if (keyPushed[key]) {
        this.emit('key', key, 0)
      }
    }
  }
  isPushed(key) {
    if (this.keyPushed[key]) {
      return this.keyPushed[key] > 0
    } else {
      return false
    }
  }
  setMoveState(state) {
    switch(state) {
      case 'normal':
        moveState = moveMode.normal
        break
      case 'backReady':
        moveState = moveMode.backReady
        break
      case 'back':
        moveState = moveMode.back
        break
      case 'normalReady':
        moveState = moveMode.normalReady
        break
    }
  }
  destroy() {
    document.removeEventListener('keydown', this._keyDownHandler, false)
    document.removeEventListener('keyup', this._keyUpHandler, false)
  }
}
