<template>
   <div ref="info_point"></div>
</template>

<script>
import InfoPoint from "!!raw-loader!../../assets/ricoh_assets/icons/ICN003-03_info_point.svg" // loads SVG Icons as String via Webpack.
export default {
    mounted() {
        let shadowRoot = this.$refs.info_point.attachShadow({mode: 'open'})
        shadowRoot.innerHTML = InfoPoint

        const style = document.createElement('style')
        style.innerHTML = `svg { width: 100%; height: 100%; }`

        const clonedStyle = style.cloneNode(true)
        shadowRoot.appendChild(clonedStyle)
    }
}
</script>

<style>
</style>