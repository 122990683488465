<template>
    <div>
        <v-dialog persistent max-width="1030px" v-model="c_checkPointDialogVisible">
            <template v-slot:activator="{c_checkPointDialogVisible}"></template> <!-- eslint-disable-line -->
            <v-card class="dialog">
                <v-toolbar flat dark class="dialog__title" color="#4A4B4C" height="40">点検項目の設定</v-toolbar>
                <v-container fluid>
                    <v-row>
                        <v-col>
                            <canvas id="capture" class="dialog__canvas" width="480" height="360"></canvas>
                        </v-col>
                        <v-col cols="6">
                            <v-form v-model="isValid" lazy-validation>
                                <v-layout>
                                    <v-card-text class="dialog__item">識別ID</v-card-text>
                                    <v-text-field outlined clearable class="ml-4" :rules="[rules.required]" v-model="checkPointName" dense></v-text-field>
                                </v-layout>
                                <v-checkbox class="dialog__item" style="width: 60% !important;" v-model="isAutoFocus" label="オートフォーカス" dense />
                                <v-checkbox class="dialog__item" style="width: 60% !important;" v-model="isTemplateMatching" label="テンプレートマッチング" dense />
                            </v-form>
                            <div v-if="displayType==0">
                                <div v-if="mode==0">
                                    <v-layout>
                                        <v-btn large rounded dark outlined class="mb-2 font-weight-bold blue--text" color="#ffffff" @click="selectButton($event)">
                                            <v-icon color="#0099ff" v-if="meter.isSave">mdi-check</v-icon>
                                            {{ detailNames[0].name }}</v-btn>
                                        <v-btn large rounded dark outlined class="mb-2 ml-2 font-weight-bold blue--text" color="#ffffff" @click="selectButton($event)">
                                            <v-icon color="#0099ff" v-if="crack.isSave">mdi-check</v-icon>
                                            {{ detailNames[1].name }}</v-btn>
                                        <v-btn large rounded dark outlined class="mb-2 ml-2 font-weight-bold blue--text" color="#ffffff" @click="selectButton($event)">
                                            <v-icon color="#0099ff" v-if="rust.isSave">mdi-check</v-icon>
                                            {{ detailNames[2].name }}</v-btn>
                                        <v-btn large rounded dark outlined class="mb-2 ml-2 font-weight-bold blue--text" color="#ffffff" @click="selectButton($event)">
                                            <v-icon color="#0099ff" v-if="smoke.isSave">mdi-check</v-icon>
                                            {{ detailNames[3].name }}</v-btn>
                                    </v-layout>
                                    <v-layout>
                                        <v-btn large rounded dark outlined class="mb-2 font-weight-bold blue--text" color="#ffffff" @click="selectButton($event)">
                                            <v-icon color="#0099ff" v-if="leak.isSave">mdi-check</v-icon>
                                            {{ detailNames[4].name }}</v-btn>
                                        <v-btn large rounded dark outlined class="mb-2 ml-2 font-weight-bold blue--text" color="#ffffff" @click="selectButton($event)">
                                            <v-icon color="#0099ff" v-if="lampSwitch.isSave">mdi-check</v-icon>
                                            {{ detailNames[5].name }}</v-btn>
                                    </v-layout>
                                </div>

                                <div v-if="mode==1">
                                    <meter-settings ref="meterSettings" :params="meter" @update="updateMeterSettings" @cancel="cancel" />
                                </div>
                                <div v-if="mode==2">
                                    <crack-settings ref="crackSettings" :params="crack" @update="updateCrackSettings" @cancel="cancel" />
                                </div>
                                <div v-if="mode==3">
                                    <rust-settings ref="rustSettings" :params="rust" @update="updateRustSettings" @cancel="cancel" />
                                </div>
                                <div v-if="mode==4">
                                    <smoke-settings ref="smokeSettings" :params="smoke" @update="updateSmokeSettings" @cancel="cancel" />
                                </div>
                                <div v-if="mode==5">
                                    <leak-settings ref="leakSettings" :params="leak" @update="updateLeakSettings" @cancel="cancel" />
                                </div>
                                <div v-if="mode==6">
                                    <lamp-switch-settings ref="lampSwitchSettings" :params="lampSwitch" @update="updateLampSwitchSettings" @cancel="cancel" />
                                </div>
                            </div>

                            <div v-if="displayType==1">
                                <thermal-settings ref="thermalSettings" @update="updateThermalSettings" />
                            </div>

                            <div v-if="displayType==2">
                                <microphone-settings ref="microphoneSettings" @update="updateMicrophoneSettings" />
                            </div>
                        </v-col>
                    </v-row>
                </v-container>
                <v-card-text class="dialog__error-message" v-show="isError&&this.displayType===0">{{'メーターの登録に失敗しました。ネットワークを確認して再度実行してください。'}}</v-card-text>
                <v-card-text class="dialog__error-message" v-show="isError&&this.displayType===1">{{'熱分布の登録に失敗しました。ネットワークを確認して再度実行してください。'}}</v-card-text>
                <v-card-text class="dialog__error-message" v-show="isError&&this.displayType===2">{{'音の登録に失敗しました。ネットワークを確認して再度実行してください。'}}</v-card-text>
                <v-card-actions v-if="mode==0">
                    <v-layout justify-end class="buttons">
                        <v-btn rounded dark class="dialog__button" color="#707070" @click="closeWindow">キャンセル</v-btn>
                        <v-btn rounded :dark="!isDisabled" :disabled="isDisabled" class="dialog__button ml-5 mb-2" color="#0099FF" @click="registerCheckPointInformation">登録</v-btn>
                    </v-layout>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <progress-dialog :progress-visible="isShowProgressDialog" :progress-message="progressMessage" :info-visible=null :info-type=null :info-message=null></progress-dialog>
    </div>
</template>

<script>
import MqttCommand from '../util/MqttCommand'
import ProgressDialog from './ProgressDialog.vue'
import MeterSettings from './parts/PtzSettings.vue'
import RustSettings from './parts/RustSettings.vue'
import CrackSettings from './parts/CrackSettings.vue'
import SmokeSettings from './parts/SmokeSettings.vue'
import LeakSettings from './parts/LeakSettings.vue'
import LampSwitchSettings from './parts/LampSwitchSettings.vue'
import ThermalSettings from './parts/ThermalSettings.vue'
import MicrophoneSettings from './parts/MicrophoneSettings.vue'
export default {
    components: {
        ProgressDialog,
        MeterSettings,
        RustSettings,
        CrackSettings,
        SmokeSettings,
        LeakSettings,
        LampSwitchSettings,
        ThermalSettings,
        MicrophoneSettings,
    },
    props: {
        checkPointDialogVisible: { type: Boolean },
        serialNo: { type: String },
        displayType: {
            type: Number,
            default: 0, // 0:メーター・ひび・さび・煙・漏れ・ランプ／スイッチ  1:熱分布  2:音
        },
    },
    data() {
        return {
            mode: 0,
            detailNames: [
                { no: 1, name: 'メーター', },
                { no: 2, name: 'ひび', },
                { no: 3, name: 'さび', },
                { no: 4, name: '煙', },
                { no: 5, name: '漏れ', },
                { no: 6, name: 'ランプ／スイッチ（目視）', },
            ],
            checkPointName: '',
            isAutoFocus: true,
            isTemplateMatching: true,
            rules: {
                required: value => !!value || '必須入力です。',
            },
            isValid: true,
            // メーター
            meter: {
                isValid: true,
                isSave: false,
                lower: null,
                upper: null,
                meterMin: null,
                meterMax: null,
                angleMin: null,
                angleMax: null,
                meterType: '',
                remarks: '',
            },
            // ひび
            crack: {
                isValid: true,
                isSave: false,
                area: null,
                remarks: '',
            },
            // さび
            rust: {
                isValid: true,
                isSave: false,
                area: null,
                erosion: null,
                remarks: '',
            },
            // 煙
            smoke: {
                isValid: true,
                isSave: false,
                threshold: [ null, null, ],
                remarks: '',
            },
            // 漏れ
            leak: {
                isValid: true,
                isSave: false,
                threshold: [ null, null, ],
                remarks: '',
            },
            // ランプ／スイッチ（目視）
            lampSwitch: {
                isValid: true,
                isSave: false,
                threshold: [ null, null, ],
                remarks: '',
            },
            // 熱分布
            thermal: {
                isValid: true,
                temperature: [ 0, 0 ],
                threshold: [ null, null, ],
                remarks: '',
            },
            // 音
            microphone: {
                isValid: true,
                recordingTimeSecond: 0,
                gain: 0,
                threshold: [ null, null, ],
                remarks: '',
            },
            topicRegExp: /ap-northeast-1_([0-9a-zA-Z]){9}\/([0-9a-z]){12,}\/usr_program\/route-check-subject-registration\/reply/,
            checkPointRegistrationRecvTopic: `${sessionStorage.getItem('userPoolId')}/${this.$store.state.selectedCrawler}/usr_program/route-check-subject-registration/reply`,
            registerCheckPointTimeoutId: '',
            isShowProgressDialog: false,
            progressMessage: '',
            isError: false,
            resetZoom: null,
            addWaypointToMap: null
        }
    },
    computed: {
        isDisabled: {
            get() {
                if (this.displayType === 0) {
                    let saveCounts = [
                        this.meter.isSave,
                        this.crack.isSave,
                        this.rust.isSave,
                        this.smoke.isSave,
                        this.leak.isSave,
                        this.lampSwitch.isSave,
                    ].filter(x => x).length
                    return !(saveCounts > 0 && this.isValid &&
                        this.meter.isValid && this.crack.isValid && this.rust.isValid && this.smoke.isValid && this.leak.isValid && this.lampSwitch.isValid)
                }
                else if (this.displayType === 1) {
                    return !(this.isValid && this.thermal.isValid)
                }
                else if (this.displayType === 2) {
                    return !(this.isValid && this.microphone.isValid)
                }
                return false;
            }
        },
        c_checkPointDialogVisible: {
            get() {
                return this.checkPointDialogVisible
            },
            set(val) {
                this.$emit('check-point-dialog-visible', val)
            }
        }
    },
    methods: {
        async initialize(connectionId, registeredWayPoint, resetZoom, addWaypointToMap) {
            // if (connectionId != null) {
                let object = null
                if (this.displayType === 2) {
                    let canvasList = Array.from(document.getElementsByTagName('canvas'))
                    if (canvasList.length !== 0) {
                        object = canvasList.find(val => val.previousSibling != null && val.previousSibling.id != null && val.previousSibling.id === connectionId) 
                    }
                }
                else {
                    object = document.getElementById(connectionId)
                }
                this.$nextTick(function() {
                    let capture = document.getElementById('capture')
                    if (object != null) {
                        capture.getContext('2d').drawImage(object, 0, 0, 480, 360)
                    }
                })
            // }
            // 既に登録済みのwayPointの中からwaitがtrueのものの個数を確認して、+1したものを識別IDのplaceHolderに設定する
            this.checkPointName = registeredWayPoint != null ? `#${registeredWayPoint.filter(wayPoint => wayPoint.wait === true).length + 1}` : '#1'
            this.resetZoom = resetZoom
            this.addWaypointToMap = addWaypointToMap
        },
        async registerCheckPointInformation() {
            this.isError = false
            
            MqttCommand.subscribe(this.checkPointRegistrationRecvTopic)

            let type = 'cmd'
            let name = 'register.subject'
            
            let visual = {}
            let thermal_distribution = null
            let sound = null
            if (this.displayType === 0) {
                // 可視光域カメラでの点検の詳細設定
                if (this.meter.isSave) {
                    switch(this.meter.meterType) {
                        case 'round':
                            visual['meter'] = {
                                'round': {
                                    'normal.ranges': (this.meter.lower === null && this.meter.upper === null) ? {} : {
                                        'lower.limit': this.meter.lower,
                                        'upper.limit': this.meter.upper,
                                    },
                                    'value.to.angle': (this.meter.meterMin === null && this.meter.angleMin === null && this.meter.meterMax === null && this.meter.angleMax === null) ? [] : [
                                        { 'value': this.meter.meterMin, 'angle.deg': this.meter.angleMin, },
                                        { 'value': this.meter.meterMax, 'angle.deg': this.meter.angleMax, },
                                    ],
                                    'remarks': this.meter.remarks,
                                }
                            }
                            break;
                        case 'rectangle':
                            visual['meter'] = {
                                'rect': {}
                            }
                            break;
                        case 'sevenSeg':
                            visual['meter'] = {
                                'seven.seg': {}
                            }
                            break;
                        case 'counter':
                            visual['meter'] = {
                                'counter': {}
                            }
                            break;
                        case 'rod':
                            visual['meter'] = {
                                'bar': {}
                            }
                            break;
                        default:
                            // nothing
                    }
                }
                if (this.crack.isSave) {
                    visual['crack'] = {
                        'max.crack.area.percent': this.crack.area === null ? NaN : this.crack.area,
                        'remarks': this.crack.remarks,
                    }
                }
                if (this.rust.isSave) {
                    visual['rust'] = {
                        'max.rust.area.percent': this.rust.area === null ? NaN : this.rust.area,
                        'max.rust.erosion.percent': this.rust.erosion === null ? NaN : this.rust.erosion,
                        'remarks': this.rust.remarks,
                    }
                }
                if (this.smoke.isSave) {
                    visual['smoke'] = {
                        'threshold': (this.smoke.threshold === [] || this.smoke.threshold.filter(x => x !== null).length === 0) ? [] : this.smoke.threshold,
                        'remarks': this.smoke.remarks,
                    }
                }
                if (this.leak.isSave) {
                    visual['leakage'] = {
                        'threshold': (this.leak.threshold === [] || this.leak.threshold.filter(x => x !== null).length === 0) ? [] : this.leak.threshold,
                        'remarks': this.leak.remarks,
                    }
                }
                if (this.lampSwitch.isSave) {
                    visual['lamp.switch'] = {
                        'threshold': (this.lampSwitch.threshold === [] || this.lampSwitch.threshold.filter(x => x !== null).length === 0) ? [] : this.lampSwitch.threshold,
                        'remarks': this.lampSwitch.remarks,
                    }
                }
            }
            else if (this.displayType === 1) {
                // サーモカメラでの点検の詳細設定
                thermal_distribution = {
                    'threshold': (this.thermal.threshold === [] || this.thermal.threshold.filter(x => x !== null).length === 0) ? [] : this.thermal.threshold,
                    'display.temperature': (this.thermal.temperature === [] || this.thermal.temperature.filter(x => x !== null).length === 0) ? { 'min': 0, 'max': 70 } : {
                        'min': this.thermal.temperature[0],
                        'max': this.thermal.temperature[1],
                    },
                    'remarks': this.thermal.remarks,
                }
            }
            else if (this.displayType === 2) {
                // 集音マイクでの点検の詳細設定
                sound = {
                    'recording.time.second': this.microphone.recordingTimeSecond,
                    'microphone.gain.percent': this.microphone.gain,
                    'threshold': (this.microphone.threshold === [] || this.microphone.threshold.filter(x => x !== null).length === 0) ? [] : this.microphone.threshold,
                    'remarks': this.microphone.remarks,
                }
            }

            let payload = {
                'subject.name': this.checkPointName,
                'does.auto.focus.after.aiming': this.isAutoFocus,
                'does.template.matching': this.isTemplateMatching,
            }
            if (Object.keys(visual).length !== 0) {
                payload['visual'] = visual
            }
            if (thermal_distribution != null) {
                payload['thermal.distribution'] = thermal_distribution
            }
            if (sound != null) {
                payload['sound'] = sound
            }
            
            let packet = await MqttCommand.createPacket(type, name, payload)
            this.packetId = packet['packet.id']

            let self = this
            MqttCommand.setSubscribeTopicHandler(this.topicRegExp, this.packetId, function(topic, data) { // eslint-disable-line
                if (data['replied.to'] === this) {
                    let wayPointId = null
                    let wayPoint = null
                    if (data.payload['has.succeeded']) {
                        wayPoint = {
                            'lat.deg': data.payload['lat.deg'],
                            'lon.deg': data.payload['lon.deg'],
                            'elev.m': data.payload['elev.m'],
                            'azimuth.deg': data.payload['azimuth.deg'],
                            'wait': data.payload['wait']
                        }
                        if (self.resetZoom != null) {
                            self.resetZoom()
                        }
                        wayPointId = this
                        self.closeWindow()
                    }
                    else {
                        self.closeWindowWithError()
                    }
                    self.addWaypointToMap({ 'type': 'meter', 'wayPointId': wayPointId, 'wayPoint': wayPoint })
                    clearTimeout(self.registerCheckPointTimeoutId)
                }
            }.bind(this.packetId))
            .then(()=>{
                let sendTopic = `${sessionStorage.getItem('userPoolId')}/${this.$store.state.selectedCrawler}/usr_program/route-check-subject-registration`
                MqttCommand.sendPacket(sendTopic, packet)
                this.progressMessage = `${this.checkPointName}を登録中です。`
                this.isShowProgressDialog = true
                this.registerCheckPointTimeoutId = setTimeout(function() {
                    this.addWaypointToMap({ 'type': 'meter', 'wayPointId': null, 'wayPoint': null })
                    clearTimeout(this.registerCheckPointTimeoutId)
                    this.closeWindowWithError()
                }.bind(this), 30000)
            })
        },
        closeWindow() {
            MqttCommand.removeSubscribeTopicHandler(this.topicRegExp, this.packetId)
            MqttCommand.unsubscribe(this.checkPointRegistrationRecvTopic)
            this.progressMessage = ''
            this.isShowProgressDialog = false
            this.c_checkPointDialogVisible = false
            this.checkPointName = ''
            this.isAutoFocus = true
            this.isTemplateMatching = true
            this.isError= false
            this.packetId = undefined
            this.isValid = true

            this.meter.isValid = true
            this.meter.isSave = false
            this.meter.lower = null
            this.meter.upper = null
            this.meter.meterMin = null
            this.meter.meterMax = null
            this.meter.angleMin = null
            this.meter.angleMax = null
            this.meter.meterType = ''
            this.meter.remarks = ''

            this.crack.isValid = true
            this.crack.isSave = false
            this.crack.area = null
            this.crack.remarks = ''

            this.rust.isValid = true
            this.rust.isSave = false
            this.rust.area = null
            this.rust.erosion = null
            this.rust.remarks = ''

            this.smoke.isValid = true
            this.smoke.isSave = false
            this.smoke.threshold = [ null, null ]
            this.smoke.remarks = ''

            this.leak.isValid = true
            this.leak.isSave = false
            this.leak.threshold = [ null, null ]
            this.leak.remarks = ''

            this.lampSwitch.isValid = true
            this.lampSwitch.isSave = false
            this.lampSwitch.threshold = [ null, null ]
            this.lampSwitch.remarks = ''

            this.thermal.isValid = true
            this.thermal.threshold = [ null, null ]
            this.thermal.temperature = [ 0, 0 ]
            this.thermal.remarks = ''

            this.microphone.isValid = true
            this.microphone.recordingTimeSecond = 0
            this.microphone.gain = 0
            this.microphone.threshold = [ null, null ]
            this.microphone.remarks = ''

            if (this.$refs.thermalSettings !== undefined) {
                this.$refs.thermalSettings.initialize()
            }
            if (this.$refs.microphoneSettings !== undefined) {
                this.$refs.microphoneSettings.initialize()
            }
        },
        closeWindowWithError() {
            MqttCommand.removeSubscribeTopicHandler(this.topicRegExp, this.packetId)
            MqttCommand.unsubscribe(this.checkPointRegistrationRecvTopic)
            this.progressMessage = ''
            this.isShowProgressDialog = false
            this.isError = true
            this.packetId = undefined
        },
        updateMeterSettings(settings) {
            this.meter.isValid = settings.isValid
            this.meter.isSave = settings.isSave
            if (settings.isValid) {
                if (settings.isSave) {
                    this.meter.lower = settings.lower
                    this.meter.upper = settings.upper
                    this.meter.meterMin = settings.meterMin
                    this.meter.meterMax = settings.meterMax
                    this.meter.angleMin = settings.angleMin
                    this.meter.angleMax = settings.angleMax
                    this.meter.meterType = settings.meterType
                    this.meter.remarks = settings.remarks
                }
            }
            this.mode = 0
        },
        updateCrackSettings(settings) {
            this.crack.isValid = settings.isValid
            this.crack.isSave = settings.isSave
            if (settings.isValid) {
                if (settings.isSave) {
                    this.crack.area = settings.area
                    this.crack.remarks = settings.remarks
                }
            }
            this.mode = 0
        },
        updateRustSettings(settings) {
            this.rust.isValid = settings.isValid
            this.rust.isSave = settings.isSave
            if (settings.isValid) {
                if (settings.isSave) {
                    this.rust.area = settings.area
                    this.rust.erosion = settings.erosion
                    this.rust.remarks = settings.remarks
                }
            }
            this.mode = 0
        },
        updateSmokeSettings(settings) {
            this.smoke.isValid = settings.isValid
            this.smoke.isSave = settings.isSave
            if (settings.isValid) {
                if (settings.isSave) {
                    this.smoke.threshold = settings.threshold
                    this.smoke.remarks = settings.remarks
                }
            }
            this.mode = 0
        },
        updateLeakSettings(settings) {
            this.leak.isValid = settings.isValid
            this.leak.isSave = settings.isSave
            if (settings.isValid) {
                if (settings.isSave) {
                    this.leak.threshold = settings.threshold
                    this.leak.remarks = settings.remarks
                }
            }
            this.mode = 0
        },
        updateLampSwitchSettings(settings) {
            this.lampSwitch.isValid = settings.isValid
            this.lampSwitch.isSave = settings.isSave
            if (settings.isValid) {
                if (settings.isSave) {
                    this.lampSwitch.threshold = settings.threshold
                    this.lampSwitch.remarks = settings.remarks
                }
            }
            this.mode = 0
        },
        updateThermalSettings(settings) {
            this.thermal.isValid = settings.isValid
            if (settings.isValid) {
                this.thermal.threshold = settings.threshold
                this.thermal.temperature = settings.temperature
                this.thermal.remarks = settings.remarks
            }
        },
        updateMicrophoneSettings(settings) {
            this.microphone.isValid = settings.isValid
            if (settings.isValid) {
                this.microphone.recordingTimeSecond = settings.second
                this.microphone.gain = settings.gain
                this.microphone.threshold = settings.threshold
                this.microphone.remarks = settings.remarks
            }
        },
        selectButton(e) {
            this.mode = this.detailNames.filter(x => x.name === e.currentTarget.innerText)[0].no
        },
        cancel() {
            this.mode = 0
        },
    }
}
</script>

<style scoped>
.dialog {
    width: 1030px;
    height: 770px;
    overflow-x: hidden;
    overflow-y: scroll;
}
.dialog__button {
    min-width: 159px !important;
    height: 54px !important;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 26px;
    opacity: 1;
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
}
.dialog__canvas {
    margin-top: 30px;
}
.dialog__error-message {
    font: normal normal bold 16px/32px Segoe UI;
    color: #FF0000 !important;
    white-space: pre-wrap;
    text-align: right;
    padding: 0px !important;
}
.dialog__item {
    width: 16%;
    text-align: left;
    font: normal normal bold 20px/28px Segoe UI;
    letter-spacing: 0px;
    color: #000000 !important;
    opacity: 1;
    padding: 1px !important;
    margin-top: 0px !important;
}
.dialog__title {
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
    opacity: 1;
}
.buttons {
    position: absolute;
    right: 1%;
    bottom: 2%;
}
</style>