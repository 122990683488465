import LSStartState from './LSStartState'

class LSReadyState {
    constructor() {}

    doReply(lsContext, reply) {
        if (reply.name === 'rsi.livestreaming.stop' && reply.payload.result === true) {
            lsContext.changeState(LSStartState)
        }
    }

    doAction(lsContext, randomStr) { // eslint-disable-line
    }
}

const lsReadyState = new LSReadyState()
Object.freeze(lsReadyState)
export default lsReadyState