<template>
    <div ref="one_bar"></div>
</template>

<script>
import OneBar from "!!raw-loader!../../assets/ricoh_assets/icons/ICN002-10_one_bar.svg" // loads SVG Icons as String via Webpack.
export default {
    mounted() {
        let shadowRoot = this.$refs.one_bar.attachShadow({mode: 'open'})
        shadowRoot.innerHTML = OneBar

        const style = document.createElement('style')
        style.innerHTML = `svg { width: 100%; height: 100%; }`

        const clonedStyle = style.cloneNode(true)
        shadowRoot.appendChild(clonedStyle)
    }
}
</script>

<style>
</style>