<template>
  <div id="thermal" class="thermal">
    <v-progress-circular indeterminate class="progress-circular--center" color="#009999" width="10" size="150" v-show="!isThermalArrived"></v-progress-circular>
  </div>
</template>

<script>
export default {
  data() {
    return {
      connectionId: null,
      isThermalArrived: false
    }
  },
  created() {
  },
  mounted() {
    window.addEventListener('resize', this.resizeThermal)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeThermal)
  },
  methods: {
    setLiveStreamEventCallback(liveStreaming) {
      let self = this
      liveStreaming.on('thermal_start', function($video, connection_id, mediaStreamTrack, stream) {
        self.startThermal($video, connection_id, mediaStreamTrack, stream)
      })

      liveStreaming.on('thermal_stop', function() {
        self.stopThermal()
      })
    },
    startThermal($video, connection_id, mediaStreamTrack, stream) {
      this.isThermalArrived = true
      this.connectionId = connection_id
      let $thermalDiv = document.getElementById('thermal')
      $thermalDiv.appendChild($video);
      $video.zIndex = 1000;
      $video.style.position = 'absolute'

      if ($video.srcObject) {
        $video.srcObject.addTrack(mediaStreamTrack);
      } else {
        $video.srcObject = stream;
      }
      let isVideoPlaying = ($video.currentTime > 0 && !$video.paused && !$video.ended && $video.readyState > $video.HAVE_CURRENT_DATA)
      if ($video != null && !isVideoPlaying) {
          $video.play()
      }
      this.resizeThermal()
      this.$emit('addThermal', { connectionId : connection_id })
    },
    stopThermal() {
      this.destroy()
    },
    resizeThermal() {
      // 画面サイズに応じて表示エリアの背景の大きさを変更
      let $thermalDiv = document.getElementById('thermal')
      let $video = document.getElementById(this.connectionId)
      let height = window.innerHeight - document.getElementsByTagName('header')[0].clientHeight
      if (height <500) {
        $thermalDiv.style.height = '500px'
      }
      else {
        $thermalDiv.style.height = `${height}px`
      }

      let thermalDivWidth = window.innerWidth
      let thermalDivHeight = parseInt($thermalDiv.style.height)

      // アスペクト比 4:3を維持したまま、配信映像表示エリア内に表示が収まるようサイズを修正する
      if ($video != null) {
        if (thermalDivWidth * 3 / 4 <= thermalDivHeight) {
          $video.style.height = `${thermalDivWidth * 3 / 4}px`
          $video.style.width = `${thermalDivWidth}px`
        }
        else if (thermalDivWidth * 3 / 4 > thermalDivHeight) {
          $video.style.height =`${thermalDivHeight}px`
          $video.style.width = `${thermalDivHeight * 4 / 3}px`
        }
        else {
            // nothing
        }
        $video.style.marginLeft = `${(thermalDivWidth - parseInt($video.style.width)) / 2}px`
        $video.style.marginTop = `${(thermalDivHeight - parseInt($video.style.height)) / 2}px`

        // this.adjustSizeAndPosition()
      }
    } ,
    reconnect() {
      this.destroy()
    },
    destroy() {
      if ($video != null) {
        $video.pause()
      }
      let $thermalDiv = document.getElementById('thermal')
      let $video = document.getElementById(this.connectionId)
      if ($thermalDiv != null && $video != null && $thermalDiv.childElementCount > 0) {
        $thermalDiv.removeChild($video)
      }
      this.connectionId = null
      this.isThermalArrived = false
    }
  },
}
</script>

<style scoped>
.progress-circular--center {
  margin-top: 18%;
  margin-left: 50%;
  transform: translateX(-50%);
}
.ptz {
  position: relative;
  width: inherit;
  height: inherit;
  top: 0px;
  left: 0px;
  background-color: #000000;
}
.ptz-home-button{
  width: 175px;
  height: 64px !important;
  padding: 0 16px;
  background: #009999 0% 0% no-repeat padding-box;
  opacity: 1;
  font: normal normal bold 24px/32px Segoe UI;
  letter-spacing: 0px;
}
.ptz-home-button--layout {
  position: absolute;
  bottom: 10px;
  right: 5%;
}
</style>