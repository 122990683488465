<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="3">
          <v-layout>
            <v-btn rounded dark class="button--left" color="#707070" @click="returnToHome">
              ホーム画面へ戻る
            </v-btn>
          </v-layout>
        </v-col>
        <v-col cols="6" class="col--center">
          <v-btn rounded dark class="button--center" color="#FF0000" v-show="drivingMode==='autonomous' && !isEnteringLeavingStationMode" @click="pause('pause')">
            <v-icon large>$vuetify.icons.emergencyStop</v-icon>一時停止
          </v-btn>
        </v-col>
        <v-col cols="3" class="col--right">
          <v-btn rounded dark class="button--right" color="#707070" @click="pause('check.finish')">点検終了</v-btn>
          <v-spacer></v-spacer>
          <v-btn rounded dark class="button--right mt-5" color="#0099FF" v-show="drivingMode==='remote.ctrl'" @click="resumeAutonomousDriving">自律走行再開</v-btn>
          <v-spacer></v-spacer>
          <v-btn rounded outlined class="button--right outlined-button mt-5" color="#0099FF" v-show="drivingMode==='remote.ctrl'" @click="skipPointAndResume">スキップして再開</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <resume-dialog class="forefront" 
      :resume-dialog-visible="isPushedPause" :dialogItem="pauseDialogItem" 
      :is-remote-driving-mode=false :lift-stop=true 
      :operating-mode="operatingMode"
      @resume="resume($event)">
    </resume-dialog>
    
    <check-finish-confirm-dialog class="forefront" 
      :check-finish-confirm-dialog-visible="isShowConfirmFinishCheckDialog" 
      @confirmCheckFinish="finishCheck" @cancelCheckFinish="cancelFinishCheck">
    </check-finish-confirm-dialog>

  </div>
</template>

<script>
import { BeepSound } from '../../class/BeepSound'
import ResumeDialog from '../ResumeDialog.vue'
import CheckFinishConfirmDialog from '../CheckFinishConfirmDialog.vue'
export default {
  props: {
    drivingMode: { type: String },
    cameraMode: { type: String },
    isPause: { type: Boolean },
    isEnteringLeavingStation: { type:Boolean }
  },
  components: {
    ResumeDialog,
    CheckFinishConfirmDialog
  },
  data() {
    return {
      mode: this.drivingMode,
      camera: this.cameraMode,
      isPushedPause: this.isPause,
      isEnteringLeavingStationMode: this.isEnteringLeavingStation,
      pauseDialogItem: {
        key: 'pause',
        title: '一時停止ボタンが押されました。',
        text: '次の操作を選択してください。'
      },
      isShowConfirmFinishCheckDialog: false
    }
  },
  computed: {
    operatingMode() {
      return this.$store.state.selectedMode
    }
  },
  watch: {
    drivingMode (mode) {
      this.mode = mode
    },
    cameraMode (camera) {
      this.camera = camera
    },
    isPause (isPause) {
      this.isPushedPause = isPause
      if (this.isPushedPause) {
        this.$emit('addDialog', { dialog_name: 'resumeDialog' })
      }
      else {
        this.$emit('removeDialog', { dialog_name: 'resumeDialog' })
      }
    },
    isEnteringLeavingStation(isEnteringLeavingStation) {
      this.isEnteringLeavingStationMode = isEnteringLeavingStation
    },
    isShowConfirmFinishCheckDialog (status) {
      if (status) {
        this.$emit('addDialog', { dialog_name: 'confirmFinishCheckDialog' })
      }
      else {
        this.$emit('removeDialog', { dialog_name: 'confirmFinishCheckDialog' })
      }
    }
  },
  methods: {
    pause(from) {
      let beep = new BeepSound('sine', 950, 0.2, 0.3)
      beep.play()
      // 一時停止のコマンドを送信する
      this.$emit('pause', from)
    },
    resume(event) {
      this.$emit('resume', event)
    },
    confirmFinishCheck() {
      this.isShowConfirmFinishCheckDialog = true
    },
    finishCheck() {
      this.$emit('finishCheck')
    },
    cancelFinishCheck() {
      this.closeConfirmFinishCheckDialog()
      this.$emit('cancelFinishCheck')
    },
    closeConfirmFinishCheckDialog() {
      this.isShowConfirmFinishCheckDialog = false
    },
    resumeAutonomousDriving() {
      let beep = new BeepSound('sine', 950, 0.2, 0.3)
      beep.play()
      this.$emit('resume', { mode: 'autonomous', from: 'resume.autonomous' })
    },
    skipPointAndResume() {
      let beep = new BeepSound('sine', 950, 0.2, 0.3)
      beep.play()
      this.$emit('resume', { mode: 'skippoint.autonomous', from: 'resume.skippoint.autonomous' })
    },
    returnToHome() {
      this.$emit('returnToHome')
    }

  }
}
</script>

<style scoped>
.button--left {
    height: 54px !important;
    min-width: 174px !important;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
}
.button--center {
    width: 220px;
    height: 64px !important;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 35px;
    opacity: 1;
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
}
.button--right {
    height: 54px !important;
    min-width: 174px !important;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
}
.col--center {
    text-align: center;
}
.col--right {
    text-align: right;
}
</style>