// Copyright(c) 2024 RICOH Company, Ltd. All rights reserved.

'use strict'

import AWSUtil from './AWSUtil'
import { Robot } from '../class/Robot'

class Robots {
  managedRobotList = []
  intervalId = null
  lastUpdate = new Date(0).getTime()
  
  constructor() {
  }

  async createManagedRobotList() {
    // managedRobotListを初期化する
    this.managedRobotList.splice(0)
    const axios = require('axios')
    const url = `https://api.dev-crawlerrobo.trial.ricoh/getmanagedrobotinformation`

    // ヘッダーの署名を取得する
    let signedHeaderInfo = await AWSUtil.createSigV4(url, 'get')
    
    let self = this
    // 認可情報と署名したヘッダーを利用してAPIをコールする
    return axios.get(url, {
      headers: {
          'Authorization': signedHeaderInfo.Authorization,
          'X-Host-Override': signedHeaderInfo.Host,
          'X-Amz-Date': signedHeaderInfo['X-Amz-Date'],
          'X-Amz-Security-Token': signedHeaderInfo['x-amz-security-token'],
      },
    })
    .then((res) => {
      let items = JSON.parse(JSON.stringify(res.data))
      if (items !== '') {
        let count = 1
        for (let item of items)
        {
          if (self.managedRobotList.find( robot => robot.robotStatus.serialNo === item.serial_no) == null) {
            self.managedRobotList.push(new Robot(count, item.serial_no, item.robot_name, item.firmware_version, item.accessories))
            count++
          }
        }
        // 初回のロボット一覧取得時から2秒毎にロボット一覧の更新要否をチェックする
        if (self.intervalId === null) {
          self.intervalId = setInterval(function() {
            self.updateRobotListIfNeeded()
          }, 2000)
        }

        // ロボット一覧取得日時を記録する
        self.lastUpdate = new Date().getTime()
      }
    })
    .catch((err) => {
      self.managedRobotList.splice(0)
    })
  }

  async updateRobotListIfNeeded() {
    // 最後にロボット一覧を更新したタイミングから5分以上更新がない場合、再度RobotListを取得する
    if (new Date().getTime() - this.lastUpdate > 300000 || this.managedRobotList.length === 0) {
      return this.createManagedRobotList()
    }
    else {
      return new Promise((resolve) => {
        resolve()
      })
    }
  }

  getManagedRobotList() {
    let self = this
    return new Promise((resolve)=> {
      self.updateRobotListIfNeeded()
      .then(()=> {
        resolve(self.managedRobotList)
      })
    })
  }

  getSpecificRobot(serialNo) {
    return this.managedRobotList.find(val => val.robotStatus.serialNo === serialNo)
  }
}

export default new Robots();