<template>
    <v-dialog persistent max-width="621px" v-model="c_returnToHomeConfirmationDialogVisible">
        <template v-slot:activator="{c_returnToHomeConfirmationDialogVisible}"></template> <!-- eslint-disable-line -->
        <v-card class="dialog">
        <v-toolbar flat dark class="dialog__title" color="#4A4B4C" height="40">確認</v-toolbar>
        <v-card-text class="dialog__message mt-10">{{ message }}</v-card-text>
        <v-card-actions>
            <v-layout justify-end>
                <v-btn rounded dark class="dialog__button" color="#707070" @click="cancelBackHome">キャンセル</v-btn>
                <v-btn rounded dark class="dialog__button mx-3" color="#0099FF" @click="backHome">ホーム画面へ戻る</v-btn>
            </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        returnToHomeConfirmationDialogVisible: { type: Boolean },
    },
    data() {
        return {
            message:`本当にホーム画面へ戻りますか？\n登録したポイントはすべて削除されます。`
        }
    },
    computed: {
        c_returnToHomeConfirmationDialogVisible: {
            get() {
                return this.returnToHomeConfirmationDialogVisible
            },
            set(val) {
                this.$emit('return-to-home-confirmation-dialog-visible', val)
            }
        }
    },
    methods: {
        cancelBackHome() {
            this.c_returnToHomeConfirmationDialogVisible = false
        },
        backHome() {
            this.$emit('confirmReturnToHome', true)
        }
    }
}
</script>

<style scoped>
.dialog {
    max-width: 621px;
    min-height: 236px;
}
.dialog__button {
    min-width: 164px !important;
    height: 54px !important;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 26px;
    opacity: 1;
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
}
.dialog__message {
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
    color: #000000 !important;
    opacity: 1;
    white-space: pre-wrap;
}
.dialog__title {
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
    opacity: 1;
}
</style>