<template>
    <div id="error-warning-card">
        <v-card dark class="card--error" v-show="type==='error'">
            <v-layout>
                <v-icon large class="ml-5 mt-3">$vuetify.icons.info</v-icon>
                <v-card-subtitle class="card__message">
                    {{ errorWarningMessage }}
                </v-card-subtitle>
            </v-layout>
        </v-card>

        <v-card dark class="card--warning" v-show="type==='warning'">
            <v-layout>
                <v-icon large class="ml-5 mt-3">$vuetify.icons.infoWarning</v-icon>
                <v-card-subtitle class="card__message">
                    {{ errorWarningMessage }}
                </v-card-subtitle>
            </v-layout>
        </v-card>
    </div>
</template>

<script>
import { convert } from '@ricoh-srg-system/error-stringizer'
export default {
    props: {
        type: { type: String },
        errorWarning: { type: Object },
    },
    methods: {
    },
    data() {
        return {

        }
    },
    computed: {
        errorWarningMessage: {
            get() {
                return convert(JSON.stringify(this.errorWarning), window.navigator.language)
            }
        }
    }
}
</script>

<style scoped>
.card--error {
    max-width: 650px;
    min-height: 69px;
    background: #FF0000 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    opacity: 1;
}
.card__message {
    text-align: left;
    font: normal normal bold 24px/32px Segoe UI;
    letter-spacing: 0px;
    color: #FFFFFF !important;
    opacity: 1;
    white-space: pre-wrap;
}
.card--warning {
    max-width: 650px;
    min-height: 69px;
    background: #F9A825 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    opacity: 1;
}
</style>