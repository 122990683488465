// Copyright(c) 2024 RICOH Company, Ltd. All rights reserved.

import NormalState from './NormalState'

let self = null
let callback = null
let moveContext = function(notifyMoveDirection) {
    this.state = NormalState
    callback = notifyMoveDirection
    self = this
}

moveContext.prototype = {
    constructor: moveContext,
    setInput: function(key) {
        self.state.doInput(self, key)
    },
    changeState: function(state, key) {
        self.state = state
        self.state.doAction(self, key)
    },
    notify: function(state, direction) {
        callback(state, direction)
    },
}

export default { moveContext }