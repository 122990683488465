<template>
  <div id="menu" v-cloak>
    <v-tabs
      id="tabs"
      class="tabs"
      dark
      vertical
      color="#000000"
      background-color="#4A4B4C"
    >
      <v-tabs-slider class="tab-select-bar"></v-tabs-slider>
      <v-tab>ホーム</v-tab>
      <v-tab>ルート一覧</v-tab>
      <v-tab>点検計画</v-tab>
      <v-tab>設定</v-tab>
      <v-tab-item>
        <home ref="home"></home>
      </v-tab-item>
      <v-tab-item>
        <route-list></route-list>
      </v-tab-item>
      <v-tab-item>
        <check-scheduler
          ref="scheduler"
          @mounted="initScheduler"
        ></check-scheduler>
      </v-tab-item>
      <v-tab-item>
        <settings-page></settings-page>
      </v-tab-item>
      <div class="license-button">
        powerd by the
        <v-btn text small @click="isShowLicense = true">projects</v-btn>
      </div>
    </v-tabs>
    <license-dialog
      class="license-dialog"
      :license-dialog-visible="isShowLicense"
      @license-dialog-visible="isShowLicense = $event"
    ></license-dialog>
  </div>
</template>

<script>
import Home from '../components/Home.vue';
import RouteList from '../components/RouteList.vue';
import CheckScheduler from '@ricoh-srg-system/check-scheduler-component';
import SettingsPage from '../components/SettingsPage.vue';
import LicenseDialog from '../components/LicenseDialog.vue';
import MqttCommand from '../util/MqttCommand';
import Robots from '../class/Robots';
import Routes from '../class/Routes';
import RobotAliveMonitoringCommand from '../util/RobotAliveMonitoringCommand';
import { Schedules } from '../class/Schedules';

export default {
  components: {
    Home,
    RouteList,
    CheckScheduler,
    SettingsPage,
    LicenseDialog,
  },
  data() {
    return {
      isShowLicense: false,
    };
  },
  beforeDestroy() {
    RobotAliveMonitoringCommand.stopMonitoring(true).then(() => {
      MqttCommand.endIot();
      window.removeEventListener('resize', this.fitWindowSize);
    });
  },
  created() {
    Robots.getManagedRobotList().then(async () => {
      let managedRobotList = await Robots.getManagedRobotList();
      for (let robot of managedRobotList) {
        MqttCommand.initIot(robot.robotStatus.serialNo);
      }
      RobotAliveMonitoringCommand.startMonitoringAllRobots(managedRobotList);
      Routes.getRouteList().then(() => {
        this.$refs.home.initialize();
      });
    });
  },
  mounted() {
    window.addEventListener('resize', this.fitWindowSize);
    this.fitWindowSize();
  },
  methods: {
    fitWindowSize() {
      let tabs = document.getElementById('tabs');
      tabs.style.height = `${window.innerHeight - document.getElementsByTagName('header')[0].clientHeight}px`;
    },
    initScheduler() {
      this.Schedules = new Schedules();
      this.$refs.scheduler.initialize(this.Schedules);
    },
  },
};
</script>

<style scoped>
.license-button {
  position: absolute;
  bottom: 5px;
  left: 5px;
  font-size: 20px;
  color: grey;
  text-align: center;
}
.license-dialog {
  z-index: 1000;
}
.tabs {
  min-height: 500px;
}
.tab-select-bar {
  background: #009999 0% 0% no-repeat padding-box;
  opacity: 1;
}
.v-tab {
  width: 228px;
  height: 64px !important;
  justify-content: left;
  font: normal normal bold 24px/32px Segoe UI;
  letter-spacing: 0px;
  margin-left: 12px;
}
.v-tab--active {
  background-color: white;
}
.v-tabs-slider {
  width: 12px;
}
</style>
