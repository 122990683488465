<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="3">
          <v-layout>
            <v-btn rounded dark class="button--left" color="#707070" @click="returnToHomeConfirmationDialogVisible=true">
              ホーム画面へ戻る
            </v-btn>
          </v-layout>
        </v-col>
        <v-col cols="6">
        </v-col>
        <v-col cols="3" class="col--right">
          <v-btn rounded dark class="button--right" color="#0099FF" v-show="w_cameraMode==='maincam'" @click="registerRoute">作成したルートの登録</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <check-point-registration-dialog class="front" ref="check_point_registration" :check-point-dialog-visible="isCheckPointRegistering" @check-point-dialog-visible="isCheckPointRegistering=$event" :display-type="displayType"></check-point-registration-dialog>
    
    <route-registration-dialog class="route-create--forefront" ref="route_registration" 
      :dialog-visible="isRouteRegistering" 
      @dialog-visible="isRouteRegistering=$event"
      @finish-route-registration="routeRegistrationResponse($event)">
    </route-registration-dialog>
    
    <progress-dialog :progress-visible="isProgress" :progress-message="progressMessage" :info-visible=null :info-type=null :info-message=null></progress-dialog>
    
    <return-to-home-confirmation-dialog 
      :return-to-home-confirmation-dialog-visible="returnToHomeConfirmationDialogVisible" 
      @return-to-home-confirmation-dialog-visible="returnToHomeConfirmationDialogVisible=$event" 
      @confirmReturnToHome="returnToHome">
    </return-to-home-confirmation-dialog>
  </div>
</template>

<script>
import CheckPointRegistrationDialog from '../CheckPointRegistrationDialog.vue'
import RouteRegistrationDialog from '../RouteRegistrationDialog.vue'
import ProgressDialog from '../ProgressDialog.vue'
import ReturnToHomeConfirmationDialog from '../ReturnToHomeConfirmationDialog.vue'

import MqttCommand from '../../util/MqttCommand'
export default {
  props: {
    cameraMode: { type: String }
  },
  components: {
    CheckPointRegistrationDialog,
    RouteRegistrationDialog,
    ProgressDialog,
    ReturnToHomeConfirmationDialog,
  },
  watch: {
    cameraMode (mode) {
      this.w_cameraMode =mode 
      if (mode === 'ptz') {
        this.displayType = 0
      }
      else if (mode === 'thermal') {
        this.displayType = 1
      }
      else if (mode === 'sound') {
        this.displayType = 2
      }
    },
    isProgress(status) {
      if (status) {
        this.$emit('addDialog', { dialog_name: 'progressDialog' })
      }
      else {
        this.$emit('removeDialog', { dialog_name: 'progressDialog' })
      }
    },
    isCheckPointRegistering(status) {
      if (status) {
        this.$emit('addDialog', { dialog_name: 'checkPointRegistrationDialog' })
      }
      else {
        this.$emit('removeDialog', { dialog_name: 'checkPointRegistrationDialog' })
      }
    },
    isRouteRegistering(status) {
      if (status) {
        this.$emit('addDialog', { dialog_name: 'routeRegistrationDialog' })
      }
      else {
        this.$emit('removeDialog', { dialog_name: 'routeRegistrationDialog' })
      }
    },
    returnToHomeConfirmationDialogVisible(status) {
      if (status) {
        this.$emit('addDialog', { dialog_name: 'returnToHomeConfirmationDialog' })
      }
      else {
        this.$emit('removeDialog', { dialog_name: 'returnToHomeConfirmationDialog' })
      }
    }
  },
  data() {
    return  {
      returnToHomeConfirmationDialogVisible: false,
      w_cameraMode: this.cameraMode,
      isCheckPointRegistering: false,
      isProgress: false,
      progressMessage: '',
      routeRegistrationRecvTopic: `${sessionStorage.getItem('userPoolId')}/${this.$store.state.selectedCrawler}/usr_program/route-check-subject-registration/reply`,
      routeRegisterationTopicRegExp: /ap-northeast-1_([0-9a-zA-Z]){9}\/([0-9a-z]){12,}\/usr_program\/route-check-subject-registration\/reply/,
      registerCurrLocTimeoutId: null,
      displayType: null,
      isRouteRegistering: false
    }
  },
  methods: {
    returnToHome() {
      this.$emit('returnToHome')
    },
    async registerCurrentLoc(addWaypointToMap) {
      MqttCommand.subscribe(this.routeRegistrationRecvTopic)

      let type = 'cmd'
      let name = 'register.curr.loc'
      let payload = {}
      let packet = await MqttCommand.createPacket(type, name, payload)
      let packetId = packet['packet.id']
      let self = this
      MqttCommand.setSubscribeTopicHandler(this.routeRegisterationTopicRegExp, packetId, function(topic, data) { // eslint-disable-line
        if (data['replied.to'] === this) {
          let wayPointId = null
          let wayPoint = null
          if (data.payload['has.succeeded']) {
            wayPointId = packetId
            wayPoint = data.payload['way.point']
          }
          addWaypointToMap({ 'type': 'current.loc', 'wayPointId': wayPointId, 'wayPoint': wayPoint })
          self.closeWindow(packetId)
          clearTimeout(self.registerCurrLocTimeoutId)
        }
      }.bind(packetId))
      .then(()=>{
        let sendTopic = `${sessionStorage.getItem('userPoolId')}/${this.$store.state.selectedCrawler}/usr_program/route-check-subject-registration`
        MqttCommand.sendPacket(sendTopic, packet)
        this.progressMessage = '現在地を登録中です。'
        this.isProgress = true
        this.registerCurrLocTimeoutId = setTimeout(function() {
        this.$emit('registerCurrentLocResult', {'result': false, 'wayPointId': null, 'wayPoint': null})
          clearTimeout(this.registerCurrLocTimeoutId)
          addWaypointToMap({ 'type': 'current.loc', 'wayPointId': null, 'wayPoint': null })
          this.closeWindow(packetId)
        }.bind(this), 10000)
      })
    },
    async closeWindow(packetId) {
      MqttCommand.removeSubscribeTopicHandler(this.routeRegisterationTopicRegExp, packetId)
      MqttCommand.unsubscribe(this.routeRegistrationRecvTopic)
      this.progressMessage = ''
      this.isProgress = false
    },
    registerCheckPoint(connectionId, registeredWayPoint, resetZoom, addWaypointToMap) {
      this.isCheckPointRegistering = true
      this.$refs.check_point_registration.initialize(connectionId, registeredWayPoint, resetZoom, addWaypointToMap)
    },
    registerRoute() {
      this.isRouteRegistering = true
      this.$refs.route_registration.initialize();
    },
    routeRegistrationResponse(event) {
      if (event.result) {
        this.returnToHome()
      }
    }
  }
}
</script>

<style scoped>
.button--left {
  height: 54px !important;
  min-width: 174px !important;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  text-align: left;
  font: normal normal bold 24px/32px Segoe UI;
  letter-spacing: 0px;
}
.button--right {
  height: 54px !important;
  min-width: 174px !important;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  text-align: left;
  font: normal normal bold 24px/32px Segoe UI;
  letter-spacing: 0px;
}
.col--right {
  text-align: right;
}
</style>