// AWSUtil.js

'use strict'
import {EventEmitter} from 'events'

class AWSUtil extends EventEmitter {
    aws = require('aws-sdk')

    loggedinUserName = null
    expTime = null
    checkTokenExpItvId = null
    awsCredentialInfo = null

    constructor() {
        super()
        this.startNotifyExpiredToken()
    }

    startNotifyExpiredToken() {
        this.checkTokenExpItvId = setInterval(function() {
            if (this.expTime == null && JSON.parse(sessionStorage.getItem('expTime')) != null) {
                this.expTime = JSON.parse(sessionStorage.getItem('expTime')).exp_time
            }
            if (this.expTime != null) {
                if (this.expTime < Math.floor(new Date().getTime() / 1000)) {
                    // 再ログインが必要であることをvueに通知する
                    this.emit('notifyTokenExpiration')
                    if (this.checkTokenExpItvId != null) {
                        clearInterval(this.checkTokenExpItvId)
                    }
                }
                else {
                    this.updateCredentialsIfNeeded()
                }
            }
        }.bind(this), 1000)
    }

    updateCredentialsIfNeeded() {
        let self = this
        return new Promise((resolve, reject) => {
            if(self.awsCredentialInfo != null) {
                if (self.aws.config.credentials.needsRefresh()) {
                    self.awsCredentialInfo.refresh((err) => {
                        if(err) {
                        reject(err);
                        }
                        else {
                        resolve();
                        }
                    })
                }
            }
        })
    }

    getUserPoolInfo(tenantId, userName) {
        return new Promise((resolve, reject) => {
            const axios = require('axios')
            axios.get('https://api.dev-crawlerrobo.trial.ricoh/getcompanycognitoinformation', {
                headers: {
                    'Company-ID': tenantId,
                    'User-Name': userName
                },
            })
            .then((res) => {
                let item = JSON.parse(JSON.stringify(res.data))
                if (item !== '') {
                    sessionStorage.setItem('userPoolId', item.user_pool_id)
                    sessionStorage.setItem('clientId', item.client_id)
                    sessionStorage.setItem('idPoolId', item.id_pool_id)
  
                    let userPoolInfo = {
                        loggedinUserName: item.user_name,
                        userPoolId: item.user_pool_id,
                        clientId: item.client_id
                    }
                    resolve(userPoolInfo)
                }
            }) 
            .catch((error) => {
                reject(error)
            }) 
        })
    }

    authenticate(tenantId, userName, password, regLoggedinUserFunc) {
        const cognito = require('amazon-cognito-identity-js')

        return new Promise((resolve, reject)=> {
            this.getUserPoolInfo(tenantId, userName)
            .then((userPoolInfo) => {
                const poolData = {
                    UserPoolId: userPoolInfo.userPoolId,
                    ClientId: userPoolInfo.clientId,
                    Storage: sessionStorage
                }
                const userPool = new cognito.CognitoUserPool(poolData);
                const userData = {
                    Username: userName,
                    Pool: userPool,
                    Storage: sessionStorage
                }
                
                const authData = {
                    Username: userName,
                    Password: password
                }
                const authDetail = new cognito.AuthenticationDetails(authData)
                const cognitoUser = new cognito.CognitoUser(userData)
                let self = this
                cognitoUser.authenticateUser(authDetail, {
                    onSuccess: function(authResult) {
                        regLoggedinUserFunc(userPoolInfo.loggedinUserName)
                        self.getExpTime(authResult.getIdToken().getJwtToken())
                        resolve()
                    },
                    onFailure: function(error) {
                        reject(error)
                    }
                })
            })
            .catch((error) => {
                reject(error)
            })
        })
    }

    getExpTime(idToken) {
        const base64Url = idToken.split('.')[1];                             
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');    
        const decoded = JSON.parse(decodeURIComponent(escape(window.atob(base64))))
        const expTime =  {
            exp_time : decoded.exp
        }
        sessionStorage.setItem('expTime', JSON.stringify(expTime))
    }

    getAwsCredentialInfo () {
        return new Promise((resolve, reject) => {
            if (this.awsCredentialInfo == null) {
                const clientId = sessionStorage.getItem('clientId')
                const lastAuthUser = sessionStorage.getItem(`CognitoIdentityServiceProvider.${clientId}.LastAuthUser`)
                const idToken =  sessionStorage.getItem(`CognitoIdentityServiceProvider.${clientId}.${lastAuthUser}.idToken`)
            
                this.aws.config.region = 'ap-northeast-1'
                this.aws.config.credentials = new this.aws.CognitoIdentityCredentials({
                    IdentityPoolId: sessionStorage.getItem('idPoolId'),
                    Logins: {
                        [`cognito-idp.ap-northeast-1.amazonaws.com/${sessionStorage.getItem('userPoolId')}`]: idToken
                    }
                });
                this.aws.config.credentials.get((error) => {
                    if (error) {
                        reject(error)
                    }
                    else {
                        this.awsCredentialInfo = this.aws.config.credentials
                        resolve(this.awsCredentialInfo)
                    }
                })
            }
            else {
                resolve(this.awsCredentialInfo)
            }
        })
    }

    async createSigV4(url, method, data) {
        return new Promise((resolve, reject)=> {
            const core = require('aws-sdk/lib/core')
            const serviceName = 'execute-api'

            let options = null
            if (data != null) {
                options = {
                    url: url,
                    headers: {},
                    body: JSON.stringify(data)
                }
            }
            else {
                options = {
                    url: url,
                    headers: {}
                }
            }
            
            const addr = new URL(url)
            const querystring = addr.searchParams.toString()

            const now = new Date();
            options.headers.host = addr.host;
            options.pathname = () => addr.pathname;
            options.methodIndex = method;
            options.search = () => querystring ? querystring : "";
            options.region = 'ap-northeast-1';
            options.method = method.toUpperCase();

            try {
                const signer = new core.Signers.V4(options, serviceName);
                this.getAwsCredentialInfo()
                .then((data)=> {
                    const credential = new this.aws.Credentials(data.accessKeyId, data.secretAccessKey, data.sessionToken)
                    signer.addAuthorization(credential, now)
                    resolve(options.headers)
                })
                .catch(() => {
                    reject()
                })
            }
            catch(err) {
                reject(err)
            }
        })
    }
    
    getCurrentUser() {
        const poolData = {
            UserPoolId: sessionStorage.getItem('userPoolId'),
            ClientId: sessionStorage.getItem('clientId'),
            Storage: sessionStorage
        }
        const cognito = require('amazon-cognito-identity-js')
        const userPool = new cognito.CognitoUserPool(poolData);
        return userPool.getCurrentUser()
    }
    
    clearAWSCache() {
        const clientId = sessionStorage.getItem('clientId')
        const lastAuthUser = sessionStorage.getItem(`CognitoIdentityServiceProvider.${clientId}.LastAuthUser`)
        const idToken =  sessionStorage.getItem(`CognitoIdentityServiceProvider.${clientId}.${lastAuthUser}.idToken`)

        if (this.getCurrentUser() !== null) {
            this.aws.config.region = 'ap-northeast-1' 
            
            this.aws.config.credentials = new this.aws.CognitoIdentityCredentials({
                IdentityPoolId: sessionStorage.getItem('idPoolId'),
                Logins: {
                    [`cognito-idp.ap-northeast-1.amazonaws.com/${sessionStorage.getItem('userPoolId')}`]: idToken
                }
            });
            this.aws.config.credentials.clearCachedId();
        }
    }

    disableAuthentication() { 
        return new Promise((resolve, reject)=> {
            // AWSが保存する認証情報のキャッシュを削除する
            this.clearAWSCache()
            // ログイン中のユーザーをサインアウトする
            this.getCurrentUser().signOut()
            // 再度ログイン中のユーザーを取得し、ログイン中ユーザーがいなければログアウト処理完了
            if (this.getCurrentUser() === null) { 
                sessionStorage.clear()
                resolve()
            }
            else {
                reject()
            }
        })
    }
}

export default new AWSUtil()