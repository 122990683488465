
class LSKeepState {
    constructor() {}

    doReply(lsContext, reply) { // eslint-disable-line
    }

    doAction(lsContext, randomStr) {
        let payload = {
            token: randomStr,
        }
        setInterval(function() {
            lsContext.publish('cmd.noreply', 'rsi.livestreaming.keep', payload)
        }, 25000)
    }
}

const lsKeepState = new LSKeepState()
Object.freeze(lsKeepState)
export default lsKeepState