// Copyright(c) 2024 RICOH Company, Ltd. All rights reserved.

export class Application {
  appName = null;
  drivingMode = null;
  route = [];

  constructor(){}

  // method
  updateApplication(updateInfo) {
    this.appName = updateInfo.application['app.name']!=null ? updateInfo.application['app.name'] : null;
    this.drivingMode = updateInfo.application['driving.mode']!=null ? updateInfo.application['driving.mode'] : null;
    this.route = updateInfo.application['route']!=null ? updateInfo.application['route'] : []
    return this;
  }
}