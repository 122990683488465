// Copyright(c) 2024 RICOH Company, Ltd. All rights reserved.

// MqttCommand.js

'use strict'

import {EventEmitter} from 'events'
import AWSUtil from '../class/AWSUtil';

let deviceIot = null
let _serialNo = null
let callbackList = []

export default class MqttCommand extends EventEmitter{
    constructor () {
        super()
    }    
    static createUUID_() {
        let chars = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".split("");
        for (let i = 0, len = chars.length; i < len; i++) {
            switch (chars[i]) {
                case "x":
                    chars[i] = Math.floor(Math.random() * 16).toString(16);
                    break;
                case "y":
                    chars[i] = (Math.floor(Math.random() * 4) + 8).toString(16);
                    break;
            }
        }
        return chars.join("").replace(/-/g, '_')
    }

    static initIot(serialNo) {
        return new Promise(resolve => {
            const awsIot = require('aws-iot-device-sdk');
            const endpoint = 'as4tyirr4am2o-ats.iot.ap-northeast-1.amazonaws.com';
            const region = 'ap-northeast-1'
            _serialNo = serialNo
            let uuid = MqttCommand.createUUID_()
            AWSUtil.getAwsCredentialInfo()
            .then((data) => {
                deviceIot = awsIot.device({
                    region: region,
                    clientId: `${serialNo}_${new Date().getTime()}_${uuid}`,
                    accessKeyId: data.accessKeyId,
                    secretKey: data.secretAccessKey,
                    sessionToken: data.sessionToken,
                    protocol: 'wss',
                    port: 443,
                    host: endpoint
                });
            
                deviceIot.on('disconnect', function() {
                    console.log('iot disconnected!')
                });
                deviceIot.on('error', function(error) {
                    console.log(`*** error from MqttCommand: ${error} ***`);
                    this.emit('reconnect')
                    // deviceIot.end()
                    // MqttCommand.initIot(_serialNo)
                });
                // deviceIot.on('reconnect', function() {
                //     console.log('reconnect');
                // })
                deviceIot.on('close', function() {
                    console.log('close');
                })
                deviceIot.on('offline', function() {
                    console.log('offline');
                })
                resolve()
            })
        })
    }
    static subscribe(recvTopic) {
        let options = {
            qos: 0
        }

        return new Promise((resolve, reject) => {
            deviceIot.subscribe(recvTopic, options, function(err, granted) {
                if (err) {
                    console.log(err)
                    reject(err)
                }
                else {
                    console.log(granted)
                    console.log('subscribe success');
                    resolve()
                }
            })
            
            deviceIot.on('message', function(topic, payload) {
                let cache = require('js-cache')
                let ttl = 60000

                let data = JSON.parse(payload.toString())
                if (cache.get(data['packet.id']) == null) {
                    console.log(JSON.parse(payload))
                    cache.set(data['packet.id'], data, ttl)
                    for (let callback of callbackList) {
                        if (callback.topicRegExp.test(topic)) {
                            if (callback['packet.id'] === null) {
                                callback.callback(topic, data)
                            }
                            else {
                                if (callback['packet.id'] === data['replied.to']) {
                                    callback.callback(topic, data)
                                }
                            }
                        }
                    }
                }
            })
        })
    }
    static publish(sendTopic, type, name, payload) {
        let options = {
            qos: 1
        }
        
        let time = Math.floor(((performance.now() + performance.timeOrigin) * 1000))
        let randomNum = Math.floor(Math.random() * 0x10000)
        
        let packet = {
            'packet.id': (0x5 << 44) + ((time & 0xFFFFFFF) << 16) + (randomNum & 0xFFFF),
            'timestamp': new Date().getTime(),
            'type': type,
            'name': name,
            'payload': payload
        };

        deviceIot.publish(sendTopic, JSON.stringify(packet), options, function(err){
            if(err){
                console.log(err)
            }
            else {
                console.log(`published to ${sendTopic} : ${JSON.stringify(packet)}`)
            }
        })
    }
    static unsubscribe(recvTopic) {
        deviceIot.unsubscribe(recvTopic, function(err) {
            if (err) {
                console.log(err)
            }
            else {
                console.log('unsubscribe success');
            }
        })
    }
    static endIot() {
        return new Promise(resolve => {
            deviceIot.end()
            resolve()
        })
    }
    static setSubscribeTopicHandler(topicRegExp, packetId, callback) {
        return new Promise(resolve => {
            callbackList.push({
                'topicRegExp': topicRegExp,
                'packet.id': packetId,
                'callback': callback
            })
            resolve()
        })
    }
    static removeSubscribeTopicHandler(topicRegExp, packetId) {
        for (let i = 0; i < callbackList.length; i++) {
            if (callbackList[i].topicRegExp === topicRegExp) {
                if (packetId === null) {
                    callbackList.splice(i, 1)
                }
                else {
                    if (callbackList[i]['packet.id'] === packetId) {
                        callbackList.splice(i, 1)
                    }
                }
            }
        }
    }
    static createPacket(type, name, payload) {
        return new Promise(resolve => {
            let time = Math.floor(((performance.now() + performance.timeOrigin) * 1000))
            let randomNum = Math.floor(Math.random() * 0x10000)
        
            let packet = {
            'packet.id': (0x5 << 44) + ((time & 0xFFFFFFF) << 16) + (randomNum & 0xFFFF),
                'timestamp': new Date().getTime(),
                'type': type,
                'name': name,
                'payload': payload
            };
            resolve(packet)
        })
    }
    static sendPacket(sendTopic, packet) {
        let options = {
            qos: 1
        }

        deviceIot.publish(sendTopic, JSON.stringify(packet), options, function(err){
            if(err){
                console.log(err)
            }
            else {
                console.log(`published to ${sendTopic} : ${JSON.stringify(packet)}`)
            }
        })
    }
}