// Copyright(c) 2024 RICOH Company, Ltd. All rights reserved.

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { TranslationData } from '@/i18n/translations.js'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';

// for localization.
Vue.use(VueI18n); 
const translationSettings = {
    locale: "ja",
    fallbackLocale: 'en',
    messages: TranslationData,
    silentTranslationWarn: true
};
const i18n = new VueI18n(translationSettings);

Vue.config.productionTip = false

new Vue({
  i18n, // for localization.
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
